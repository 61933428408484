import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import { FindLocalAgentSearchAgentForm } from "../../../../../interfaces/navigationMenu";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { ItemHero } from "../../../../../interfaces/homePage";
import { makeid } from "../../../../../utils";
import data from "../../../../../mockdata/data";
const defaultData = {
  title: "",
  highlightFeatureTitle: "",
  countries: [],
};
const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  highlightFeatureTitle: yup.string().required(validationMessage.required),
  countries: yup.array().required(validationMessage.required),
});
const SearchAgentTab = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataSearchAgent, setDataSearchAgent] = useState<ItemHero | null>(null);
  const [dataSearchAgentSaved, setDataSearchAgentSaved] = useState<ItemHero | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<FindLocalAgentSearchAgentForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<FindLocalAgentSearchAgentForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "findlocalagent",
      section: "searchagent",
      contents: JSON.stringify(data),
    };

    setLoadingBtn(true);
    if (dataSearchAgent) {
      const res = await homePageService.update(dataSearchAgent.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Search Agent has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Search Agent has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }

    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<FindLocalAgentSearchAgentForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("findlocalagent");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "searchagent"
      );
      if (data) {
        setDataSearchAgent(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataSearchAgentSaved(data);
        }
        const initData = JSON.parse(data.contents ?? "{}");
        reset(initData);
        setKeyReset(makeid(5));
      } else if (dataSearchAgentSaved) {
        const dataSaved = JSON.parse(dataSearchAgentSaved.contents ?? "{}");
        setDataSearchAgent(null);
        reset(dataSaved);
      } else {
        setDataSearchAgent(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ p: 3, mb: 2 }}>
        {
          loading ? (
            <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
              <CircularProgress />
            </Box>
          ) :<Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("title")?.length > 40 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("title")?.length}/40
            </Typography>
            <TextField
              size="small"
              fullWidth
              {...register(`title`)}
              label={t(
                "homepage_navigationMenu_findLocalAgent_searchAgent_title"
              )}
              InputLabelProps={{ shrink: true }}
              error={errors?.title !== undefined}
              helperText={
                errors?.title !== undefined ? validationMessage.required : ""
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Paper>
              <Box>
                <Typography className="text-16 fw-600 p-10">
                  {t(
                    "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_titleHeader"
                  )}
                </Typography>
                <Divider />
              </Box>
              <Box p={3} py={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="text-14 fw-600 mb-10"
                      color={watch("title")?.length > 30 ? "error" : "green"}
                    >
                      {t("checkLength")}: {watch("title")?.length}/30
                    </Typography>
                    <TextField
                      size="small"
                      fullWidth
                      {...register(`highlightFeatureTitle`)}
                      label={t(
                        "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_title"
                      )}
                      InputLabelProps={{ shrink: true }}
                      error={errors?.title !== undefined}
                      helperText={
                        errors?.title !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="text-14 mb-10">
                      {t(
                        "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries_dataLimit"
                      )}
                      : 6{" "}
                      {t(
                        "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries_dataLimit_unit"
                      )}
                    </Typography>

                    <Controller
                      key={keyReset}
                      control={control}
                      name={`countries`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <>
                            <Autocomplete
                              multiple
                              disablePortal
                              disableClearable
                              id="countries"
                              options={data.listCountry}
                              getOptionLabel={(option) => option.label}
                              getOptionDisabled={(options) => (value?.length >= 6 ? true : false)}
                              value={
                                value
                                  ? data.listCountry.filter((item) =>
                                      value.includes(item.label)
                                    )
                                  : undefined
                              }
                              onChange={(e, value) => {
                                if (value) {
                                  onChange(value.map((item) => item.label));
                                } else {
                                  onChange([]);
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label={t(
                                    "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries"
                                  )}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  error={errors?.countries !== undefined}
                                  helperText={
                                    errors?.countries !== undefined
                                      ? validationMessage.required
                                      : ""
                                  }
                                />
                              )}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        }
        
      </Paper>

      <Stack className="mt-30" alignItems={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
  );
};

export default SearchAgentTab;
