import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { FAQItem, SearchCustomerReviewQuery } from "../../../interfaces/homePage";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import ContentLayout from "../../../components/Table/ContentLayout";
import TableHeading from "./TableHeading";
import TableItem from "./TableItem";
import faqService from "../../../services/faqService";
const FAQsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchparams] = useSearchParams();
  const { language } = useGlobalContext();
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState<FAQItem[] | null>(null);
  const [page, setPage] = React.useState(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1);
  const [perPage, setPerPage] = React.useState(Number(searchParams.get("pageSize")) ? Number(searchParams.get("pageSize")) : 10);
  const fetchListData = async (payload: SearchCustomerReviewQuery) => {
    setLoading(true);
    setSearchparams({
      page: String(payload.pageNumber),
      pageSize: String(payload.pageSize),
     });
    const response = await faqService.getList(payload);

    if (response?.status === 200) {
      setData(response.data.data);
      setTotal(response.data.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    faqService.getListRandom(language ?? "en", 10)
    fetchListData({
      language: language ?? "en",
      pageSize: perPage,
      pageNumber: page,
    });
  }, [language, page, perPage]);

  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <ContentLayout
        isReverse={true}
        extraActions={
          <Box sx={{ m: 2 }}>
            <Button
              className="h-45"
              variant="contained"
              endIcon={<Add />}
              onClick={() => navigate("/homepage/faqs/add-faq")}
            >
              {t("addNewButton")}
            </Button>
          </Box>
        }
        total={total}
        tableHeading={<TableHeading />}
        tableContent={
          loading ? (
            <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : !data ? (
            <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                {t("table_data_notFound")}
              </TableCell>
            </TableRow>
          ) : data.length > 0 ? (
            data.map((e: FAQItem, i: number) => (
              <TableItem
                key={i}
                data={e}
                dataKey={i}
                childrenCallback={() => fetchListData({
                  language: language ?? "en",
                  pageSize: perPage,
                  pageNumber: page,
                })}
              />
            ))
          ) : (
            <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                {t("table_data_notFound")}
              </TableCell>
            </TableRow>
          )
        }
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Paper>
  );
};

export default FAQsPage;
