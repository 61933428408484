import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  AttachMoney,
  Clear,
  CloudUpload,
  Delete,
  Edit,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../interfaces/masterSetup";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import Swal from "sweetalert2";
import { BestSellingProductForm, ItemHero } from "../../../interfaces/homePage";
import { makeid } from "../../../utils";
import homePageService from "../../../services/homePageService";
import MetaSEO from "./MetaSEO";
const defaultData = {
  bestSellingProducts: [],
};
const validationSchema = yup.object().shape({
  bestSellingProducts: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        images: yup.string().required(validationMessage.required),
        cardTitle: yup.string().required(validationMessage.required),
        url: yup.string().required(validationMessage.required),
        amount: yup
          .number()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
      })
    )
    .required(validationMessage.required),
});
const BestSellingProductPage = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataBestSellingProduct, setDataBestSellingProduct] =
    useState<MasterSetupResItem | null>(null);
  const [dataBestSellingProductSaved, setDataBestSellingProductSaved] =
    useState<MasterSetupResItem | null>(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);

  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<BestSellingProductForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "bestSellingProducts",
  });

  const submit: SubmitHandler<BestSellingProductForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "best-selling-product-content",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true)
    if (dataBestSellingProduct) {
      const res = await homePageService.update(
        dataBestSellingProduct.id,
        payload
      );
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Best Selling Product Content has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Best Selling Product Content has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<BestSellingProductForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language &&
          item.section === "best-selling-product-content"
      );
      if (data) {
        setDataBestSellingProduct(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataBestSellingProductSaved(data);
        }
        const initData: BestSellingProductForm = JSON.parse(data.contents);

        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: BestSellingProductForm = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              bestSellingProducts: dataSaved.bestSellingProducts.map(
                (item) => ({
                  ...item,
                  // key: `BEST_SELLING_PRODUCT_${makeid(8)}`,
                  url:
                    initData.bestSellingProducts.find(
                      (el) => el.key === item.key
                    )?.url ?? item.url,
                  cardTitle:
                    initData.bestSellingProducts.find(
                      (el) => el.key === item.key
                    )?.cardTitle ?? item.cardTitle,
                })
              ),
            };
            console.log(dataForm, initData, "if");

            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: BestSellingProductForm = JSON.parse(
            dataBestSellingProductSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              bestSellingProducts: dataSaved.bestSellingProducts.map(
                (item) => ({
                  ...item,
                  url:
                    initData.bestSellingProducts.find(
                      (el) => el.key === item.key
                    )?.url ?? item.url,
                  cardTitle:
                    initData.bestSellingProducts.find(
                      (el) => el.key === item.key
                    )?.cardTitle ?? item.cardTitle,
                })
              ),
            };

            console.log(dataForm, initData, "else");

            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataBestSellingProductSaved) {
        const dataSaved = JSON.parse(
          dataBestSellingProductSaved?.contents ?? "{}"
        );

        setDataBestSellingProduct(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataBestSellingProduct(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false)
  };  
  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <>
      <MetaSEO />
     <form onSubmit={handleSubmit(submit, error)}>
        <Paper sx={{ p: 3, mb: 2 }}>

           {
         loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
         <CircularProgress />
       </Box> : <Grid container spacing={6}>
           {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Grid item xs={12}>
              <Box className="d-inline-block">
                <Button
                  variant="contained"
                  startIcon={<CloudUpload />}
                  onClick={() => {
                    append({
                      images: "",
                      cardTitle: "",
                      url: "",
                      amount: 0,
                      key: `BEST_SELLING_PRODUCT_${makeid(8)}`,
                    });
                  }}
                >
                  {t("homepage_bestSellingProduct_content_addImageButton")}
                </Button>
              </Box>
            </Grid>}

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {fields.map((item, index) => {
                  return (
                    <Grid item xs={12} md={3} key={item.id}>
                      <Stack direction={"row"} alignItems={"flex-end"}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Controller
                              key={keyReset}
                              control={control}
                              name={`bestSellingProducts.${index}.images`}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <Box sx={{ position: "relative" }}>
                                    <UploaderCustom
                                      page="feature-destination"
                                      documents={value ?? ""}
                                      onChange={(data: any) => {
                                        onChange(data);
                                      }}
                                      widthImg={""}
                                      heightImg={""}
                                      idBtnUpload="btn-upload"
                                      imageViewHeight="450px"
                                      isShowRequire={errors?.bestSellingProducts?.[index]
                                        ?.images !== undefined}
                                      type="image"
                                    />
                                    {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                                      <Button
                                        variant="text"
                                        sx={{
                                          position: "absolute",
                                          top: "-5px",
                                          right: "-5px",
                                          color: "red",
                                          zIndex: 99,
                                          background: "white",
                                          p: 1,
                                          minWidth: "30px",
                                        }}
                                        onClick={() => onChange("")}
                                      >
                                        <Clear />
                                      </Button>
                                    )}
                                  </Box>
                                );
                              }}
                            />
                           
                            <Typography className="text-14 mt-5">
                              {t("fileUpload_size")}: 325 X 450{" "}
                              {t("fileUpload_unit")}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              label={t(
                                "homepage_bestSellingProduct_content_addABestSellingProductForm_cardTitleField"
                              )}
                              {...register(
                                `bestSellingProducts.${index}.cardTitle`
                              )}
                              InputLabelProps={{ shrink: true }}
                              error={
                                errors?.bestSellingProducts?.[index]?.url !==
                                undefined
                              }
                              helperText={
                                errors?.bestSellingProducts?.[index]?.url !==
                                undefined
                                  ? validationMessage.required
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              fullWidth
                              label={t("urlField")}
                              {...register(`bestSellingProducts.${index}.url`)}
                              InputLabelProps={{ shrink: true }}
                              error={
                                errors?.bestSellingProducts?.[index]?.url !==
                                undefined
                              }
                              helperText={
                                errors?.bestSellingProducts?.[index]?.url !==
                                undefined
                                  ? validationMessage.required
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Delete
                          color="error"
                          onClick={() => remove(index)}
                          sx={{ mb: "10px" }}
                        />}
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {watch("bestSellingProducts").length > 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-18 fw-600">
                      {t("homepage_bestSellingProduct_content_preview")}
                    </Typography>
                  </Grid>
                  {watch("bestSellingProducts").map((item, index) => {
                    return (
                      <Grid item xs={12} md={3} key={index}>
                        {item.images && (
                          <>
                            <Box
                              className={"position-relative h-400 rounded-10"}
                            >
                              <Box
                                className="position-absolute left-0 top-0 w-full h-400 rounded-10 zIndex-1"
                                sx={{ background: "rgba(0, 0, 0, 0.5)" }}
                              ></Box>
                              <img
                                className="position-absolute right-0 top-0 w-full h-400  rounded-10"
                                src={
                                  item.images && item.images.includes("https")
                                    ? item.images
                                    : `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${item.images}`
                                }
                              />

                              <Stack
                                spacing={1}
                                className="position-absolute zIndex-1 left-15 top-30"
                              >
                                <Typography className="text-white fw-600">
                                  {item.cardTitle}
                                </Typography>
                                <Typography className="text-white text-14">
                                  {item.amount} {t("itineraries")}
                                </Typography>
                                <Typography className="border-full bg-tkg-blue text-white fw-600 rounded-20 px-10 py-5">
                                  {t("learnMoreButton")}
                                </Typography>
                              </Stack>
                            </Box>
                          </>
                        )}
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
                  {t("saveButton")}
                </Button>
              </Box>
            </Grid>
          </Grid>}
        </Paper>
      </form>
      
      
    </>
  );
};

export default BestSellingProductPage;
