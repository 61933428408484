import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import Swal from "sweetalert2";
import homePageService from "../../../services/homePageService";
import {
  HandleContactUs,
  ItemData,
  PayloadHeroSection,
} from "../../../interfaces/homePage";
import { Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { makeid } from "../../../utils";

const validationSchema = yup.object().shape({
  mainAddress: yup
    .object()
    .shape({
      address: yup.string().required(validationMessage.required),
      phone: yup.string().required(validationMessage.required),
      email: yup
        .string()
        .required(validationMessage.required)
        .email(validationMessage.email),
      url: yup.string(),
    })
    .required(validationMessage.required),
  openHours: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        hours: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
  support: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        location: yup.string().required(validationMessage.required),
        phone: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
  emailSupport: yup.string().required(validationMessage.required),
  emergenceContact: yup
    .object()
    .shape({
      phone: yup.string().required(validationMessage.required),
      email: yup
        .string()
        .required(validationMessage.required)
        .email(validationMessage.email),
    })
    .required(validationMessage.required),
  appMobileUrl: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        name: yup.string().required(validationMessage.required),
        url: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});

const defaultData = {
  mainAddress: {
    address: "",
    phone: "",
    email: "",
    url: "",
  },
  openHours: [
    {
      key: `NAVIGATION_CONTACT_US_OPEN_HOURS_${makeid(8)}`,
      hours: "",
    },
  ],
  support: [
    {
      key: `NAVIGATION_CONTACT_US_SUPPORT_${makeid(8)}`,
      location: "",
      phone: "",
    },
  ],
  emailSupport: "",
  emergenceContact: {
    phone: "",
    email: "",
  },
  appMobileUrl: [
    {
      key: `NAVIGATION_CONTACT_US_APP_MOBILE_${makeid(8)}`,
      name: "",
      url: "",
    },
  ],
};

const ContactUs = () => {
  const { language } = useGlobalContext();
  const { t } = useTranslation();
  const [dataContact, setDataContact] = useState<ItemData | null>(null);
  const [dataContactSaved, setDataContactSaved] = useState<ItemData | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<HandleContactUs>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<HandleContactUs> = async (data) => {
    const payload: PayloadHeroSection = {
      language: language ?? "en",
      type: "homepage",
      section: "contact-us",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataContact) {
      const res = await homePageService.update(dataContact.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Contact Us has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Contact Us has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<HandleContactUs> = (errors) => {
    console.log(errors);
  };

  const {
    fields: fieldsOpenHours,
    append: appendOpenHours,
    remove: removeOpenHours,
  } = useFieldArray({
    control,
    name: "openHours",
  });

  const {
    fields: fieldsSupport,
    append: appendSupport,
    remove: removeSupport,
  } = useFieldArray({
    control,
    name: "support",
  });

  const {
    fields: fieldsApp,
    append: appendApp,
    remove: removeApp,
  } = useFieldArray({
    control,
    name: "appMobileUrl",
  });

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: any) =>
          item.language === language && item.section === "contact-us"
      );
      if (data) {
        setDataContact(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {          
          setDataContactSaved(data);
        }
        const initData: HandleContactUs = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: HandleContactUs = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              mainAddress: {
                address: initData.mainAddress.address ?? dataSaved.mainAddress.address,
                phone: initData.mainAddress.phone ?? dataSaved.mainAddress.phone,
                email: initData.mainAddress.email ?? dataSaved.mainAddress.email,
                url: initData.mainAddress.url ?? dataSaved.mainAddress.url,
            },
            openHours: dataSaved.openHours.map(item => ({
                // key: item.key,
key: `NAVIGATION_CONTACT_US_OPEN_HOURS_${makeid(8)}`,
                hours: initData.openHours.find(el => el.key === item.key)?.hours ?? item.hours
            })),
            support: dataSaved.support.map(item => ({
                // key: item.key,
key: `NAVIGATION_CONTACT_US_SUPPORT_${makeid(8)}`,
                location: initData.support.find(el => el.key === item.key)?.location ?? item.location,
                phone: initData.support.find(el => el.key === item.key)?.phone ?? item.phone,
            })),
            emailSupport: initData.emailSupport ?? dataSaved.emailSupport,
            emergenceContact: {
                phone: initData.emergenceContact.phone ?? dataSaved.emergenceContact.phone,
                email: initData.emergenceContact.email ?? dataSaved.emergenceContact.email
            },
            appMobileUrl: dataSaved.appMobileUrl.map(item => ({
                // key: item.key,
                key: `NAVIGATION_CONTACT_US_APP_MOBILE_${makeid(8)}`,
                name: initData.appMobileUrl.find(el => el.key === item.key)?.name ?? item.name,
                url: initData.appMobileUrl.find(el => el.key === item.key)?.url ?? item.url,
            }))
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: HandleContactUs = JSON.parse(
            dataContactSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              mainAddress: {
                address: initData.mainAddress.address ?? dataSaved.mainAddress.address,
                phone: initData.mainAddress.phone ?? dataSaved.mainAddress.phone,
                email: initData.mainAddress.email ?? dataSaved.mainAddress.email,
                url: initData.mainAddress.url ?? dataSaved.mainAddress.url,
            },
            openHours: dataSaved.openHours.map(item => ({
                key: item.key,
                hours: initData.openHours.find(el => el.key === item.key)?.hours ?? item.hours
            })),
            support: dataSaved.support.map(item => ({
                key: item.key,
                location: initData.support.find(el => el.key === item.key)?.location ?? item.location,
                phone: initData.support.find(el => el.key === item.key)?.phone ?? item.phone,
            })),
            emailSupport: initData.emailSupport ?? dataSaved.emailSupport,
            emergenceContact: {
                phone: initData.emergenceContact.phone ?? dataSaved.emergenceContact.phone,
                email: initData.emergenceContact.email ?? dataSaved.emergenceContact.email
            },
            appMobileUrl: dataSaved.appMobileUrl.map(item => ({
                key: item.key,
                name: initData.appMobileUrl.find(el => el.key === item.key)?.name ?? item.name,
                url: initData.appMobileUrl.find(el => el.key === item.key)?.url ?? item.url,
            }))
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        // reset(initData);
      } else if (dataContactSaved) {
        const dataSaved = JSON.parse(dataContactSaved?.contents ?? "{}");
        setDataContact(null);
        reset(dataSaved);
      } else {
        setDataContact(null);
        reset(defaultData);
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <>
    {
       loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
       <CircularProgress />
     </Box> :  <form onSubmit={handleSubmit(submit, error)}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Paper sx={{ p: 2 }}>
            <Box mb={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t("homepage_navigationMenu_contactUs_headOffice_header")}
              </Typography>
              <TextField
                {...register("mainAddress.address")}
                label={t(
                  "homepage_navigationMenu_contactUs_headOffice_address"
                )}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.mainAddress?.address !== undefined}
                helperText={
                  errors?.mainAddress?.address !== undefined
                    ? errors?.mainAddress?.address?.message
                    : ""
                }
              />
              <TextField
                {...register("mainAddress.phone")}
                label={t("homepage_navigationMenu_contactUs_headOffice_phone")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.mainAddress?.phone !== undefined}
                helperText={
                  errors?.mainAddress?.phone !== undefined
                    ? errors?.mainAddress?.phone?.message
                    : ""
                }
              />
              <TextField
                {...register("mainAddress.email")}
                label={t("homepage_navigationMenu_contactUs_headOffice_email")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.mainAddress?.email !== undefined}
                helperText={
                  errors?.mainAddress?.email !== undefined
                    ? errors?.mainAddress?.email?.message
                    : ""
                }
              />
              <TextField
                {...register("mainAddress.url")}
                label={t(
                  "homepage_navigationMenu_contactUs_headOffice_website"
                )}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.mainAddress?.url !== undefined}
                helperText={
                  errors?.mainAddress?.url !== undefined
                    ? errors?.mainAddress?.url?.message
                    : ""
                }
              />
            </Box>
            <Box mb={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t("homepage_navigationMenu_contactUs_openHours_header")}
              </Typography>
              {fieldsOpenHours.map((item, index) => (
                <TextField
                  key={item.id}
                  {...register(`openHours.${index}.hours`)}
                  label={`${t(
                    "homepage_navigationMenu_contactUs_headOffice_website"
                  )} ${index + 1}`}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.openHours?.[index]?.hours !== undefined}
                  helperText={
                    errors?.openHours?.[index]?.hours !== undefined
                      ? errors?.openHours?.[index]?.hours?.message
                      : ""
                  }
                />
              ))}
              
              {
                language === process.env.REACT_APP_DEFAULT_LANGUAGE && <>
                <Button
                onClick={() => {
                  appendOpenHours({ hours: "", key: `NAVIGATION_CONTACT_US_OPEN_HOURS_${makeid(8)}`, });
                }}
              >
                {t("addTimeButton")}
              </Button>
              <Button
                onClick={() => {
                  removeOpenHours(-1);
                }}
                color="error"
              >
                {t("removeTimeButton")}
              </Button>
                 </>
              }
              
              
            </Box>
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper sx={{ p: 2 }}>
            <Box mb={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t(
                  "homepage_navigationMenu_contactUs_reservationSupport_header"
                )}
              </Typography>
              {fieldsSupport.map((item, index) => (
                <Grid container spacing={2} key={item.id}>
                  <Grid item md={6}>
                    <TextField
                      {...register(`support.${index}.location`)}
                      label={`${t(
                        "homepage_navigationMenu_contactUs_reservationSupport_location"
                      )} ${index + 1}`}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.support?.[index]?.location !== undefined}
                      helperText={
                        errors?.support?.[index]?.location !== undefined
                          ? errors?.support?.[index]?.location?.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      {...register(`support.${index}.phone`)}
                      label={`${t(
                        "homepage_navigationMenu_contactUs_reservationSupport_phone"
                      )} ${index + 1}`}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.support?.[index]?.phone !== undefined}
                      helperText={
                        errors?.support?.[index]?.phone !== undefined
                          ? errors?.support?.[index]?.phone?.message
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              ))}
              {
                language === process.env.REACT_APP_DEFAULT_LANGUAGE && <>
                <Button
                onClick={() => {
                  appendSupport({ location: "", phone: "", key: `NAVIGATION_CONTACT_US_SUPPORT_${makeid(8)}`, });
                }}
              >
                {t("addLocationButton")}
              </Button>
              <Button
                onClick={() => {
                  removeSupport(-1);
                }}
                color="error"
              >
                {t("removeLocationButton")}
              </Button>
                </>
              }
              
              <Box mt={3}>
                <TextField
                  {...register("emailSupport")}
                  label={t(
                    "homepage_navigationMenu_contactUs_reservationSupport_emailSupport"
                  )}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.emailSupport !== undefined}
                  helperText={
                    errors?.emailSupport !== undefined
                      ? errors?.emailSupport?.message
                      : ""
                  }
                />
              </Box>
            </Box>
            <Box mb={2}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t("homepage_navigationMenu_contactUs_emergenceContact_header")}
              </Typography>
              <TextField
                {...register("emergenceContact.phone")}
                label={t(
                  "homepage_navigationMenu_contactUs_emergenceContact_phone"
                )}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.emergenceContact?.phone !== undefined}
                helperText={
                  errors?.emergenceContact?.phone !== undefined
                    ? errors?.emergenceContact?.phone?.message
                    : ""
                }
              />
              <TextField
                {...register("emergenceContact.email")}
                label={t(
                  "homepage_navigationMenu_contactUs_emergenceContact_email"
                )}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.emergenceContact?.email !== undefined}
                helperText={
                  errors?.emergenceContact?.email !== undefined
                    ? errors?.emergenceContact?.email?.message
                    : ""
                }
              />
              {fieldsApp.map((item, index) => (
                <Grid container spacing={2} key={item.id}>
                  <Grid item md={6}>
                    <TextField
                      {...register(`appMobileUrl.${index}.name`)}
                      label={`${t(
                        "homepage_navigationMenu_contactUs_emergenceContact_appName"
                      )} ${index + 1}`}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.appMobileUrl?.[index]?.name !== undefined}
                      helperText={
                        errors?.appMobileUrl?.[index]?.name !== undefined
                          ? errors?.appMobileUrl?.[index]?.name?.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      {...register(`appMobileUrl.${index}.url`)}
                      label={`${t(
                        "homepage_navigationMenu_contactUs_emergenceContact_appURL"
                      )} ${index + 1}`}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.appMobileUrl?.[index]?.url !== undefined}
                      helperText={
                        errors?.appMobileUrl?.[index]?.url !== undefined
                          ? errors?.appMobileUrl?.[index]?.url?.message
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              ))}
              {
                language === process.env.REACT_APP_DEFAULT_LANGUAGE && <>
                <Button
                onClick={() => {
                  appendApp({ name: "", url: "", key: `NAVIGATION_CONTACT_US_APP_MOBILE_${makeid(8)}`, });
                }}
              >
                {t("addAppButton")}
              </Button>
              <Button
                onClick={() => {
                  removeApp(-1);
                }}
                color="error"
              >
                {t("removeAppButton")}
              </Button>
                </>
              }
              
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
        <Button disabled={loadingBtn} type="submit" variant="contained">
          {t("saveButton")}
        </Button>
      </Box>
    </form>
    }
    </>
    
  );
};

export default ContactUs;
