import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { styled } from "@mui/material/styles";
import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import {
  Backdrop,
  Badge,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../media/images/logo-tkg.png";
import userService from "../services/userService";
import { responseSendSignInCode, userAuth } from "../interfaces/authentication";
import { ExpandLess, ExpandMore, Language, Notifications, Search } from "@mui/icons-material";
import { useUrlSearchParams } from "use-url-search-params";
import MainSiteHeader from "./MainSiteHeader";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18t from "../config-translation";
import { makeTitle } from "../utils";
import Clock from "react-live-clock";
import { useGlobalContext } from "../context/MyGlobalContext";
import { makeSlugKey } from "../utils/function";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingBottom: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  textAlign: "center",
}));

const MainSiteLayoutInner = ({ memuList }: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const [params] = useUrlSearchParams();
  let location = useLocation();
  const { setLanguage} = useGlobalContext();

  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(null);
  const openLang = Boolean(anchorElLang);

  const [valueLang, setValueLang] = React.useState("English");


  const handleClickLang = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElLang(event.currentTarget);
  };
  const handleCloseLang = () => {
    setAnchorElLang(null);
  };

  const setLang = (lang: string) => {
    switch (lang) {
      case "en":
        setLanguage("en");
        setValueLang("English");
        localStorage.setItem("language", "en");
        break;
      case "fr":
        setLanguage("fr");
        setValueLang("Français");
        localStorage.setItem("language", "fr");
        break;
      case "cn":
        setLanguage("cn");
        setValueLang("中国");
        localStorage.setItem("language", "cn");
        break;
    }
    i18n.changeLanguage(lang);
    setAnchorElLang(null);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };


  const token = localStorage.getItem("accessToken");

  const getCurrentUser = async () => {
    try {
      let [responseUser] = await Promise.all([
        userService.getCurrent(),
        userService.getSimple()
      ]);

      // const response = await userService.getCurrent();
      let authData: responseSendSignInCode = responseUser.data;
      localStorage.setItem("user", JSON.stringify(authData));
      setIsLoading(false);
      navigate("/");



    } catch (e: any) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("timeZone");
      navigate("/");
    }
  }


  React.useEffect(() => {
    if (params?.token && params?.companyCode) {
      localStorage.setItem('accessToken', `${params?.token}`);
      localStorage.setItem('companyCode', `${params?.companyCode}`);
      localStorage.setItem('timeZone', `${params?.timeZone}`);
      getCurrentUser();
    }
  }, [params]);

  React.useEffect(() => {
    if (token) {
      setIsLoading(false);
    }
  }, [token]);

  console.log(location.pathname.includes("inspiration/update"));
  
  return (
    <Box sx={{ display: "flex" }}>
      {isLoading ? (
        <Backdrop
          sx={{
            color: "#fff",
            background: "white",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress style={{ color: "black" }} />
        </Backdrop>
      ) : (
        <>
          <CssBaseline />
          <MainSiteHeader {...{ open, handleDrawerOpen }} />
          <Drawer
            PaperProps={{
              sx: {
                background: "#283c4c",
              },
            }}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <Link to="/" style={{ margin: "0 auto", display: "block" }}>
                <img src={Logo} alt="" style={{ width: "200px" }} />
              </Link>
            </DrawerHeader>
            <Divider />
            {memuList}
          </Drawer>
          <Main open={open} style={{ height: "100%" }}>
          <Box
              className="mt-47 rounded-8 mb-5"
              sx={{
                color: "#000000",
                fontSize: 16,
                fontWeight: 500,
                boxShadow: "unset",
                display: "flex",
                justifyContent: (!location.pathname.includes("/update") && location.pathname.split("/").pop()) ? "space-between" : "flex-end",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              {(!location.pathname.includes("/update") && location.pathname.split("/").pop()) && <Box>
                <Typography className="text-24 fw-500">
                  {t(`page_header_${makeSlugKey(makeTitle(location.pathname.split("/").pop() ?? ""))}`)}
                </Typography>
              </Box>}
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                <IconButton className="mr-10" color="inherit">
                  <Search />
                </IconButton>

                <Clock
                  format="h:mm A"
                  interval={1000}
                  ticking={true}
                  style={{ fontSize: "23px" }}
                  timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                />

                <IconButton
                  color="inherit"
                  className="ml-10"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <Badge badgeContent={0} color="error">
                    <Notifications sx={{ color: "#174D75" }} />
                  </Badge>
                </IconButton>
              </Box>
            </Box>
            <Box component={"div"} sx={{ height: "100%", mb: "100px" }}>
              <Outlet />
            </Box>
            <Box
              sx={{
                bgcolor: "#fff",
                mt: 3,
                position: "fixed",
                left: 280,
                bottom: 0,
                width: "calc(100% - 280px)",
                height: "90px",
                textAlign: "center",
                py: 2,
                px: 4,
                zIndex: 1, borderTop: "1px solid #d9d9d9"
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", pt: 2 }} >
                <Box>
                  <Typography color="#000" variant="subtitle1" fontSize={"16px"} fontWeight={600}>
                    {`Help Desk: +61 8 7082 5321`}
                  </Typography>
                </Box>

                <Box display={"flex"} alignItems={"center"}>
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} spacing={"5px"} mr={"25px"}>
                    <Typography color="#000" variant="subtitle1" fontSize={"16px"} fontWeight={600}>
                      {`User Guide`}
                    </Typography>
                    <Typography color="#000" variant="subtitle1" fontSize={"16px"} fontWeight={600}>
                      {`|`}
                    </Typography>
                    <Typography color="#000" variant="subtitle1" fontSize={"16px"} fontWeight={600}>
                      {`FAQs`}
                    </Typography>
                    <Typography color="#000" variant="subtitle1" fontSize={"16px"} fontWeight={600}>
                      {`|`}
                    </Typography>
                    <Typography color="#000" variant="subtitle1" fontSize={"16px"} fontWeight={600}>
                      {`Support`}
                    </Typography>
                  </Stack>

                  <Box display={"flex"} alignItems={"center"}>
                    <Box>
                      <Button onClick={handleClickLang}
                        startIcon={<Language sx={{ color: "#000" }} />}
                        endIcon={openLang ? <ExpandLess sx={{ color: "#000" }} /> : <ExpandMore sx={{ color: "#000" }} />}
                        sx={{ color: "#000", textTransform: "capitalize", fontSize: "16px", fontWeight: 600 }}
                      >
                        {valueLang}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElLang}
                        open={openLang}
                        onClose={handleCloseLang}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={() => setLang("en")}>
                          <ListItemIcon>
                            <Language fontSize="small" />
                          </ListItemIcon>
                          English
                        </MenuItem>
                        <MenuItem onClick={() => setLang("fr")}>
                          <ListItemIcon>
                            <Language fontSize="small" />
                          </ListItemIcon>
                          Français
                        </MenuItem>
                        <MenuItem onClick={() => setLang("cn")}>
                          <ListItemIcon>
                            <Language fontSize="small" />
                          </ListItemIcon>
                          中国
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Typography color="#000" variant="subtitle1" fontSize={"14px"}>
                {`© ${new Date().getFullYear()} - TKG Platform Technology`}
              </Typography>
            </Box>
          </Main>

        </>
      )}
    </Box>
  );
};

const MainSiteLayout = ({ memuList }: any) => {
  return (
    <I18nextProvider i18n={i18t}>
      <MainSiteLayoutInner memuList={memuList} />
    </I18nextProvider>
  );
}

export default MainSiteLayout;
