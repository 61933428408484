import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";
import { Clear, Delete } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import { ItemHero } from "../../../../../interfaces/homePage";
import { WhyChooseUsSpecialiseForm } from "../../../../../interfaces/navigationMenu";
const defaultData = {
  title: "",
  headerSection: {
    title: "",
    textBody: "",
    images: "",
    urlButton: "",
  },

  dataLimit: [
    {
      key: `NAVIGATION_WHY_CHOOSE_US_SPECIALISE_${makeid(8)}`,
      title: "",
      textBody: "",
      images: "",
      urlButton: "",
    },
  ],
};
const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  headerSection: yup.object().shape({
    title: yup.string().required(validationMessage.required),
    textBody: yup.string().required(validationMessage.required),
    images: yup
      .string()
      .nullable()
      .transform((value) => (value === undefined ? null : value)),
    urlButton: yup
      .string()
      .nullable()
      .transform((value) => (value === undefined ? null : value)),
  }),
  dataLimit: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        textBody: yup.string().required(validationMessage.required),
        images: yup
          .string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
        urlButton: yup
          .string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
      })
    )
    .required(validationMessage.required),
});
const Specialise = () => {
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [dataSpecialise, setDataSpecialise] = useState<ItemHero | null>(null);
  const [dataSpecialiseSaved, setDataSpecialiseSaved] = useState<ItemHero | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyReset, setKeyReset] = useState("");
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsSpecialiseForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dataLimit",
  });
  const submit: SubmitHandler<WhyChooseUsSpecialiseForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "specialise",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataSpecialise) {
      const res = await homePageService.update(dataSpecialise.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Specialise has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Specialise has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<WhyChooseUsSpecialiseForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "specialise"
      );
      if (data) {
        setDataSpecialise(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {          
          setDataSpecialiseSaved(data);
        }
        const initData: WhyChooseUsSpecialiseForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: WhyChooseUsSpecialiseForm = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...initData,
              dataLimit: initData.dataLimit.map(item => ({
                ...item,
                key: `NAVIGATION_WHY_CHOOSE_US_SPECIALISE_${makeid(8)}`,
                //  key: item.key,
              }))
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: WhyChooseUsSpecialiseForm = JSON.parse(
            dataSpecialiseSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              headerSection: {
                title: initData.headerSection.title ?? dataSaved.headerSection.title,
                textBody: initData.headerSection.textBody ?? dataSaved.headerSection.textBody,
                images: dataSaved.headerSection.images,
                urlButton: initData.headerSection.urlButton ?? dataSaved.headerSection.urlButton,
              },
            
              dataLimit: dataSaved.dataLimit.map(item => ({
                key: item.key,
                title: initData.dataLimit.find(el => el.key === item.key)?.title ?? item.title,
                textBody: initData.dataLimit.find(el => el.key === item.key)?.textBody ?? item.textBody,
                images: item.images,
                urlButton: initData.dataLimit.find(el => el.key === item.key)?.urlButton ?? item.urlButton,
              }))
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataSpecialiseSaved) {
        const dataSaved = JSON.parse(dataSpecialiseSaved?.contents ?? "{}");
        setDataSpecialise(null);
        reset(dataSaved);
        setKeyReset(makeid(5));
      } else {
        setDataSpecialise(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <>
    {
       loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
       <CircularProgress />
     </Box> :  <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ p: 3, mb: 2 }}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("title")?.length > 50 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("title")?.length}/50
            </Typography>

            <TextField
              size="small"
              fullWidth
              {...register(`title`)}
              label={t("homepage_navigationMenu_whyChooseUs_specialise_title")}
              InputLabelProps={{ shrink: true }}
              error={errors?.title !== undefined}
              helperText={
                errors?.title !== undefined ? validationMessage.required : ""
              }
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper sx={{ p: 3, mb: 2 }}>
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography className="text-16 fw-600 text-tkg-blue">
              {t(
                "homepage_navigationMenu_whyChooseUs_specialise_section_header"
              )}{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("headerSection.title")?.length > 50 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("headerSection.title")?.length}/50
            </Typography>

            <TextField
              size="small"
              fullWidth
              {...register(`headerSection.title`)}
              label={t(
                "homepage_navigationMenu_whyChooseUs_specialise_section_title"
              )}
              InputLabelProps={{ shrink: true }}
              error={errors?.headerSection?.title !== undefined}
              helperText={
                errors?.headerSection?.title !== undefined
                  ? validationMessage.required
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-14 fw-600 mb-10" color={watch("headerSection.textBody")?.length > 120 ? "error" : "green"}>
              {t("checkLength")}: {watch("headerSection.textBody")?.length}/120
            </Typography>
            <TextField
              size="small"
              fullWidth
              {...register(`headerSection.textBody`)}
              label={t(
                "homepage_navigationMenu_whyChooseUs_specialise_section_textBody"
              )}
              InputLabelProps={{ shrink: true }}
              error={errors?.headerSection?.textBody !== undefined}
              helperText={
                errors?.headerSection?.textBody !== undefined
                  ? validationMessage.required
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              key={keyReset}
              control={control}
              name={`headerSection.images`}
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <Box sx={{ position: "relative" }}>
                    <UploaderCustom
                      page="master-setup"
                      documents={value ?? ""}
                      onChange={(data: any) => onChange(data)}
                      widthImg={'1200px'}
                      heightImg={''}
                      idBtnUpload="btn-upload"
                      type="image"
                      imageViewHeight="425px"
                      isShowRequire={errors.headerSection?.images !== undefined}
                    />
                    {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                      <Button
                        variant="contained"
                        sx={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          color: "red",
                          zIndex: 99,
                          background: "white",
                          p: 1,
                          minWidth: "30px",
                        }}
                        onClick={() => onChange("")}
                      >
                        <Delete />
                      </Button>
                    )}
                  </Box>
                );
              }}
            />

            <Typography className="text-14 mt-5">
              {t("fileUpload_size")}: 1328 X 425 {t("fileUpload_unit")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  size="small"
                  fullWidth
                  {...register(`headerSection.urlButton`)}
                  label={t(
                    "homepage_navigationMenu_whyChooseUs_specialise_section_buttonURL"
                  )}
                  InputLabelProps={{ shrink: true }}
                  placeholder="https://tweettour.com/"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="contained">
                  {t(
                    "homepage_navigationMenu_whyChooseUs_specialise_section_buttonContent"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <>
        <Typography className="text-24 fw-600">
          {t("dataLimit_length")}: 6{" "}
        </Typography>

        {fields.map((item, index) => (
          <Paper sx={{ p: 3, mb: 2 }} key={index}>
           {(index && language === process.env.REACT_APP_DEFAULT_LANGUAGE) ? <Stack alignItems={"flex-end"}><Delete color="error" onClick={() => remove(index)} /></Stack> : null}
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography
                  className="text-14 fw-600 mb-10"
                  color={
                    watch(`dataLimit.${index}.title`)?.length > 35
                      ? "error"
                      : "green"
                  }
                >
                  {t("checkLength")}:{" "}
                  {watch(`dataLimit.${index}.title`)?.length}/35
                </Typography>

                <TextField
                  size="small"
                  fullWidth
                  {...register(`dataLimit.${index}.title`)}
                  label={t("dataLimit_title")}
                  InputLabelProps={{ shrink: true }}
                  error={errors?.dataLimit?.[index]?.title !== undefined}
                  helperText={
                    errors?.dataLimit?.[index]?.title !== undefined
                      ? validationMessage.required
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="text-14 fw-600 mb-10"
                  color={watch("title")?.length > 90 ? "error" : "green"}
                >
                  {t("checkLength")}: {watch("title")?.length}/90
                </Typography>

                <TextField
                  size="small"
                  fullWidth
                  {...register(`dataLimit.${index}.textBody`)}
                  label={t("dataLimit_textBody")}
                  InputLabelProps={{ shrink: true }}
                  error={errors?.dataLimit?.[index]?.textBody !== undefined}
                  helperText={
                    errors?.dataLimit?.[index]?.textBody !== undefined
                      ? validationMessage.required
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  key={keyReset}
                  control={control}
                  name={`dataLimit.${index}.images`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Box sx={{ position: "relative" }}>
                        <UploaderCustom
                          page="master-setup"
                          documents={value ?? ""}
                          onChange={(data: any) => onChange(data)}
                          widthImg={''}
                          heightImg={''}
                          idBtnUpload="btn-upload"
                          type="image"
                          imageViewHeight="382px"
                          isShowRequire={errors.dataLimit?.[index]?.images !== undefined}
                        />
                        {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                               <Button variant="text" sx={{ position: "absolute", top: "-5px", right: "-5px", color: "red", zIndex: 99, background: "white", p: 1, minWidth: "30px" }} onClick={() => onChange("")}><Clear /></Button>
                        )}
                      </Box>
                    );
                  }}
                />

                <Typography className="text-14 mt-5">
                  {t("fileUpload_size")}: 638 X 382 {t("fileUpload_unit")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  size="small"
                  fullWidth
                  {...register(`dataLimit.${index}.urlButton`)}
                  label={t(
                    "dataLimit_buttonURL"
                  )}
                  InputLabelProps={{ shrink: true }}
                  placeholder="https://tweettour.com/"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="contained">
                  {t(
                    "dataLimit_buttonContent"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
              <Grid item xs={12}>
                {(index + 1 === fields.length && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                  <Stack
                    alignItems={"flex-end"}
                  >
                    <Button
                      disabled={fields.length >= 6}
                      variant="contained"
                      onClick={() =>
                        append({
                          title: "",
                          textBody: "",
                          images: "",
                          key: `NAVIGATION_WHY_CHOOSE_US_SPECIALISE_${makeid(8)}`,
                        })
                      }
                    >
                      {t(
                        "addMoreButton"
                      )}
                    </Button>
                  </Stack>
                )}
              </Grid>
            </Grid>
          </Paper>
        ))}
      </>

      <Stack className="mt-30" alignItems={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
    }
    </>
   
  );
};

export default Specialise;
