const endPointAPI = {
    USER: {
        CURRENT: "/user/current",
        FIND_SIMPLE: "/user/find-simple"
    },
    LANGUAGE_CONTENT: {
        STORE: "/language-content/contents/store",
        UPDATE: "/language-content/contents/update",
        DELETE: "/language-content/contents/delete",
        GET_BY_ID: "/language-content/contents/find-detail-by-id",
        GET_LIST_LANG: "/language-content/contents/get-list-lang",
        GET_LIST_MAIN_SITE: "/language-content/contents/get-list-main-site",
    },
    SEO: {
        STORE: "/cms/seo/store",
        FIND_LANG_SECTION: "/cms/seo/find-by-lang-section",
        UPDATE: "/cms/seo/update",
    },
    MASTER_SETUP: {
        STORE: "/cms/master/store",
        GET_LIST: "/cms/master/get-list",
        UPDATE: "/cms/master/update",
        DELETE: "/cms/master/delete",
    },
    HOME_PAGE: {
        STORE: "/cms/homepage/store",
        GET_LIST: "/cms/homepage/get-list-by-type",
        UPDATE: "/cms/homepage/update",
        DELETE: "/cms/homepage/delete",
    },
    INSPIRATION: {
        STORE: "/cms/blog/store",
        GET_LIST: "/cms/blog/public/search",
        GET_ALL_LIST: "/cms/blog/get-list",
        UPDATE: "/cms/blog/update",
        DELETE: "/cms/blog/delete",
        FIND_DETAIL: "/cms/blog/find-detail",
    }, 
    CUSTOMER_REVIEW: {
        STORE: "/cms/customer-review/store",
        GET_LIST: "/cms/customer-review/search",
        FIND_DETAIL: "/cms/customer-review/public/find-detail",
        UPDATE: "/cms/customer-review/update",
        DELETE: "/cms/customer-review/delete",
    }, 
    FAQs: {
        STORE: "/cms/faqs/store",
        GET_LIST_RANDOM: "/cms/faqs/public/get-random",
        GET_LIST: "/cms/faqs/public/get-list",
        FIND_DETAIL: "/cms/faqs/public/find-detail",
        UPDATE: "/cms/faqs/update",
        DELETE: "/cms/faqs/delete",
    }, 
    LOCATION: {
        LIST_COUNTRIES: "/cms/public/location/countries",
        LIST_LOCATIONS: "/cms/public/location/destinations-by-countries",
    }

}

export default endPointAPI;