import { Box, CircularProgress, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  AddAPhoto,
  AddToPhotos,
  AddToPhotosOutlined,
  CloudUpload,
  UploadFile,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

interface DocumentUploadInterface {
  documents: string | null;
  // setDocuments: any;
  onChange: (data: any) => void;
  page: string;
  widthImg: any;
  heightImg: any;
  idBtnUpload: string;
  isEdit?: boolean;
  type: string;
  isUploadIcon?: boolean;
  isShowRequire?: boolean
  imageViewHeight?: string
  cssHeight?: string
}
const UploaderCustom = ({
  page,
  widthImg,
  heightImg = "auto",
  documents,
  // setDocuments,
  onChange,
  idBtnUpload,
  isEdit = true,
  type,
  isUploadIcon,
  isShowRequire,
  imageViewHeight="auto",
  cssHeight
}: DocumentUploadInterface) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [urlFile, setUrlFile] = useState("");
  const [urlName, setUrlName] = useState("");
  const { t } = useTranslation();

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    const reader: any = new FileReader();
    setLoading(true);

    
    if (!file) {
      Swal.fire({
        icon: "error",
        text: "Please select an document",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setLoading(false);
      setSuccess(false);
      return;
    }

    if (file && file.type.includes("image") && type && type === "pdf") {
      Swal.fire({
        icon: "error",
        text: "Please select an document",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setLoading(false);
      setSuccess(false);
      return;
    } else if (
      file &&
      file.type.includes("application") &&
      type &&
      type === "image"
    ) {
      Swal.fire({
        icon: "error",
        text: "Please select an image",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setLoading(false);
      setSuccess(false);
      return;
    }
    if (
      file.size / 1024 >
      Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE)
    ) {
      Swal.fire({
        icon: "error",
        text:
          "Max file size is " +
          Number(process.env.REACT_APP_PUBLIC_IMAGE_MAX_SIZE) / 1024 +
          "MB!",
        timer: 2000,
        showConfirmButton: false,
        showCancelButton: false,
      });
      setLoading(false);
      setSuccess(false);
      return;
    }

    reader.onload = async () => {

      const form = new FormData();
      if (file) {
        form.append("file", file);
        form.append("image", file);
        form.append("width", widthImg ? widthImg : '');
        form.append("height", heightImg ? heightImg : '');
        form.append("page", page);
        form.append("bucket", process.env.REACT_APP_BUCKET_S3 ?? "");
        form.append("bucket_folder", process.env.REACT_APP_BUCKET_FOLDER ?? "");
        form.append("company", localStorage.getItem("companyCode") ?? "");
        fetch(
          "https://dev-file-system.tkgplatform.com.au/api/components/upload-image",
          {
            method: "POST",
            body: form,
            headers: process.env.REACT_APP_UPLOAD_IMAGE_LOCAL
              ? {
                "x-api-key":
                  "clZvSRq5BYftEyNtmX2PiUIdMfkz5E3ljswW12iWEDIZtK4SSnsRzvimrXz6l04T",
              }
              : {},
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            // console.log(res);
            if (res.meta.status === 200) {
              setUrlFile(res.response.preview);
              setUrlName(res.response.name);
              Swal.fire({
                icon: "success",
                text: "Upload document success!",
                timer: 2000,
                showConfirmButton: false,
                showCancelButton: false,
              });
              setLoading(false);
            }
          });
      }

    };
    reader.readAsDataURL(file);
  };

  const VisuallyHiddenInput = styled("input")({
    height: "100%",
    position: "absolute",
    width: "100%",
    cursor: "pointer",
    top: 0,
    right: 0,
    zIndex: 99,
    fontSize: "13px",
    opacity: 0,
  });

  useEffect(() => {
    if (urlFile) {
      onChange(urlFile);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlFile]);

  // console.log(documents);

  useEffect(() => {
    // console.log(documents);

    if (!documents) {
      setUrlFile("");
      setLoading(false);
    }
  }, [documents]);
// console.log(loading);

  return (
    <Box
      display={"flex"}
      justifyContent={documents || urlFile ? "start" : "center"}
      alignItems={"center"}
      flexDirection={documents || urlFile ? "row" : "column"}
      // height={(urlFile && urlFile.length > 0) ? "300px" : "200px"}
      border={"1px dashed #d9d9d9"}
      borderRadius={"8px"}
      sx={{ background:  isShowRequire ? "#ffd4d4" : "#f5f5f5",  height: documents ? "auto" : cssHeight }}
    >
      {documents ? (
        <Box>
          {type === "pdf" ? (
            <Stack alignItems={"center"} direction={"row"} spacing={1} p={1}>
              <Box
                component={"a"}
                href={documents}
                target="_blank"
                sx={{ textDecoration: "none", color: "#174d75", fontSize: 14 }}
              >
                {documents}
                {/* <PictureAsPdf sx={{ fontSize: "30px", color: "#174d75" }} /> */}
              </Box>
            </Stack>
          ) : (
            <img src={documents} style={{ width: "100%", height: imageViewHeight  }} />

          )}
        </Box>

      ) : urlFile && urlFile.length > 0 ? (
        <Box>
          {type === "image" ? (
            <img src={urlFile} style={{ width: "100%", height: imageViewHeight }} />
          ) : (
            <Stack alignItems={"center"} direction={"row"} spacing={1} p={1}>
              <UploadFile sx={{ fontSize: "30px", color: "#174d75" }} />
              <Box
                component={"a"}
                href={urlFile}
                target="_blank"
                sx={{ textDecoration: "none", color: "#174d75", fontSize: 14 }}
              >
                {urlName}
              </Box>
            </Stack>
          )}
        </Box>
      ) : (
        <Stack
          className="w-full h-full"
          position={"relative"}
          minWidth={"64px"}
          fontSize={"0.75rem"}
          color={"#5b5b5b"}
          borderRadius={"8px"}
          overflow={"hidden"}
          mx={0}
          py={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {loading ? (
            <Stack spacing={2} alignItems={"center"}>
              <CircularProgress />
              <Typography sx={{ fontWeight: 600, mt: 1, color: "#000" }}>Uploading ...</Typography>
            </Stack>
          ) : (
            <Box className="col-auto" style={{ paddingLeft: "10px" }}>
              <Box mt={0}>
                <label
                  htmlFor={idBtnUpload}
                  className="text-15 d-flex text-center"
                >
                  <Box>
                    {type === "image" ? <AddAPhoto fontSize="large" /> : <CloudUpload fontSize="large" />}
                    <br />
                    <Typography sx={{ fontWeight: 600, mb: 0, color: "#000" }}>
                      {isUploadIcon ? t("iconUpload_caption") : type === "image" ? t("imageUpload_text") : t("pdfUpload_text")}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        mb: 0,
                        fontSize: "13px",
                        color: "#000",
                      }}
                    >
                      {t("fileUpload_content")}
                    </Typography>
                  </Box>
                </label>
                <VisuallyHiddenInput
                  type="file"
                  id={idBtnUpload}
                  accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, image/png, image/jpeg"
                  onChange={handleImageChange}
                  className="file-input"
                  disabled={!isEdit}
                />
              </Box>
            </Box>
          )}
        </Stack>
      )}

      {error && !success && (
        <Box className="mt-8" color={"red"}>
          {error}
        </Box>
      )}
    </Box>
  );
};

export default UploaderCustom;
