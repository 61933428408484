import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AttachMoney, Clear, CloudUpload, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../interfaces/masterSetup";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import Swal from "sweetalert2";
import { FeatureDestinationForm, ItemHero } from "../../../interfaces/homePage";
import { makeid } from "../../../utils";
import homePageService from "../../../services/homePageService";
import { FormCurrencyField } from "../../../components/TextFields/FormCurrencyField";
import data from "../../../mockdata/data";
const defaultData = {
  destinations: [],
};
const validationSchema = yup.object().shape({
  destinations: yup
    .array()
    .of(
      yup.object().shape({
        images: yup.string().required(validationMessage.required),
        country: yup.string().required(validationMessage.required),
        countryFlag: yup
          .string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
        priceFrom: yup.string().required(validationMessage.required),
        url: yup.string().required(validationMessage.required),
        key: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});
const FeatureDestinationPage = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataFeatureDestination, setDataFeatureDestination] =
    useState<MasterSetupResItem | null>(null);
  const [dataFeatureDestinationSaved, setDataFeatureDestinationSaved] =
    useState<MasterSetupResItem | null>(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<FeatureDestinationForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "destinations",
  });

  const submit: SubmitHandler<FeatureDestinationForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "feature-destination",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataFeatureDestination) {
      const res = await homePageService.update(
        dataFeatureDestination.id,
        payload
      );
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Feature Destination has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Feature Destination has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<FeatureDestinationForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "feature-destination"
      );
      if (data) {
        setDataFeatureDestination(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataFeatureDestinationSaved(data);
        }
        const initData: FeatureDestinationForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: FeatureDestinationForm = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              destinations: dataSaved.destinations.map(item => ({
                ...item,
                url: initData.destinations.find(el => el.key === item.key)?.url ?? item.url
              })),
            };
            console.log(dataForm, initData, "if");

            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: FeatureDestinationForm = JSON.parse(
            dataFeatureDestinationSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              destinations: dataSaved.destinations.map(item => ({
                ...item,
                url: initData.destinations.find(el => el.key === item.key)?.url ?? item.url
              })),
            };

            console.log(dataForm, initData, "else");

            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataFeatureDestinationSaved) {
        const dataSaved = JSON.parse(dataFeatureDestinationSaved?.contents ?? "{}");

        setDataFeatureDestination(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataFeatureDestination(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ p: 3, mb: 2 }}>
        {
           loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
           <CircularProgress />
         </Box> : <Grid container spacing={6}>
         {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Grid item xs={12}>
            <Box className="d-inline-block">
              <Button
                variant="contained"
                startIcon={<CloudUpload />}
                onClick={() => {
                  append({ images: "", country: "", priceFrom: "", url: "", key: `FEATURE_DESTINATION_${makeid(8)}` });
                }}
              >
                {t("homepage_featureDestination_addImageButton")}
              </Button>
            </Box>
          </Grid>}

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {fields.map((item, index) => {
                return (
                  <Grid item xs={12} md={3} key={item.id}>
                    <Stack
                      direction={"row"}
                      alignItems={"flex-end"}
                      className="border-full p-10 rounded-10"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Controller
                            key={keyReset}
                            control={control}
                            name={`destinations.${index}.images`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Box sx={{ position: "relative" }}>
                                  <UploaderCustom
                                    page="feature-destination"
                                    documents={value ?? ""}
                                    onChange={(data: any) => {
                                      onChange(data);
                                    }}
                                    widthImg={""}
                                    heightImg={""}
                                    idBtnUpload="btn-upload"
                                    imageViewHeight="102px"
                                    isShowRequire={errors?.destinations?.[index]?.images !==
                                      undefined}
                                    type="image"
                                  />
                                  {value && (
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        color: "red",
                                        zIndex: 99,
                                        background: "white",
                                        p: 1,
                                        minWidth: "30px",
                                      }}
                                      onClick={() => onChange("")}
                                    >
                                      <Clear />
                                    </Button>
                                  )}
                                </Box>
                              );
                            }}
                          />
                         
                          <Typography className="text-14 mt-5">
                            {t("fileUpload_size")}: 209 X 102{" "}
                            {t("fileUpload_unit")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            key={keyReset}
                            control={control}
                            name={`destinations.${index}.country`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="countries"
                                    options={data.listCountry}
                                    getOptionLabel={(option) => option.label}
                                    value={data.listCountry.find(
                                      (item) => item.label === value
                                    )}
                                    onChange={(e, value) => {
                                      if (value) {
                                        onChange(value.label);
                                        setValue(
                                          `destinations.${index}.countryFlag`,
                                          `https://flagcdn.com/w20/${value.code.toLowerCase()}.png`
                                        );
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        <img
                                          loading="lazy"
                                          width="20"
                                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                          alt=""
                                        />
                                        {option.label}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        label={t(
                                          "homepage_featureDestination_addmoreForm_countriesField"
                                        )}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        error={
                                          errors?.destinations?.[index]
                                            ?.country !== undefined
                                        }
                                        helperText={
                                          errors?.destinations?.[index]
                                            ?.country !== undefined
                                            ? validationMessage.required
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </>
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCurrencyField
                            name={`destinations.${index}.priceFrom`}
                            control={control}
                            rules={{ required: false }}
                            currency={<AttachMoney />}
                            readOnly={false}
                            textFieldProps={{
                              label: `${t(
                                "homepage_featureDestination_addmoreForm_fromField"
                              )}`,
                              size: "small",
                              fullWidth: true,
                              placeholder: "",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            fullWidth
                            label={t("urlField")}
                            {...register(`destinations.${index}.url`)}
                            InputLabelProps={{ shrink: true }}
                            error={
                              errors?.destinations?.[index]?.url !== undefined
                            }
                            helperText={
                              errors?.destinations?.[index]?.url !== undefined
                                ? validationMessage.required
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                     {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Delete
                        color="error"
                        onClick={() => remove(index)}
                        sx={{ mb: "10px" }}
                      />}
                    </Stack>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {watch("destinations").length > 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-18 fw-600">
                    {t("homepage_featureDestination_currentFeatureDestination")}
                  </Typography>
                </Grid>
                {watch("destinations").map((item, index) => {
                  return (
                    <Grid item xs={12} md={3} key={index}>
                      {item.images && (
                        <>
                          <Box className={"position-relative h-100 rounded-10"}>
                            <Box
                              className="position-absolute left-0 top-0 w-full h-100 rounded-10 zIndex-1"
                              sx={{ background: "rgba(0, 0, 0, 0.5)" }}
                            ></Box>
                            <img
                              className="position-absolute right-0 top-0 w-full h-100  rounded-10"
                              src={
                                item.images && item.images.includes("https")
                                  ? item.images
                                  : `${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${item.images}`
                              }
                            />

                            <Typography className="position-absolute zIndex-1 left-15 top-10 text-white fw-600">
                              <Stack spacing={1}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  srcSet={`${item.countryFlag} 2x`}
                                  src={item.countryFlag ?? ""}
                                  alt=""
                                />
                                {item.country}
                              </Stack>
                            </Typography>
                            <Typography className="position-absolute zIndex-1 bottom-10 right-15 border-full bg-tkg-blue text-white fw-600 rounded-20 px-10 py-5">
                              {t("homepage_featureDestination_priceFrom")} $
                              {item.priceFrom}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
                {t("saveButton")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        }
        
      </Paper>
    </form>
  );
};

export default FeatureDestinationPage;
