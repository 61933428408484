import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../interfaces/masterSetup";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { FeatureServiceForm, ItemHero } from "../../../interfaces/homePage";
import homePageService from "../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../utils";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import { Clear, Delete } from "@mui/icons-material";
import ContentComponent from "../../../components/Editor/ContentComponent";

const validationSchema = yup.object().shape({
  services: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        icon: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        subtitle: yup.string().required(validationMessage.required),
        bodyText: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});
const ContentItem = () => {
  const defaultData = {
    services: [{ icon: "", title: "", subtitle: "", bodyText: "", key: `FEATURE_SERVICE_${makeid(8)}` }],
  };
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataFeatureService, setDataFeatureService] =
    useState<MasterSetupResItem | null>(null);
  const [dataFeatureServiceSaved, setDataFeatureServiceSaved] =
    useState<MasterSetupResItem | null>(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<FeatureServiceForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "services",
  });

  const submit: SubmitHandler<FeatureServiceForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "feature-service-content",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataFeatureService) {
      const res = await homePageService.update(dataFeatureService.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Feature Service Content has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Feature Service Content has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<FeatureServiceForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    // setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language &&
          item.section === "feature-service-content"
      );      
      if (data) {
        setDataFeatureService(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {          
          setDataFeatureServiceSaved(data);
        }
        const initData: FeatureServiceForm = JSON.parse(data.contents ?? "{}");
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: FeatureServiceForm = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              services: dataSaved.services.map(item => ({
                key: item.key,
                icon: item.icon,
                title: initData.services.find(el => el.key === item.key)?.title ?? item.title,
                subtitle: initData.services.find(el => el.key === item.key)?.subtitle ?? item.subtitle,
                bodyText: initData.services.find(el => el.key === item.key)?.bodyText ?? item.title,
              })),
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: FeatureServiceForm = JSON.parse(
            dataFeatureServiceSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              services: dataSaved.services.map(item => ({
                key: item.key,
                icon: item.icon,
                title: initData.services.find(el => el.key === item.key)?.title ?? item.title,
                subtitle: initData.services.find(el => el.key === item.key)?.subtitle ?? item.subtitle,
                bodyText: initData.services.find(el => el.key === item.key)?.bodyText ?? item.title,
              })),
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataFeatureServiceSaved) {
        const dataSaved = JSON.parse(dataFeatureServiceSaved?.contents ?? "{}");

        setDataFeatureService(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataFeatureService(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  
  return (
    <Box mt={2}>
      {
          loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box> : <form onSubmit={handleSubmit(submit, error)}>
        <Typography className="text-24 fw-600">
          {t("dataLimit_length")}: 3{" "}
        </Typography>

        <Grid container spacing={2}>
          {fields.map((item, index) => {
            return (
              <Grid item xs={12} key={item.id}>
                <Paper sx={{ p: 2 }}>
                  {(index && language === process.env.REACT_APP_DEFAULT_LANGUAGE) ? (
                    <Stack alignItems={"flex-end"}>
                      <IconButton onClick={() => remove(index)}>
                        <Delete color="error" />
                      </IconButton>
                    </Stack>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid xs={12} md={3}>
                      <Box pl={2} pt={2}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name={`services.${index}.icon`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="feature-service"
                                  documents={value ?? ""}
                                  onChange={(data: any) => {
                                    onChange(data);
                                  }}
                                  widthImg={60}
                                  heightImg={60}
                                  idBtnUpload="btn-upload"
                                  isUploadIcon={true}
                                  type="image"
                                  isShowRequire={errors?.services?.[index]?.icon !== undefined}
                                />
                                {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                                  <Button variant="text" sx={{ position: "absolute", top: "-5px", right: "-5px", color: "red", zIndex: 99, background: "white", p: 1, minWidth: "30px" }} onClick={() => onChange("")}><Clear /></Button>
                                )}
                              </Box>
                            );
                          }}
                        />
                        <Typography className="text-14 mt-5">
                          {t("fileUpload_size")}: 48 X 42 {t("fileUpload_unit")}
                        </Typography>
                      </Box>

                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch(`services.${index}.title`) &&
                              watch(`services.${index}.title`).length > 30
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch(`services.${index}.title`)
                          ? watch(`services.${index}.title`).length
                          : 0}
                        /30
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        label={t("homepage_featureService_content_title")}
                        {...register(`services.${index}.title`)}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.services?.[index]?.title !== undefined}
                        helperText={
                          errors?.services?.[index]?.title !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                      <Box mt={2} />
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch(`services.${index}.subtitle`) &&
                              watch(`services.${index}.subtitle`).length > 80
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch(`services.${index}.subtitle`)
                          ? watch(`services.${index}.subtitle`).length
                          : 0}
                        /80
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        label={t("homepage_featureService_content_subtitle")}
                        {...register(`services.${index}.subtitle`)}
                        InputLabelProps={{ shrink: true }}
                        error={
                          errors?.services?.[index]?.subtitle !== undefined
                        }
                        helperText={
                          errors?.services?.[index]?.subtitle !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch(`services.${index}.bodyText`) &&
                              watch(`services.${index}.bodyText`).length > 120
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch(`services.${index}.bodyText`)
                          ? watch(`services.${index}.bodyText`).length
                          : 0}
                        /120
                      </Typography>
                      <Typography className="text-18 fw-600"> {t("homepage_featureService_content_bodyText")}</Typography>
                      <Controller
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <ContentComponent data={value ?? ""} onChange={onChange} />
                            // <Editor
                            //   disabled={false}
                            //   value={value ?? ""}
                            //   apiKey={process.env.REACT_APP_EDITOR_API_KEY}
                            //   init={{
                            //     ...configNote.editorAttr,
                            //     height: 300,
                            //   }}
                            //   onInit={(evt, editor) => {
                            //     if (index) {
                            //       editorsRef.current = editor;
                            //     } else {
                            //       editorsRef.current = editor;
                            //     }
                            //   }}
                            //   onEditorChange={onChange}
                            // />
                          );
                        }}
                        name={`services.${index}.bodyText`}
                      />

                    </Grid>
                    {(index + 1 === fields.length && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                      <Grid item xs={12}>
                        <Stack alignItems={"flex-end"}>
                          <Button
                            variant="outlined"
                            disabled={fields.length >= 3}
                            onClick={() =>
                              append({
                                key: `FEATURE_SERVICE_${makeid(8)}`,
                                icon: "",
                                title: "",
                                subtitle: "",
                                bodyText: "",
                              })
                            }
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
            {t("saveButton")}
          </Button>
        </Box>
      </form>
      }
      
    </Box>
  );
};

export default ContentItem;
