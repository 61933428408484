import { Box } from "@mui/material";
import { styled } from "@mui/system";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Quill from "react-quill";
interface Props {
  data: string;
  onChange: (data: string) => void;
  disabled?: boolean;
  error?: boolean;
  heightContent?: string;
}
const ContentComponent = ({
  data,
  onChange,
  disabled = false,
  error = false,
  heightContent = "500px",
}: Props) => {
  const quillRef = useRef<any>(null);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "blockquote"],
      [
        { align: [] },
        // { align: "center" },
        // { align: "right" },
        // { align: "justify" },
      ],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      // [{ size: [] }],
      ["link"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "align",
    "color",
    "background",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const [content, setContent] = useState("");

  useEffect(() => {
    if (data) {
      setContent(data);
    }
  }, [data]);
  function handleChangeInput(content: string) {
    onChange(content);
  }

  return (
    <EditorWrapper
      className={`overflow-auto ${error ? "border-danger" : "border-full"}`}
      minHeight={heightContent}
    >
      <Quill
        readOnly={disabled}
        style={{ minHeight: heightContent }}
        className="quill-custom"
        ref={quillRef}
        modules={modules}
        formats={formats}
        value={data || ""}
        onChange={handleChangeInput}
        theme="snow"
      />
    </EditorWrapper>
  );
};

const EditorWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "minHeight",
})(({ minHeight }: any) => ({
  ".quill-custom": {
    ".ql-editor": {
      height: minHeight,
    },
  },
}));
export default ContentComponent;
