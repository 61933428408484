import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { PayloadHeroSection } from "../interfaces/homePage";
import { PayloadSEO } from "../interfaces/masterSeo";

const homePageService = {

    getList: async (type: string) => {
        try {
            return await apiInstance.get(`${endPointAPI.HOME_PAGE.GET_LIST}/${type}`)
        } catch (error) {
            return null
        }
    },

    store: async (payload: PayloadHeroSection) => {
        try {
            return await apiInstance.post(endPointAPI.HOME_PAGE.STORE, payload)
        } catch (error) {
            return null
        }
    },


    update: async (id: number, payload: PayloadHeroSection) => {
        try {
            return await apiInstance.put(`${endPointAPI.HOME_PAGE.UPDATE}/${id}`, payload)
        } catch (error) {
            return null
        }
    },
}
export default homePageService;
