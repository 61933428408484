import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import {
  HandleMasterSetup,
  MasterSetupRequest,
  MasterSetupResItem,
} from "../../interfaces/masterSetup";
import UploaderCustom from "../../components/Upload/UploaderCustom";
import { useEffect, useState } from "react";
import validationMessage from "../../constants/validationMessage";
import data from "../../mockdata/data";
import masterSetupService from "../../services/masterSetupService";
import Swal from "sweetalert2";
import SwitchWithName from "../../components/switch/SwitchWithName";
import { useGlobalContext } from "../../context/MyGlobalContext";
import { makeid } from "../../utils";
import { Clear, Delete } from "@mui/icons-material";

const defaultData = {
  websiteLogoIsInHeader: false,
  websiteLogoIsInFooter: false,
  websiteLogoIsShow: false,
  wishlistDisplayIsInHeader: false,
  wishlistDisplayIsInFooter: false,
  wishlistDisplayIsShow: false,
  language: [],
  currency: [],

  menuBar: [
    {
      title: "",
      url: "",
    },
  ],
};
const validationSchema = yup.object().shape({
  websiteLogo: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  websiteLogoIsInHeader: yup.boolean().required(validationMessage.required),
  websiteLogoIsInFooter: yup.boolean().required(validationMessage.required),
  websiteLogoIsShow: yup.boolean().required(validationMessage.required),
  wishlistDisplayIsInHeader: yup.boolean().required(validationMessage.required),
  wishlistDisplayIsInFooter: yup.boolean().required(validationMessage.required),
  wishlistDisplayIsShow: yup.boolean().required(validationMessage.required),
  language: yup
    .array(yup.string().required(validationMessage.required))
    .required(),
  currency: yup
    .array(yup.string().required(validationMessage.required))
    .required(),
  menuBar: yup
    .array(
      yup.object().shape({
        title: yup.string().required(validationMessage.required),
        url: yup.string().required(validationMessage.required),
        key: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});
const MasterSetup = () => {
  const [keyReset, setKeyReset] = useState("");
  const [dataMasterSetup, setDataMasterSetup] =
    useState<MasterSetupResItem | null>(null);
  const [dataMasterSetupSaved, setDataMasterSetupSaved] =
    useState<MasterSetupResItem | null>(null);
  const { language } = useGlobalContext();

  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<HandleMasterSetup>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "menuBar",
  });

  const submit: SubmitHandler<HandleMasterSetup> = async (data) => {
    const payload: MasterSetupRequest = {
      language: language ?? localStorage.getItem("language") ?? "en",
      section: "master-setup",
      contents: JSON.stringify(data),
    };
    if (dataMasterSetup) {
      const res = await masterSetupService.update(dataMasterSetup.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Master setup has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await masterSetupService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Master setup has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
  };
  const error: SubmitErrorHandler<HandleMasterSetup> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    const response = await masterSetupService.getListData();

    if (response?.status === 200) {
      const data = response.data.find(
        (item: MasterSetupResItem) =>
          item.language === language && item.section === "master-setup"
      );
      if (data) {
        setDataMasterSetup(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataMasterSetupSaved(data);
        }
        const initData: HandleMasterSetup = JSON.parse(data.contents);

        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: HandleMasterSetup = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              menuBar: dataSaved.menuBar.map((item, index) => ({
                key: item.key,
                url: item.url,
                title:
                  initData.menuBar.find((el) => el.key === item.key)?.title ??
                  item.title,
              })),
            };
            console.log(dataForm, initData, "if");

            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: HandleMasterSetup = JSON.parse(
            dataMasterSetupSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              menuBar: dataSaved.menuBar.map((item, index) => ({
                key: item.key,
                url: item.url,
                title:
                  initData.menuBar.find((el) => el.key === item.key)?.title ??
                  item.title,
              })),
            };

            console.log(dataForm, initData, "else");

            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        setKeyReset(makeid(5));
      } else if (dataMasterSetupSaved) {
        const dataSaved = JSON.parse(dataMasterSetupSaved?.contents ?? "{}");

        setDataMasterSetup(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataMasterSetup(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <Box>
      <Typography variant="h5"></Typography>
      <form onSubmit={handleSubmit(submit, error)}>
        <Paper sx={{ mb: 2 }}>
          <Box>
            <Box p={2}>
              <Typography>{t("masterSetup_websiteLogo_cardHeader")}</Typography>
              <Divider />
            </Box>

            <Grid container spacing={3} sx={{ px: "75px", py: "20px" }}>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="websiteLogo"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="master-setup"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={""}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              type="image"
                            />
                            {value && (
                              <Button
                                variant="text"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => onChange("")}
                              >
                                <Clear />
                              </Button>
                            )}
                          </Box>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="websiteLogoIsInHeader"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e, checked) => onChange(checked)}
                              />
                            }
                            label={t("masterSetup_websiteLogo_headerCheckbox")}
                            labelPlacement="start"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="websiteLogoIsInFooter"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e, checked) => onChange(checked)}
                              />
                            }
                            label={t("masterSetup_websiteLogo_footerCheckbox")}
                            labelPlacement="start"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="websiteLogoIsShow"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <SwitchWithName
                                checked={value}
                                setChecked={(checked) => onChange(checked)}
                                keyField="masterSetup_websiteLogo_switch"
                              />
                            }
                            label={t("masterSetup_websiteLogo_labelSwitch")}
                            labelPlacement="start"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper sx={{ mb: 2 }}>
          <Box>
            <Box p={2}>
              <Typography>
                {t("masterSetup_wishlishDisplay_cardHeader")}
              </Typography>
              <Divider />
            </Box>

            <Grid container spacing={3} sx={{ px: "75px", py: "20px" }}>
              <Grid item xs={12} md={6}></Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="wishlistDisplayIsInHeader"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e, checked) => onChange(checked)}
                              />
                            }
                            label={t(
                              "masterSetup_wishlishDisplay_headerCheckbox"
                            )}
                            labelPlacement="start"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="wishlistDisplayIsInFooter"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={value}
                                onChange={(e, checked) => onChange(checked)}
                              />
                            }
                            label={t(
                              "masterSetup_wishlishDisplay_footerCheckbox"
                            )}
                            labelPlacement="start"
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name="wishlistDisplayIsShow"
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <FormControlLabel
                            control={
                              <SwitchWithName
                                checked={value}
                                setChecked={(checked) => onChange(checked)}
                                keyField="masterSetup_wishlishDisplay_switch"
                                width={100}
                              />
                            }
                            label={t("masterSetup_wishlishDisplay_labelSwitch")}
                            labelPlacement="start"
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Paper sx={{ mb: 2 }}>
          <Box>
            <Box p={2}>
              <Typography>
                {t("masterSetup_chooseLanguageAndCurrency_cardHeader")}
              </Typography>
              <Divider />
            </Box>

            <Grid container spacing={3} sx={{ px: "75px", py: "20px" }}>
              <Grid item xs={12} md={6}>
                <Controller
                  key={keyReset}
                  control={control}
                  name="language"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        size={"small"}
                        disablePortal
                        multiple
                        options={data.languages}
                        getOptionLabel={(option) => option.label}
                        defaultValue={data.languages.filter((item) =>
                          value.includes(item.value)
                        )}
                        onChange={(event, value) =>
                          onChange(value.map((v) => v.value))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t(
                              "masterSetup_chooseLanguageAndCurrency_language"
                            )}
                            // placeholder="Select Language"
                            InputLabelProps={{ shrink: true }}
                            error={errors.language !== undefined}
                            helperText={
                              errors.language !== undefined
                                ? validationMessage.required
                                : ""
                            }
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  key={keyReset}
                  control={control}
                  name="currency"
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        size={"small"}
                        disablePortal
                        multiple
                        options={["USD", "AUD", "EUR"]}
                        defaultValue={value}
                        onChange={(event, value) => onChange(value)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t(
                              "masterSetup_chooseLanguageAndCurrency_currency"
                            )}
                            // placeholder="Select Currency"
                            InputLabelProps={{ shrink: true }}
                            error={errors.currency !== undefined}
                            helperText={
                              errors.currency !== undefined
                                ? validationMessage.required
                                : ""
                            }
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper sx={{ mb: 2 }}>
          <Box>
            <Box p={2}>
              <Typography>{t("masterSetup_addMenuBar_cardHeader")}</Typography>
              <Divider />
            </Box>

            <Box p={3}>
              <Grid container spacing={3}>
                {fields.length &&
                  fields.map((field, index) => (
                    <Grid item xs={12} md={2} key={index}>
                      <Card sx={{ p: 2, backgroundColor: "#f5f5f5" }}>
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#fff" }}
                          {...register(`menuBar.${index}.title`)}
                          label={t("masterSetup_addMenuBar_title")}
                          InputLabelProps={{ shrink: true }}
                          error={errors?.menuBar?.[index]?.title !== undefined}
                          helperText={
                            errors?.menuBar?.[index]?.title !== undefined
                              ? validationMessage.required
                              : ""
                          }
                        />
                        <TextField
                          fullWidth
                          sx={{ backgroundColor: "#fff" }}
                          {...register(`menuBar.${index}.url`)}
                          label={t("urlField")}
                          InputLabelProps={{ shrink: true }}
                          error={errors?.menuBar?.[index]?.url !== undefined}
                          helperText={
                            errors?.menuBar?.[index]?.url !== undefined
                              ? validationMessage.required
                              : ""
                          }
                        />
                        {language ===
                          process.env.REACT_APP_DEFAULT_LANGUAGE && (
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => remove(index)}
                              color="error"
                              sx={{ minWidth: "30px" }}
                            >
                              <Delete sx={{ fontSize: 20 }} />
                            </Button>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  ))}

                {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        append({
                          title: "",
                          url: "",
                          key: `MASTER_SETUP_${makeid(8)}`,
                        })
                      }
                    >
                      {t("masterSetup_addMenuBar_addMenu")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Paper>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button type="submit" variant="contained" sx={{ px: 3 }}>
            {t("saveButton")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default MasterSetup;
