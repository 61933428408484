import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const userService = {
    getCurrent: async () => {
        return await apiInstance.get(endPointAPI.USER.CURRENT);
    },

    getSimple: async () => {
        return await apiInstance.post(endPointAPI.USER.FIND_SIMPLE, { isActive: true });
    }
}

export default userService;
