import { createBrowserRouter } from "react-router-dom";
import MainSiteLayout from "../layouts/MainSiteLayout";
import TKGTreeView from "../layouts/menu";

import UserProfile from "../pages/profile/UserProfile";
import MasterSeo from "../pages/MasterSeo/MasterSeo";
import MasterSetup from "../pages/MasterSetup/MasterSetup";
import HeroSection from "../pages/HomePage/HeroSection/HeroSection";
import WhyChooseTweetPage from "../pages/HomePage/Navigation/WhyChooseTweet";
import FeatureDestinationPage from "../pages/HomePage/FeatureDestination";
import CustomerReview from "../pages/HomePage/CustomerReview";
import FeatureServicePage from "../pages/HomePage/FeatureService";
import BestSellingProductPage from "../pages/HomePage/BestSellingProduct";
import WhyChooseUsPage from "../pages/HomePage/WhyChooseUs";
import OtherPage from "../pages/HomePage/Other";
import FinderForm from "../pages/HomePage/KnowMore/FinderForm";
import InspirationUs from "../pages/HomePage/KnowMore/InspirationUs";
import BrochureUpload from "../pages/HomePage/KnowMore/BrochureUpload";
import FindLocalAgentPage from "../pages/HomePage/Navigation/FindLocalAgent";
import ContactUs from "../pages/HomePage/ContactUs";
import AboutUs from "../pages/HomePage/AboutUs";
import InspirationList from "../pages/HomePage/Navigation/Inspiration";
import InspirationForm from "../pages/HomePage/Navigation/Inspiration/form";
import LoginPage from "../pages/LoginPage";
import ViewInspiration from "../pages/HomePage/Navigation/Inspiration/ViewInspiration";
import CustomerReviewForm from "../pages/HomePage/CustomerReview/form";
import TermAndConditionPage from "../pages/HomePage/TermCondition";
import PrivacyPolicyPage from "../pages/HomePage/PrivacyPolicy";
import FooterPage from "../pages/HomePage/Footer";
import CookiePolicyPage from "../pages/HomePage/CookiePolicy";
import FAQsPage from "../pages/HomePage/FAQs";
import FAQForm from "../pages/HomePage/FAQs/form";
import ViewFaqs from "../pages/HomePage/FAQs/ViewAllFAQs";

// @ts-ignore
const router = createBrowserRouter([
  {
    path: "/",
    element: <MainSiteLayout memuList={<TKGTreeView />} />,
    children: [
      {
        path: "/master-seo",
        element: <MasterSeo />,
      },
      {
        path: "/master-setup",
        element: <MasterSetup />,
      },
      {
        path: "/profile/:id",
        element: <UserProfile />,
      },

      {
        path: "/homepage",
        children: [
          {
            path: "/homepage/hero-section",
            element: <HeroSection />,
          },
          {
            path: "/homepage/navigation-menu",
            children: [
              {
                path: "/homepage/navigation-menu/why-choose-us",
                element: <WhyChooseTweetPage />,
              },
              {
                path: "/homepage/navigation-menu/find-local-agent",
                element: <FindLocalAgentPage />,
              },
              {
                path: "/homepage/navigation-menu/contact-us",
                element: <ContactUs />,
              },
              {
                path: "/homepage/navigation-menu/about-us",
                element: <AboutUs />,
              },
              {
                path: "/homepage/navigation-menu/inspiration",
                children: [
                  {
                    path: "/homepage/navigation-menu/inspiration",
                    element: <InspirationList />,
                  },
                  {
                    path: "/homepage/navigation-menu/inspiration/view",
                    element: <ViewInspiration />,
                  },
                  {
                    path: "/homepage/navigation-menu/inspiration/store",
                    element: <InspirationForm />,
                  },
                  {
                    path: "/homepage/navigation-menu/inspiration/update/:slug",
                    element: <InspirationForm />,
                  },
                ]
              },
            ],
          },
          {
            path: "/homepage/feature-destination",
            element: <FeatureDestinationPage />,
          },
          {
            path: "/homepage/feature-service",
            element: <FeatureServicePage />,
          },
          {
            path: "/homepage/best-selling-product",
            element: <BestSellingProductPage />,
          },
          {
            path: "/homepage/why-choose-us",
            element: <WhyChooseUsPage />,
          },
          {
            path: "/homepage/customer-review",
            children: [
              {
                path: "/homepage/customer-review",
                element: <CustomerReview />,
              },
              {
                path: "/homepage/customer-review/add-review",
                element: <CustomerReviewForm />,
              },
              {
                path: "/homepage/customer-review/update/:id/:keyLang",
                element: <CustomerReviewForm />,
              },
            ]
          },
          {
            path: "/homepage/know-more",
            children: [
              {
                path: "/homepage/know-more/agent-finder",
                element: <FinderForm />,
              },
              {
                path: "/homepage/know-more/brochure-upload",
                element: <BrochureUpload />,
              },
              {
                path: "/homepage/know-more/inspiration-by-us",
                element: <InspirationUs />,
              }
            ]
            // element: <KnowMore />,
          },
          {
            path: "/homepage/other",
            element: <OtherPage />,
          },
          {
            path: "/homepage/faqs",
            children: [
              {
                path: "/homepage/faqs",
                element: <FAQsPage />,
              },
              {
                path: "/homepage/faqs/add-faq",
                element: <FAQForm />,
              },
              {
                path: "/homepage/faqs/update/:keyLang",
                element: <FAQForm />,
              },
              {
                path: "/homepage/faqs/view-faq",
                element: <ViewFaqs />,
              }
            ]
          },
          {
            path: "/homepage/login-page",
            element: <LoginPage />
        },
      
        
        ],
      },  {
          path: "/terms-and-conditions",
          element: <TermAndConditionPage />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicyPage />,
        },
        {
          path: "/cookie-policy",
          element: <CookiePolicyPage />,
        },
      {
        path: "/footer",
        element: <FooterPage />,
      }
    ],
  },
]);

export default router;
