import {
  InputAdornment,
  StandardTextFieldProps,
  TextField,
} from "@mui/material";
import * as React from "react";
import { forwardRef } from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { NumericFormat } from "react-number-format";

interface NumericFormatProps {
  onChange: (event: { target: { name: string; value?: number } }) => void;
  name: string;
}

export const CurrencyNumericFormat = forwardRef<unknown, NumericFormatProps>(
  function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.floatValue,
            },
          });
        }}
        thousandSeparator={","}
        valueIsNumericString
      />
    );
  }
);

export interface FormCurrencyFieldProps<T extends FieldValues>
  extends UseControllerProps<T> {
  textFieldProps?: StandardTextFieldProps;
  currency?: JSX.Element;
  readOnly?: boolean;
}

export const FormCurrencyField = <T extends FieldValues>({
  textFieldProps,
  currency,
  readOnly = false,
  ...other
}: FormCurrencyFieldProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController(other);

  return (
    <TextField
    sx={{
      ".MuiOutlinedInput-root": {
        pl: "8px"
      }
    }}
      {...textFieldProps}
      {...field}
      required={!!other.rules?.required}
      size={"small"}
      InputProps={{
        ...textFieldProps?.InputProps,
        readOnly: readOnly,
        inputComponent: CurrencyNumericFormat as any,
        startAdornment: currency && (
          <InputAdornment position="start">{currency}</InputAdornment>
        ),
      }}
      helperText={error ? error.message : null}
      error={!!error}
    />
  );
};
