import React from "react";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Overview from "./tabs-content/Overview";
import WhyUs from "./tabs-content/WhyUs";
import Specialise from "./tabs-content/Specialise";
import FAQ from "./tabs-content/FAQ";
import SearchLink from "./tabs-content/SearchLink";
import Subscribe from "./tabs-content/Subscribe";
import { useTranslation } from "react-i18next";
import AccreditationsCompany from "./tabs-content/AccreditationsCompany";
const WhyChooseTweetPage = () => {
  const [value, setValue] = React.useState("overview");
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_overviewTab")}
                </Typography>
              }
              value={"overview"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_whyUsTab")}
                </Typography>
              }
              value={"why-us"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_specialiseTab")}
                </Typography>
              }
              value={"specialise"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_faqTab")}
                </Typography>
              }
              value={"faq"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_searchLinkTab")}
                </Typography>
              }
              value={"search-link"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_subscribeTab")}
                </Typography>
              }
              value={"subscribe"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_whyChooseUs_accreditationsCompanyTab")}
                </Typography>
              }
              value={"accreditations-company"}
            />
          </TabList>
        </Box>
        <TabPanel value="overview" sx={{ px: 0 }}>
          <Overview />
        </TabPanel>
        <TabPanel value="why-us" sx={{ px: 0 }}>
          <WhyUs />
        </TabPanel>
        <TabPanel value="specialise" sx={{ px: 0 }}>
          <Specialise />
        </TabPanel>
        <TabPanel value="faq" sx={{ px: 0 }}>
          <FAQ />
        </TabPanel>
        <TabPanel value="search-link" sx={{ px: 0 }}>
          <SearchLink />
        </TabPanel>
        <TabPanel value="subscribe" sx={{ px: 0 }}>
          <Subscribe />
        </TabPanel>
        <TabPanel value="accreditations-company" sx={{ px: 0 }}>
          <AccreditationsCompany />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default WhyChooseTweetPage;
