import React, { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import Swal from "sweetalert2";
import homePageService from "../../../services/homePageService";
import {
  HandleAboutUs,
  HandleContactUs,
  ItemData,
  PayloadHeroSection,
} from "../../../interfaces/homePage";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Clear, Delete, DeleteOutline } from "@mui/icons-material";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import ContentComponent from "../../../components/Editor/ContentComponent";
import { makeid } from "../../../utils";
import TextAreaComponent from "../../../components/TextArea/TextAreaComponent";

const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  imageCover: yup.string().required(validationMessage.required),
  ourJourney: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        time: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        subTitle: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
  ourCompany: yup
    .object()
    .shape({
      content: yup.string().required(validationMessage.required),
      image: yup.string().required(validationMessage.required),
    })
    .required(validationMessage.required),
  values: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        content: yup.string().required(validationMessage.required),
        image: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
  setUsApart: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        subTitle: yup.string().required(validationMessage.required),
        content: yup.string().required(validationMessage.required),
        image: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
  submitForm: yup
    .object()
    .shape({
      title: yup.string().required(validationMessage.required),
      subTitle: yup.string().required(validationMessage.required),
      image: yup.string().required(validationMessage.required),
    })
    .required(validationMessage.required),
  happyClients: yup.object().shape({
    title: yup.string().required(validationMessage.required),
    subTitle: yup.string().required(validationMessage.required),
    content: yup.string().required(validationMessage.required),
    images: yup
      .array(
        yup.object().shape({
          name: yup.string().required(validationMessage.required),
          path: yup.string().required(validationMessage.required),
        })
      )
      .required(validationMessage.required),
    counter: yup.number().required(validationMessage.required),
  }),
});

const AboutUs = () => {
  const { language } = useGlobalContext();
  const { t } = useTranslation();
  const [dataContact, setDataContact] = useState<ItemData | null>(null);
  const [dataContactSaved, setDataContactSaved] = useState<ItemData | null>(
    null
  );
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultData = {
    title: "",
    imageCover: "",
    ourJourney: [
      {
        key: `NAVIGATION_ABOUT_US_OUR_JOURNEY_${makeid(8)}`,
        time: "",
        title: "",
        subTitle: "",
      },
    ],
    ourCompany: {
      title: "",
      content: "",
      image: "",
    },
    values: [
      {
        key: `NAVIGATION_ABOUT_US_VALUES_${makeid(8)}`,
        title: "",
        content: "",
        image: "",
      },
    ],
    setUsApart: [
      {
        key: `NAVIGATION_ABOUT_US_SET_APART_${makeid(8)}`,
        title: "",
        subTitle: "",
        content: "",
        image: "",
      },
    ],
    submitForm: {
      title: "",
      subTitle: "",
      image: "",
    },
    happyClients: {
      title: "",
      subTitle: "",
      content: "",
      images: [{
        path: "",
        name: "",
      }],
      counter: 0,
    },
  };
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<HandleAboutUs>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const {
    fields: fieldsOurJourney,
    append: appendOurJourney,
    remove: removeOurJourney,
  } = useFieldArray({
    control,
    name: "ourJourney",
  });

  const {
    fields: fieldsValues,
    append: appendValues,
    remove: removeValues,
  } = useFieldArray({
    control,
    name: "values",
  });
  const {
    fields: fieldsHappyClientsImage,
    append: appendHappyClientsImage,
    remove: removeHappyClientsImage,
  } = useFieldArray({
    control,
    name: "happyClients.images",
  });

  const {
    fields: fieldsSetUsApart,
    append: appendSetUsApart,
    remove: removeSetUsApart,
  } = useFieldArray({
    control,
    name: "setUsApart",
  });

  const submit: SubmitHandler<HandleAboutUs> = async (data) => {
    const payload: PayloadHeroSection = {
      language: language ?? "en",
      type: "homepage",
      section: "about-us",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataContact) {
      const res = await homePageService.update(dataContact.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "About Us has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "About Us has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<HandleAboutUs> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: any) => item.language === language && item.section === "about-us"
      );
      if (data) {
        setDataContact(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataContactSaved(data);
        }
        const initData: HandleAboutUs = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } 
        else {
          const dataSaved: HandleAboutUs = JSON.parse(
            dataContactSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {            
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              imageCover: dataSaved.imageCover,
              ourJourney: dataSaved.ourJourney.map((item) => ({
                key: item.key,
                time:
                  initData.ourJourney.find((el) => el.key === item.key)?.time ??
                  item.time,
                title:
                  initData.ourJourney.find((el) => el.key === item.key)
                    ?.title ?? item.title,
                subTitle:
                  initData.ourJourney.find((el) => el.key === item.key)
                    ?.subTitle ?? item.subTitle,
              })),
              ourCompany: {
                content:
                  initData.ourCompany.content ?? dataSaved.ourCompany.content,
                image: dataSaved.ourCompany.image,
              },
              values: dataSaved.values.map((item) => ({
                key: item.key,
                title:
                  initData.values.find((el) => el.key === item.key)?.title ??
                  item.title,
                content:
                  initData.values.find((el) => el.key === item.key)?.content ??
                  item.content,
                image: item.image,
              })),
              setUsApart: dataSaved.setUsApart.map((item) => ({
                key: item.key,
                title:
                  initData.setUsApart.find((el) => el.key === item.key)
                    ?.title ?? item.title,
                subTitle:
                  initData.setUsApart.find((el) => el.key === item.key)
                    ?.subTitle ?? item.subTitle,
                content:
                  initData.setUsApart.find((el) => el.key === item.key)
                    ?.content ?? item.content,
                image: item.image,
              })),
              submitForm: {
                title: initData.submitForm.title ?? dataSaved.submitForm.title,
                subTitle:
                  initData.submitForm.subTitle ?? dataSaved.submitForm.subTitle,
                image: dataSaved.submitForm.image,
              },
              happyClients: {
                title:
                  initData?.happyClients?.title ?? dataSaved?.happyClients?.title ?? "",
                subTitle:
                  initData?.happyClients?.subTitle ??
                  dataSaved?.happyClients?.subTitle ?? "",
                content:
                  initData?.happyClients?.content ??
                  dataSaved?.happyClients?.content ?? "",
                images: dataSaved?.happyClients?.images ?? [],
                counter: dataSaved?.happyClients?.counter ?? 0,
              },
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
      } 
      else if (dataContactSaved) {
        const dataSaved = JSON.parse(dataContactSaved?.contents ?? "{}");

        setDataContact(null);
        reset(dataSaved);
      } else {
        setDataContact(null);
        reset(defaultData);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  
  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit, error)}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  {t("homepage_navigation_aboutUs_banner_header")}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextField
                      {...register("title")}
                      label={t("homepage_navigation_aboutUs_banner_title")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.title !== undefined}
                      helperText={
                        errors?.title !== undefined
                          ? errors?.title?.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      control={control}
                      name={`imageCover`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="about-us"
                              type="image"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={1440}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              isShowRequire={errors.imageCover !== undefined}
                            />
                            {language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Button
                                variant="text"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => onChange("")}
                              >
                                <Clear />
                              </Button>
                            )}
                          </Box>
                        );
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 12, p: "4px 14px 0" }}
                      className={
                        errors?.imageCover !== undefined
                          ? "text-color-danger"
                          : ""
                      }
                    >
                      {errors?.imageCover !== undefined
                        ? validationMessage.required
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  {t("homepage_navigation_aboutUs_ourJourney_header")}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  {fieldsOurJourney.map((field, index) => (
                    <Grid item md={3} key={field.id}>
                      <Card sx={{ p: 2 }}>
                        <TextField
                          fullWidth
                          label={t(
                            "homepage_navigation_aboutUs_ourJourney_time"
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`ourJourney.${index}.time`)}
                          error={Boolean(errors.ourJourney?.[index]?.time)}
                          helperText={errors.ourJourney?.[index]?.time?.message}
                        />
                        <TextField
                          fullWidth
                          label={t(
                            "homepage_navigation_aboutUs_ourJourney_title"
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`ourJourney.${index}.title`)}
                          error={Boolean(errors.ourJourney?.[index]?.title)}
                          helperText={
                            errors.ourJourney?.[index]?.title?.message
                          }
                        />
                        <TextField
                          fullWidth
                          label={t(
                            "homepage_navigation_aboutUs_ourJourney_subtitle"
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`ourJourney.${index}.subTitle`)}
                          error={Boolean(errors.ourJourney?.[index]?.subTitle)}
                          helperText={
                            errors.ourJourney?.[index]?.subTitle?.message
                          }
                        />
                        {language ===
                          process.env.REACT_APP_DEFAULT_LANGUAGE && (
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              type="button"
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => removeOurJourney(index)}
                              sx={{ minWidth: "40px" }}
                            >
                              <DeleteOutline />
                            </Button>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  ))}
                  {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                    <Grid item md={3}>
                      <Button
                        type="button"
                        variant="outlined"
                        onClick={() =>
                          appendOurJourney({
                            time: "",
                            title: "",
                            subTitle: "",
                            key: `NAVIGATION_ABOUT_US_OUR_JOURNEY_${makeid(8)}`,
                          })
                        }
                      >
                        {t("addOurJourneyButton")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  {t("homepage_navigation_aboutUs_ourCompany_header")}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <ContentComponent
                            data={value}
                            onChange={onChange}
                            heightContent={"300px"}
                          />
                        );
                      }}
                      name={`ourCompany.content`}
                    />
                    <Typography
                      sx={{ fontSize: 12, p: "4px 14px 0" }}
                      className={
                        errors?.ourCompany?.content !== undefined
                          ? "text-color-danger"
                          : ""
                      }
                    >
                      {errors?.ourCompany?.content !== undefined
                        ? validationMessage.required
                        : ""}
                    </Typography>
                  </Grid>
                  <Grid item md={4}>
                    <Controller
                      control={control}
                      name={`ourCompany.image`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="about-us"
                              type="image"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={""}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              isShowRequire={
                                errors.ourCompany?.image !== undefined
                              }
                            />
                            {language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Button
                                variant="text"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => onChange("")}
                              >
                                <Clear />
                              </Button>
                            )}
                          </Box>
                        );
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 12, p: "4px 14px 0" }}
                      className={
                        errors?.ourCompany?.image !== undefined
                          ? "text-color-danger"
                          : ""
                      }
                    >
                      {errors?.ourCompany?.image !== undefined
                        ? validationMessage.required
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  {t("homepage_navigation_aboutUs_values_header")}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  {fieldsValues.map((field, index) => (
                    <Grid item md={3} key={field.id}>
                      <Card sx={{ p: 2 }}>
                        <Box mb={2}>
                          <Controller
                            control={control}
                            name={`values.${index}.image`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Box sx={{ position: "relative" }}>
                                  <UploaderCustom
                                    page="about-us"
                                    type="image"
                                    documents={value ?? ""}
                                    onChange={(data: any) => onChange(data)}
                                    widthImg={""}
                                    heightImg={""}
                                    idBtnUpload="btn-upload"
                                    isShowRequire={
                                      errors.values?.[index]?.image !==
                                      undefined
                                    }
                                  />
                                  {language ===
                                    process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        color: "red",
                                        zIndex: 99,
                                        background: "white",
                                        p: 1,
                                        minWidth: "30px",
                                      }}
                                      onClick={() => onChange("")}
                                    >
                                      <Clear />
                                    </Button>
                                  )}
                                </Box>
                              );
                            }}
                          />
                          <Typography
                            sx={{ fontSize: 12, p: "4px 14px 0" }}
                            className={
                              errors?.values?.[index]?.image !== undefined
                                ? "text-color-danger"
                                : ""
                            }
                          >
                            {errors?.values?.[index]?.image !== undefined
                              ? validationMessage.required
                              : ""}
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          label={t("homepage_navigation_aboutUs_values_title")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`values.${index}.title`)}
                          error={Boolean(errors.values?.[index]?.title)}
                          helperText={errors.values?.[index]?.title?.message}
                        />
                        <TextField
                          fullWidth
                          label={t(
                            "homepage_navigation_aboutUs_values_content"
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`values.${index}.content`)}
                          multiline
                          rows={5}
                          error={Boolean(errors.values?.[index]?.content)}
                          helperText={errors.values?.[index]?.content?.message}
                        />
                        {language ===
                          process.env.REACT_APP_DEFAULT_LANGUAGE && (
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              type="button"
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => removeValues(index)}
                              sx={{ minWidth: "40px" }}
                            >
                              <DeleteOutline />
                            </Button>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  ))}
                  {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                    <Grid item md={3}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          appendValues({
                            image: "",
                            title: "",
                            content: "",
                            key: `NAVIGATION_ABOUT_US_VALUES_${makeid(8)}`,
                          })
                        }
                      >
                        {t("addValuesButton")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>

            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  {t("homepage_navigation_aboutUs_setUsApart_header")}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  {fieldsSetUsApart.map((field, index) => (
                    <Grid item md={3} key={field.id}>
                      <Card sx={{ p: 2 }}>
                        <Box mb={2}>
                          <Controller
                            control={control}
                            name={`setUsApart.${index}.image`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Box sx={{ position: "relative" }}>
                                  <UploaderCustom
                                    page="about-us"
                                    type="image"
                                    documents={value ?? ""}
                                    onChange={(data: any) => onChange(data)}
                                    widthImg={""}
                                    heightImg={""}
                                    idBtnUpload="btn-upload"
                                    isShowRequire={
                                      errors.setUsApart?.[index]?.image !==
                                      undefined
                                    }
                                  />
                                  {language ===
                                    process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        color: "red",
                                        zIndex: 99,
                                        background: "white",
                                        p: 1,
                                        minWidth: "30px",
                                      }}
                                      onClick={() => onChange("")}
                                    >
                                      <Clear />
                                    </Button>
                                  )}
                                </Box>
                              );
                            }}
                          />
                          <Typography
                            sx={{ fontSize: 12, p: "4px 14px 0" }}
                            className={
                              errors?.setUsApart?.[index]?.image !== undefined
                                ? "text-color-danger"
                                : ""
                            }
                          >
                            {errors?.setUsApart?.[index]?.image !== undefined
                              ? validationMessage.required
                              : ""}
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          label={t("homepage_navigation_aboutUs_setUsApart_title")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`setUsApart.${index}.title`)}
                          error={Boolean(errors.setUsApart?.[index]?.title)}
                          helperText={errors.setUsApart?.[index]?.title?.message}
                        />
                        <TextField
                          fullWidth
                          label={t("homepage_navigation_aboutUs_setUsApart_subtitle")}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`setUsApart.${index}.subTitle`)}
                          error={Boolean(errors.setUsApart?.[index]?.subTitle)}
                          helperText={errors.setUsApart?.[index]?.subTitle?.message}
                        />
                        <TextField
                          fullWidth
                          label={t(
                            "homepage_navigation_aboutUs_setUsApart_content"
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`setUsApart.${index}.content`)}
                          multiline
                          rows={5}
                          error={Boolean(errors.setUsApart?.[index]?.content)}
                          helperText={errors.setUsApart?.[index]?.content?.message}
                        />
                        {language ===
                          process.env.REACT_APP_DEFAULT_LANGUAGE && (
                          <Box display={"flex"} justifyContent={"flex-end"}>
                            <Button
                              type="button"
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => removeSetUsApart(index)}
                              sx={{ minWidth: "40px" }}
                            >
                              <DeleteOutline />
                            </Button>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  ))}
                  {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                    <Grid item md={3}>
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          appendSetUsApart({
                            key: `NAVIGATION_ABOUT_US_SET_APART_${makeid(8)}`,
                            title: "",
                            subTitle: "",
                            content: "",
                            image: "",
                          },)
                        }
                      >
                        {t("addSetUsApartButton")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Paper>
            </Grid>


            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  {t("homepage_navigation_aboutUs_happyClient_header")}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          type="number"
                          label={`${t(
                            "homepage_navigation_aboutUs_happyClient_counterField"
                          )} (*)`}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`happyClients.counter`)}
                          error={Boolean(errors.happyClients?.counter)}
                          helperText={errors.happyClients?.counter?.message}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {fieldsHappyClientsImage.map((field, index) => (
                            <Grid item md={12} key={field.id}>
                              <Controller
                                control={control}
                                name={`happyClients.images.${index}.path`}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Box sx={{ position: "relative" }}>
                                      <UploaderCustom
                                        page="about-us"
                                        type="image"
                                        documents={value ?? ""}
                                        onChange={(data: string) => {
                                          onChange(data);
                                          setValue(
                                            `happyClients.images.${index}.name`,
                                            data
                                          );
                                        }}
                                        widthImg={""}
                                        heightImg={""}
                                        idBtnUpload="btn-upload"
                                        isShowRequire={
                                          errors.happyClients?.images?.[index]
                                            ?.path !== undefined
                                        }
                                      />
                                      {language ===
                                        process.env
                                          .REACT_APP_DEFAULT_LANGUAGE && (
                                        <Button
                                          variant="text"
                                          sx={{
                                            position: "absolute",
                                            top: "-5px",
                                            right: "-5px",
                                            color: "red",
                                            zIndex: 99,
                                            background: "white",
                                            p: 1,
                                            minWidth: "30px",
                                          }}
                                          onClick={() => {onChange(""); removeHappyClientsImage(index)}}
                                        >
                                          <Clear />
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                              <Typography
                                sx={{ fontSize: 12, p: "4px 14px 0" }}
                                className={
                                  errors.happyClients?.images?.[index]?.path !==
                                  undefined
                                    ? "text-color-danger"
                                    : ""
                                }
                              >
                                {errors.happyClients?.images?.[index]?.path !==
                                undefined
                                  ? validationMessage.required
                                  : ""}
                              </Typography>
                            </Grid>
                          ))}
                         <Grid item xs={12}>
                    {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                        <Stack alignItems={"flex-end"}>
                          <Button
                            disabled={fieldsHappyClientsImage.length >= 2}
                            variant="contained"
                            onClick={() =>
                              appendHappyClientsImage({
                                path: "",
                                name: "",
                              })
                            }
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Stack>
                      )}
                  </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label={`${t("titleField")} (*)`}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`happyClients.title`)}
                          error={Boolean(errors.happyClients?.title)}
                          helperText={errors.happyClients?.title?.message}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          inputProps={{
                            style: {
                              resize: "block",
                              padding: "0 !important",
                              borderRadius: "0 !important",
                            },
                          }}
                          label={`${t("subtitleField")} (*)`}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register(`happyClients.subTitle`)}
                          error={Boolean(errors.happyClients?.subTitle)}
                          helperText={errors.happyClients?.subTitle?.message}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="text-18 fw-600 mb-10">
                          {`${t("contentField")} (*)`}
                        </Typography>
                        <Controller
                          control={control}
                          name={`happyClients.content`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <TextAreaComponent
                                height={300}
                                onChange={onChange}
                                value={value ? value : ""}
                                isCreate={true}
                                isEdit={true}
                                error={
                                  errors?.happyClients?.content !== undefined
                                }
                              />
                            );
                          }}
                        />
                        <Typography
                          sx={{ fontSize: 12, p: "4px 14px 0" }}
                          className={
                            errors?.happyClients?.content
                              ? "text-color-danger"
                              : ""
                          }
                        >
                          {errors?.happyClients?.content
                            ? validationMessage.required
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
        
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6" fontWeight={500}>
                  Submit Form
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item md={8}>
                    <TextField
                      fullWidth
                      label="Title"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register(`submitForm.title`)}
                      error={Boolean(errors.submitForm?.title)}
                      helperText={errors.submitForm?.title?.message}
                    />

                    <TextField
                      fullWidth
                      label="Sub Title"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register(`submitForm.subTitle`)}
                      error={Boolean(errors.submitForm?.subTitle)}
                      helperText={errors.submitForm?.subTitle?.message}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <Controller
                      control={control}
                      name={`submitForm.image`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="about-us"
                              type="image"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={""}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              isShowRequire={
                                errors.submitForm?.image !== undefined
                              }
                            />
                            {language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Button
                                variant="text"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => onChange("")}
                              >
                                <Clear />
                              </Button>
                            )}
                          </Box>
                        );
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 12, p: "4px 14px 0" }}
                      className={
                        errors?.submitForm?.image !== undefined
                          ? "text-color-danger"
                          : ""
                      }
                    >
                      {errors?.submitForm?.image !== undefined
                        ? validationMessage.required
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
            <Button disabled={loadingBtn} type="submit" variant="contained">
              {t("saveButton")}
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};

export default AboutUs;
