import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";
import { Clear, Delete } from "@mui/icons-material";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import { ItemHero } from "../../../../../interfaces/homePage";
import { WhyChooseUsAccreditationsCompanyForm } from "../../../../../interfaces/navigationMenu";
import ContentComponent from "../../../../../components/Editor/ContentComponent";
import TextAreaComponent from "../../../../../components/TextArea/TextAreaComponent";
const defaultData = {
  title: "",
  subtitle: "",
  coverImage: "",
  dataAccreditations: [
    {
      key: `NAVIGATION_WHY_CHOOSE_US_ACCREDITATIONS_COMPANY_${makeid(8)}`,
      title: "",
      content: "",
      icon: "",
    },
  ],
};
const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  subtitle: yup.string().required(validationMessage.required),
  coverImage: yup.string().required(validationMessage.required),
  dataAccreditations: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        content: yup.string().required(validationMessage.required),
        icon: yup
          .string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
      })
    )
    .required(validationMessage.required),
});
const AccreditationsCompany = () => {
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [dataAccreditationsCompany, setDataAccreditationsCompany] =
    useState<ItemHero | null>(null);
  const [dataAccreditationsCompanySaved, setDataAccreditationsCompanySaved] =
    useState<ItemHero | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyReset, setKeyReset] = useState("");
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsAccreditationsCompanyForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dataAccreditations",
  });
  const submit: SubmitHandler<WhyChooseUsAccreditationsCompanyForm> = async (
    data
  ) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "accreditationsCompany",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataAccreditationsCompany) {
      const res = await homePageService.update(
        dataAccreditationsCompany.id,
        payload
      );
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Accreditations Company has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Accreditations Company has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<WhyChooseUsAccreditationsCompanyForm> = (
    errors
  ) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "accreditationsCompany"
      );
      if (data) {
        setDataAccreditationsCompany(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataAccreditationsCompanySaved(data);
        }
        const initData: WhyChooseUsAccreditationsCompanyForm = JSON.parse(
          data.contents
        );
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: WhyChooseUsAccreditationsCompanyForm = JSON.parse(
            dataAccreditationsCompanySaved?.contents ?? "{}"
          );
          
          console.log(dataSaved);
          
          if (Object.keys(dataSaved ?? {}).length > 0) {

            console.log("vao");
            
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              subtitle: initData.subtitle ?? dataSaved.subtitle,
              coverImage: dataSaved.coverImage,
              dataAccreditations: dataSaved.dataAccreditations.map((item) => ({
                key: item.key,
                title:
                  initData.dataAccreditations.find((el) => el.key === item.key)
                    ?.title ?? item.title,
                content:
                  initData.dataAccreditations.find((el) => el.key === item.key)
                    ?.content ?? item.content,
                icon: item.icon,
              })),
            };            
            reset(dataForm);
          }
          else {
            reset(initData);
          }
        }
        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataAccreditationsCompanySaved) {
        const dataSaved = JSON.parse(
          dataAccreditationsCompanySaved?.contents ?? "{}"
        );
        setDataAccreditationsCompany(null);
        reset(dataSaved);
        setKeyReset(makeid(5));
      } else {
        setDataAccreditationsCompany(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);  
  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit, error)}>
          <Paper sx={{ p: 3, mb: 2 }}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography
                  className="text-14 fw-600 mb-10"
                  color={watch("title")?.length > 120 ? "error" : "green"}
                >
                  {t("checkLength")}: {watch("title")?.length}/120
                </Typography>

                <TextField
                  size="small"
                  fullWidth
                  {...register(`title`)}
                  label={`${t("titleField")} (*)`}
                  InputLabelProps={{ shrink: true }}
                  error={errors?.title !== undefined}
                  helperText={
                    errors?.title !== undefined
                      ? validationMessage.required
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  className="text-14 fw-600 mb-10"
                  color={watch("subtitle")?.length > 200 ? "error" : "green"}
                >
                  {t("checkLength")}: {watch("subtitle")?.length}/200
                </Typography>

                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  inputProps={{
                    style: {
                      resize: "block",
                      padding: "0 !important",
                      borderRadius: "0 !important",
                    },
                  }}
                  {...register(`subtitle`)}
                  label={`${t("subtitleField")} (*)`}
                  InputLabelProps={{ shrink: true }}
                  error={errors?.subtitle !== undefined}
                  helperText={
                    errors?.subtitle !== undefined
                      ? validationMessage.required
                      : ""
                  }
                />
              </Grid>
              <Grid item xs={12} md={4}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name={`coverImage`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="master-setup"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={""}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              type="image"
                              imageViewHeight="800px"
                              isShowRequire={
                                errors.coverImage !==
                                undefined
                              }
                            />
                            {value &&
                              language ===
                                process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                <Button
                                  variant="text"
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    color: "red",
                                    zIndex: 99,
                                    background: "white",
                                    p: 1,
                                    minWidth: "30px",
                                  }}
                                  onClick={() => onChange("")}
                                >
                                  <Clear />
                                </Button>
                              )}
                          </Box>
                        );
                      }}
                    />

                    <Typography className="text-14 mt-5">
                      {t("fileUpload_size")}: 600 X 800 {t("fileUpload_unit")}
                    </Typography>
                  </Grid>
            </Grid>
          </Paper>

          <>
            <Typography className="text-24 fw-600">
              {t("dataLimit_length")}: 6{" "}
            </Typography>

            {fields.map((item, index) => (
              <Paper sx={{ p: 3, mb: 2 }} key={index}>
                {index &&
                language === process.env.REACT_APP_DEFAULT_LANGUAGE ? (
                  <Stack alignItems={"flex-end"}>
                    <Delete color="error" onClick={() => remove(index)} />
                  </Stack>
                ) : null}
                <Grid container spacing={2} p={2}>
                  
                  <Grid item xs={12} md={3}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name={`dataAccreditations.${index}.icon`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="master-setup"
                              documents={value ? value : ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={""}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              type="image"
                              imageViewHeight="48px"
                              isShowRequire={
                                errors.dataAccreditations?.[index]?.icon !==
                                undefined
                              }
                            />
                            {value &&
                              language ===
                                process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                <Button
                                  variant="text"
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    color: "red",
                                    zIndex: 99,
                                    background: "white",
                                    p: 1,
                                    minWidth: "30px",
                                  }}
                                  onClick={() => onChange("")}
                                >
                                  <Clear />
                                </Button>
                              )}
                          </Box>
                        );
                      }}
                    />

                    <Typography className="text-14 mt-5">
                      {t("fileUpload_size")}: 42 X 48 {t("fileUpload_unit")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                    <Typography
                      className="text-14 fw-600 mb-10"
                      color={
                        watch(`dataAccreditations.${index}.title`)?.length > 35
                          ? "error"
                          : "green"
                      }
                    >
                      {t("checkLength")}:{" "}
                      {watch(`dataAccreditations.${index}.title`)?.length}/35
                    </Typography>

                    <TextField
                      size="small"
                      fullWidth
                      {...register(`dataAccreditations.${index}.title`)}
                      label={`${t("titleField")} (*)`}
                      InputLabelProps={{ shrink: true }}
                      error={
                        errors?.dataAccreditations?.[index]?.title !== undefined
                      }
                      helperText={
                        errors?.dataAccreditations?.[index]?.title !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="text-18 fw-600 mb-10">
                      {`${t("contentField")} (*)`}
                    </Typography>
                    <Controller
                      key={keyReset}
                      control={control}
                      name={`dataAccreditations.${index}.content`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <TextAreaComponent
                            height={400}
                            onChange={onChange}
                            value={value ? value : ""}
                            isCreate={true}
                            isEdit={true}
                            error={errors?.dataAccreditations?.[index]?.content !== undefined}
                          />
                        );
                      }}
                    />
                     <Typography
                  sx={{ fontSize: 12, p: "4px 14px 0" }}
                  className={
                    errors?.dataAccreditations?.[index]?.content ? "text-color-danger" : ""
                  }
                >
                  {errors?.dataAccreditations?.[index]?.content
                    ? validationMessage.required
                    : ""}
                </Typography>
                  </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {index + 1 === fields.length &&
                      language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                        <Stack alignItems={"flex-end"}>
                          <Button
                            disabled={fields.length >= 6}
                            variant="contained"
                            onClick={() =>
                              append({
                                title: "",
                                content: "",
                                icon: "",
                                key: `NAVIGATION_WHY_CHOOSE_US_ACCREDITATIONS_COMPANY_${makeid(
                                  8
                                )}`,
                              })
                            }
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Stack>
                      )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </>

          <Stack className="mt-30" alignItems={"flex-end"}>
            <Button disabled={loadingBtn} variant="contained" type="submit">
              {t("saveButton")}
            </Button>
          </Stack>
        </form>
      )}
    </>
  );
};

export default AccreditationsCompany;
