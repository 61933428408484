import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import validationMessage from "../../../constants/validationMessage";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { makeid } from "../../../utils";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { PassengerLoginForm, ItemHero } from "../../../interfaces/homePage";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import homePageService from "../../../services/homePageService";
import Swal from "sweetalert2";
import { Add, Clear } from "@mui/icons-material";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import ContentComponent from "../../../components/Editor/ContentComponent";

const defaultData = {
  helpDeskIcon: "",
  // AppIcon: "",
  googlePlayLink: "",
  appStoreLink: "",
  loginInformation: "",
  images: [
    {
      name: "",
      path: "",
    },
  ],
};
const validationSchema = yup.object().shape({
  images: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(validationMessage.required),
        path: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
  helpDeskIcon: yup.string().required(validationMessage.required),
  loginInformation: yup.string().required(validationMessage.required),
  // AppIcon: yup.string().required(validationMessage.required),
  googlePlayLink: yup.string().required(validationMessage.required),
  appStoreLink: yup.string().required(validationMessage.required),
});
const PassengerLoginTab = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataPassengerLogin, setDataPassengerLogin] = useState<ItemHero | null>(
    null
  );
  const [dataPassengerLoginSaved, setDataPassengerLoginSaved] =
    useState<ItemHero | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<PassengerLoginForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "images",
  });

  const submit: SubmitHandler<PassengerLoginForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "passenger-login",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataPassengerLogin) {
      const res = await homePageService.update(dataPassengerLogin.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Passenger Login has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Passenger Login has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<PassengerLoginForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "passenger-login"
      );
      if (data) {
        setDataPassengerLogin(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataPassengerLoginSaved(data);
        }
        const initData: PassengerLoginForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: PassengerLoginForm = JSON.parse(
            dataPassengerLoginSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              helpDeskIcon: initData.helpDeskIcon ?? dataSaved.helpDeskIcon,
              // AppIcon: initData.AppIcon ?? dataSaved.AppIcon,
              googlePlayLink:
                initData.googlePlayLink ?? dataSaved.googlePlayLink,
              appStoreLink: initData.appStoreLink ?? dataSaved.appStoreLink,
              loginInformation:
                initData.loginInformation ?? dataSaved.loginInformation,
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataPassengerLoginSaved) {
        const dataSaved = JSON.parse(dataPassengerLoginSaved?.contents ?? "{}");

        setDataPassengerLogin(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataPassengerLogin(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <form onSubmit={handleSubmit(submit, error)}>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Paper sx={{ p: 3 }}>
            <Stack spacing={5}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography className="text-18 fw-600">
                        {t("loginPage_passengerLogin_loginInformation")}
                      </Typography>
                      <Controller
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <ContentComponent
                              data={value ?? ""}
                              onChange={onChange}
                            />
                          );
                        }}
                        name={`loginInformation`}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box>
                        <Controller
                          key={keyReset}
                          control={control}
                          name={`helpDeskIcon`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="agent-login"
                                  documents={value ?? ""}
                                  onChange={(data: any) => {
                                    onChange(data);
                                  }}
                                  widthImg={48}
                                  heightImg={42}
                                  idBtnUpload="btn-upload"
                                  isUploadIcon={true}
                                  imageViewHeight="42px"
                                  isShowRequire={
                                    errors?.helpDeskIcon !== undefined
                                  }
                                  type="image"
                                />
                                {value &&
                                  language ===
                                    process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        color: "red",
                                        zIndex: 99,
                                        background: "white",
                                        p: 1,
                                        minWidth: "30px",
                                      }}
                                      onClick={() => onChange("")}
                                    >
                                      <Clear />
                                    </Button>
                                  )}
                              </Box>
                            );
                          }}
                        />

                        <Typography className="text-14 mt-5">
                          {t("fileUpload_size")}: 48 X 42 {t("fileUpload_unit")}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography className="text-14 fw-600">
                        {t("loginPage_passengerLogin_helpDesk")}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={12} md={4}>
                      <Box>
                        <Controller
                          key={keyReset}
                          control={control}
                          name={`AppIcon`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="agent-login"
                                  documents={value ?? ""}
                                  onChange={(data: any) => {
                                    onChange(data);
                                  }}
                                  widthImg={48}
                                  heightImg={42}
                                  idBtnUpload="btn-upload"
                                  isUploadIcon={true}
                                  imageViewHeight="42px"
                                  isShowRequire={errors?.AppIcon !== undefined}
                                  type="image"
                                />
                                {value &&
                                  language ===
                                    process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        color: "red",
                                        zIndex: 99,
                                        background: "white",
                                        p: 1,
                                        minWidth: "30px",
                                      }}
                                      onClick={() => onChange("")}
                                    >
                                      <Clear />
                                    </Button>
                                  )}
                              </Box>
                            );
                          }}
                        />

                        <Typography className="text-14 mt-5">
                          {t("fileUpload_size")}: 48 X 42 {t("fileUpload_unit")}
                        </Typography>
                      </Box>
                    </Grid> */}
                    {/* <Grid item xs={12} md={8}>
                      <Typography className="text-14 fw-600">
                        {t("loginPage_passengerLogin_downloadTweetApp")}
                      </Typography>
                    </Grid> */}
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        label={t(
                          "loginPage_passengerLogin_tweetApp_googlePlayLink"
                        )}
                        {...register("googlePlayLink")}
                        error={errors?.googlePlayLink !== undefined}
                        helperText={
                          errors?.googlePlayLink !== undefined
                            ? validationMessage.required
                            : ""
                        }
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        size="small"
                        label={t(
                          "loginPage_passengerLogin_TweetApp_appStoreLink"
                        )}
                        {...register("appStoreLink")}
                        error={errors?.appStoreLink !== undefined}
                        helperText={
                          errors?.appStoreLink !== undefined
                            ? validationMessage.required
                            : ""
                        }
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Grid container spacing={2}>
                    {fields.map((field, index) => (
                      <Grid item xs={12} md={6} key={field.id}>
                        <Controller
                          key={field.id}
                          control={control}
                          name={`images.${index}.path`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="agent-login"
                                  documents={value ?? ""}
                                  onChange={(data: any) => {
                                    onChange(data);
                                    setValue(`images.${index}.name`, data);
                                  }}
                                  widthImg={780}
                                  heightImg={692}
                                  idBtnUpload="btn-upload"
                                  isUploadIcon={true}
                                  imageViewHeight="492px"
                                  isShowRequire={
                                    errors?.images?.[index]?.path !== undefined
                                  }
                                  type="image"
                                />
                                {value &&
                                  language ===
                                    process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                    <Button
                                      variant="text"
                                      sx={{
                                        position: "absolute",
                                        top: "-5px",
                                        right: "-5px",
                                        color: "red",
                                        zIndex: 99,
                                        background: "white",
                                        p: 1,
                                        minWidth: "30px",
                                      }}
                                      onClick={() => onChange("")}
                                    >
                                      <Clear />
                                    </Button>
                                  )}
                              </Box>
                            );
                          }}
                        />
                      </Grid>
                    ))}
                    {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                <Grid item md={3}>
                  <Box className="d-inline-block">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        append({
                          name: "",
                          path: ""
                        });
                      }}
                      endIcon={<Add />}
                    >
                      {t("addMoreButton")}
                    </Button>
                  </Box>
                </Grid>
              )}
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Paper>
          <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
            <Button disabled={loadingBtn} type="submit" variant="contained">
              {t("saveButton")}
            </Button>
          </Box>
        </>
      )}
    </form>
  );
};

export default PassengerLoginTab;
