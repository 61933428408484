import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";

const locationService = {
    getListCountries: async () => {
        try{
            return await apiInstance.get(endPointAPI.LOCATION.LIST_COUNTRIES);
        } catch (error) {
            return null
        }
    },

    getDestinationsByCountries: async (payload: {countriesId: number[]}) => {
        try {
            return await apiInstance.post(endPointAPI.LOCATION.LIST_LOCATIONS, payload);
        } catch (error) {
            return null
        }    
    }
}

export default locationService;
