import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import { FindLocalAgentDealsForm } from "../../../../../interfaces/navigationMenu";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { ItemHero } from "../../../../../interfaces/homePage";
import { makeid } from "../../../../../utils";
import { Clear, Delete } from "@mui/icons-material";

const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  exclusives: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        image: yup.string().required(validationMessage.required),
        urlButton: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});
const DealsTab = () => {
  const defaultData = {
    title: "",
    exclusives: [
      {
        key: `NAVIGATION_FIND_LOCAL_AGENT_DEALS_${makeid(8)}`,
        title: "",
        image: "",
        urlButton: "",
      },
    ],
  };
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataDeals, setDataDeals] = useState<ItemHero | null>(null);
  const [dataDealsSaved, setDataDealsSaved] = useState<ItemHero | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<FindLocalAgentDealsForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "exclusives",
  });

  const submit: SubmitHandler<FindLocalAgentDealsForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "findlocalagent",
      section: "deals",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true);
    if (dataDeals) {
      const res = await homePageService.update(dataDeals.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Deals has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Deals has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }

    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<FindLocalAgentDealsForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("findlocalagent");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "deals"
      );
      if (data) {
        setDataDeals(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {          
          setDataDealsSaved(data);
        }
        const initData: FindLocalAgentDealsForm = JSON.parse(data.contents);

          if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
            reset(initData);
        } else {
          const dataSaved: FindLocalAgentDealsForm = JSON.parse(
            dataDealsSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
  exclusives: dataSaved.exclusives.map(item => ({
      key: item.key,
      title: initData.exclusives.find(el => el.key === item.key)?.title ?? item.title,
      image: item.image,
      urlButton: initData.exclusives.find(el => el.key === item.key)?.urlButton ?? item.urlButton,
    })),
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        setKeyReset(makeid(5));
      } else if (dataDealsSaved) {
        const dataSaved = JSON.parse(dataDealsSaved?.contents ?? "{}");

        setDataDeals(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataDeals(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ p: 3, mb: 2 }}>
        {
          loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box> :  <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("title")?.length > 60 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("title")?.length}/60
            </Typography>
            <TextField
              size="small"
              fullWidth
              {...register(`title`)}
              label={t("homepage_navigationMenu_findLocalAgent_deals_title")}
              InputLabelProps={{ shrink: true }}
              error={errors?.title !== undefined}
              helperText={
                errors?.title !== undefined ? validationMessage.required : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className="text-24 fw-600">
              {" "}
              {t("dataLimit_length")} : 8{" "}
            </Typography>
            <Grid container spacing={2}>
              {fields.map((field, index) => (
                <Grid item xs={12} key={index}>
                  <Paper sx={{ p: 2 }}>
                    {(index && language === process.env.REACT_APP_DEFAULT_LANGUAGE) ? (
                      <Stack alignItems={"flex-end"}>
                        <IconButton onClick={() => remove(index)}>
                          <Delete color="error" />
                        </IconButton>
                      </Stack>
                    ) : null}

                    <Grid container spacing={2}>
                      
                      <Grid item xs={12} md={3}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name={`exclusives.${index}.image`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="master-setup"
                                  documents={value ?? ""}
                                  onChange={(data: any) => onChange(data)}
                                  widthImg={""}
                                  heightImg={""}
                                  idBtnUpload="btn-upload"
                                  type="image"
                                  isShowRequire={errors?.exclusives?.[index]?.image !== undefined}
                                  imageViewHeight="450px"
                                />
                                {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                              </Box>
                            );
                          }}
                        />

                        <Typography className="text-14 mt-5">
                          {t("fileUpload_size")}: 325 X 450{" "}
                          {t("fileUpload_unit")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                        <Typography
                          className="text-14 fw-600 mb-10"
                          color={
                            watch(`exclusives.${index}.title`)?.length > 35
                              ? "error"
                              : "green"
                          }
                        >
                          {t("checkLength")}:{" "}
                          {watch(`exclusives.${index}.title`)?.length}/35
                        </Typography>
                        <TextField
                          size="small"
                          fullWidth
                          {...register(`exclusives.${index}.title`)}
                          label={t("dataLimit_title")}
                          InputLabelProps={{ shrink: true }}
                          error={errors?.exclusives?.[index]?.title !== undefined}
                          helperText={
                            errors?.exclusives?.[index]?.title !== undefined
                              ? validationMessage.required
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              size="small"
                              fullWidth
                              {...register(`exclusives.${index}.urlButton`)}
                              label={t("dataLimit_buttonURL")}
                              InputLabelProps={{ shrink: true }}
                              placeholder="https://"
                              error={errors?.exclusives?.[index]?.urlButton !== undefined}
                              helperText={
                                errors?.exclusives?.[index]?.urlButton !== undefined
                                  ? validationMessage.required
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Box>
                              <Button variant="contained" className="h-45">
                                {t("dataLimit_buttonContent")}
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                        </Grid>
                      </Grid>
                      
                      {(index + 1 === fields.length && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && <Grid item xs={12}>
                        <Stack alignItems={"flex-end"}>
                          <Button
                          disabled={fields.length >= 8}
                            variant="outlined"
                            onClick={() => {
                              append({
                                key: `NAVIGATION_FIND_LOCAL_AGENT_DEALS_${makeid(8)}`,
                                title: "",
                                image: "",
                                urlButton: "",
                              });
                            }}
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Stack>
                      </Grid>}
                    </Grid>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        }
       
      </Paper>

      <Stack className="mt-30" alignItems={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
  );
};

export default DealsTab;
