import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { PayloadSEO } from "../interfaces/masterSeo";
import { MasterSetupRequest } from "../interfaces/masterSetup";

const masterSetupService = {

    getListData: async () => {
        try {
            return await apiInstance.get(`${endPointAPI.MASTER_SETUP.GET_LIST}`)
        } catch (error) {
            return null
        }
    },

    store: async (payload: MasterSetupRequest) => {
        try {
            return await apiInstance.post(endPointAPI.MASTER_SETUP.STORE, payload)
        } catch (error) {
            return null
        }
    },


    update: async (id: number, payload: MasterSetupRequest) => {
        try {
            return await apiInstance.put(`${endPointAPI.MASTER_SETUP.UPDATE}/${id}`, payload)
        } catch (error) {
            return null
        }
    },
}
export default masterSetupService;
