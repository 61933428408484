import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HandleHeroSection, ItemHero } from "../../../interfaces/homePage";
import mockForm from "../../../data/mockForm";
import { useEffect, useState } from "react";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import { Clear, Delete } from "@mui/icons-material";
import homePageService from "../../../services/homePageService";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { useTranslation } from "react-i18next";
import { makeid } from "../../../utils";

const validationSchema = yup.object().shape({
  images: yup
    .array(
      yup.object().shape({
        url: yup.string().required("Field Required"),
      })
    )
    .required("Field Required"),
  slogan: yup.string().required("Field Required"),
  highlight: yup.object().shape({
    title: yup.string().required("Field Required"),
    countries: yup
      .array(yup.string().required("Field Required"))
      .required("Field Required"),
  }),
  promotion: yup.array(
    yup.object().shape({
      country: yup.string().required("Field Required"),
      promotion: yup.string().required("Field Required"),
      key: yup.string().required("Field Required"),
    })
  ),
});

const HeroSection = () => {
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [dataHero, setDataHero] = useState<ItemHero | null>(null);
  const [dataHeroSaved, setDataHeroSaved] = useState<ItemHero | null>(null);
  const { language } = useGlobalContext();
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");

  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<HandleHeroSection>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const [openModal, setOpenModal] = useState(false);
  const submit: SubmitHandler<HandleHeroSection> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "herosection",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataHero) {
      const res = await homePageService.update(dataHero?.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Hero Section has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Hero Section has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }
    }
    setLoadingBtn(false)
  };
  // console.log(dataHero);

  const error: SubmitErrorHandler<HandleHeroSection> = (errors) => {
    console.log(errors);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "images",
  });

  const getData = async () => {
    setLoading(true);
    const res = await homePageService.getList("homepage");
    if (res?.status === 200) {
      const data = res?.data.find(
        (item: ItemHero) =>
          item.section === "herosection" && item.language === language
      );

      if (data) {
        setDataHero(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataHeroSaved(data);
        }
        const initData: HandleHeroSection = JSON.parse(data.contents ?? "{}");

        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: HandleHeroSection = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              slogan: initData.slogan ?? dataSaved.slogan,
              highlight: {
                title: initData.highlight.title ?? dataSaved.highlight.title,
                countries: dataSaved.highlight.countries,
                promotion: dataSaved.highlight.promotion?.map((item) => ({
                  key: item.key,
                  country:
                    initData.highlight.promotion?.find(
                      (el) => el.key === item.key
                    )?.country ?? item.country,
                  promotion:
                    initData.highlight.promotion?.find(
                      (el) => el.key === item.key
                    )?.promotion ?? item.promotion,
                })),
              },
            };
            console.log(dataForm, initData, "if");

            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: HandleHeroSection = JSON.parse(
            dataHeroSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              slogan: initData.slogan ?? dataSaved.slogan,
              highlight: {
                title: initData.highlight.title ?? dataSaved.highlight.title,
                countries: dataSaved.highlight.countries,
                promotion: dataSaved.highlight.promotion?.map((item) => ({
                  key: item.key,
                  country:
                    initData.highlight.promotion?.find(
                      (el) => el.key === item.key
                    )?.country ?? item.country,
                  promotion:
                    initData.highlight.promotion?.find(
                      (el) => el.key === item.key
                    )?.promotion ?? item.promotion,
                })),
              },
            };

            console.log(dataForm, initData, "else");

            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataHeroSaved) {
        const dataSaved = JSON.parse(dataHeroSaved?.contents ?? "{}");

        setDataHero(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataHero(null);
        reset({
          images: [],
          slogan: "",
          highlight: {
            title: "",
            countries: [],
            promotion: [],
          },
        });
        setKeyReset(makeid(5));
      }

      setLoading(false);
    }
    setLoading(false)
  };

  useEffect(() => {
    getData();
  }, [language]);

  console.log(watch("highlight"));

  return (
    <Box>
      {
         loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
         <CircularProgress />
       </Box> :   <form onSubmit={handleSubmit(submit, error)}>
        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="body1" fontWeight={500}>
            {t("homepage_heroSection_heroBannerPhoto_titleHeader")}
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
            <Box mb={2}>
              <Button
                variant="contained"
                startIcon={<img src="/icons/AddImage.svg" alt="add image" />}
                onClick={() => append({ url: "" })}
              >
                {t("homepage_heroSection_heroBannerPhoto_addImage")}
              </Button>
            </Box>
          )}

          <Grid container spacing={2}>
            {fields.map((_, index) => {
              return (
                <Grid item md={3} key={index}>
                  <Controller
                    key={keyReset}
                    control={control}
                    name={`images.${index}.url`}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <Box sx={{ position: "relative" }}>
                          <UploaderCustom
                            page="master-setup"
                            type="image"
                            documents={value ?? ""}
                            onChange={(data: any) => onChange(data)}
                            widthImg={1440}
                            heightImg={""}
                            idBtnUpload="btn-upload"
                            imageViewHeight="220px"
                          />
                         {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <>
                          <Button
                            variant="text"
                            sx={{
                              position: "absolute",
                              top: "-5px",
                              right: "-5px",
                              color: "red",
                              zIndex: 99,
                              background: "white",
                              p: 1,
                              minWidth: "30px",
                            }}
                            onClick={() => onChange("")}
                          >
                            <Clear />
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              remove(index);
                              setKeyReset(makeid(5));
                            }}
                            color="error"
                            size="small"
                            sx={{ minWidth: "30px" }}
                          >
                            <Delete />
                          </Button>
                          </>}
                          
                        </Box>
                      );
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>

        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="body1" fontWeight={500}>
            {t("homepage_heroSection_heroBannerSlogan_titleHeader")}
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Typography
            variant="body2"
            sx={{
              mb: 2,
              color:
                watch("slogan") && watch("slogan").length > 90
                  ? "red"
                  : "black",
            }}
          >
            {t("masterSeo_metaDescription_checkLength")} :{" "}
            {watch("slogan") ? watch("slogan").length : 0}/90
          </Typography>
          <TextField
            {...register("slogan")}
            label={t("homepage_heroSection_heroBannerSlogan_sloganPhrase")}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            error={errors.slogan !== undefined}
            helperText={
              errors.slogan !== undefined ? errors.slogan.message : ""
            }
          />
        </Paper>

        <Paper sx={{ p: 2, mb: 3 }}>
          <Typography variant="body1" fontWeight={500}>
            {t(
              "homepage_heroSection_highlightFeaturesBelowSearchBox_titleHeader"
            )}
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Grid container spacing={4}>
            <Grid item md={6}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch("highlight.title") &&
                    watch("highlight.title").length > 25
                      ? "red"
                      : "black",
                }}
              >
                {t("masterSeo_metaDescription_checkLength")} :{" "}
                {watch("highlight.title") ? watch("highlight.title").length : 0}
                /25
              </Typography>
              <TextField
                {...register("highlight.title")}
                label={t(
                  "homepage_heroSection_highlightFeaturesBelowSearchBox_title"
                )}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors?.highlight?.title !== undefined}
                helperText={
                  errors?.highlight?.title !== undefined
                    ? errors?.highlight?.title.message
                    : ""
                }
              />
            </Grid>
            <Grid item md={6}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch("highlight.countries") &&
                    watch("highlight.countries").length > 4
                      ? "red"
                      : "black",
                }}
              >
                {t(
                  "homepage_heroSection_highlightFeaturesBelowSearchBox_countries_dataLimit"
                )}
                :{" "}
                {watch("highlight.countries")
                  ? watch("highlight.countries").length
                  : 0}
                /4{" "}
                {t(
                  "homepage_heroSection_highlightFeaturesBelowSearchBox_countries"
                )}
              </Typography>
              <Controller
                control={control}
                name="highlight.countries"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Autocomplete
                    disabled={language !== process.env.REACT_APP_DEFAULT_LANGUAGE}
                      disablePortal
                      disableClearable
                      multiple
                      id="countries"
                      getOptionLabel={(option) => option.label}
                      getOptionDisabled={(options) => (value?.length >= 4 ? true : false)}
                      onChange={(e, value) => {
                        if (value) {
                          onChange(value.map((item) => item.value));
                        }
                      }}
                      value={mockForm.countryOptions.filter((item) => {
                        return value?.includes(item?.value);
                      })}
                      size="small"
                      options={mockForm.countryOptions}
                      renderInput={(params) => (
                        <TextField
                          sx={{ background: "#fff" }}
                          {...params}
                          label={t(
                            "homepage_heroSection_highlightFeaturesBelowSearchBox_countries"
                          )}
                          defaultValue={""}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={errors?.highlight?.countries !== undefined}
                          helperText={
                            errors?.highlight?.countries !== undefined
                              ? errors?.highlight?.countries.message
                              : ""
                          }
                        />
                      )}
                    />
                  );
                }}
              />
            </Grid>
            {watch("highlight.countries") && (
              <Grid item md={6}>
                <Grid container spacing={5}>
                  {watch("highlight.promotion") && (
                    <Grid item md={5}>
                      <Typography variant="body1" fontWeight={500}>
                        {t(
                          "homepage_heroSection_highlightFeaturesBelowSearchBox_countries_dataLimit"
                        )}
                      </Typography>
                      {watch("highlight.promotion")?.map((item: any, index) => (
                        <Box
                          key={index}
                          display={"flex"}
                          justifyContent={"space-between"}
                          sx={{ alignItems: "center" }}
                        >
                          <Typography variant="body2">{item?.country}</Typography>
                          <Typography variant="body2">
                            {item?.promotion}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  )}

                  <Grid item md={7}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => setOpenModal(true)}
                    >
                      {t(
                        "homepage_heroSection_highlightFeaturesBelowSearchBox_promotionSetupButton"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>

        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"xs"}
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {t(
              "homepage_heroSection_highlightFeaturesBelowSearchBox_promotionSetupButton"
            )}
          </DialogTitle>
          <DialogContent>
            <Box mt={4}>
              {watch("highlight.countries")?.map((item: string, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item md={7}>
                    <Typography variant="body1" fontWeight={500}>
                      {item}{" "}
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Controller
                      control={control}
                      name={`highlight.promotion.${index}`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        console.log(getValues(`highlight.promotion`) ,  getValues(`highlight.promotion`)?.find(el => el?.country === item)?.key);
                        
                        return (
                          <TextField
                            value={value?.promotion ?? ""}
                            onChange={(e) =>
                              onChange({
                                country: item,
                                promotion: e.target.value,
                                key: (getValues(`highlight.promotion`) && getValues(`highlight.promotion`)?.find(el => el?.country === item)?.key) ? getValues(`highlight.promotion`)?.find(el => el?.country === item)?.key : `HERO_SECTION_${makeid(8)}`,
                              })
                            }
                            label={t(
                              "homepage_heroSection_highlightFeaturesBelowSearchBox_promotionSetupTable_promotionTextHeader"
                            )}
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              errors?.highlight?.promotion?.[index]?.country !==
                              undefined
                            }
                            helperText={
                              errors?.highlight?.promotion?.[index]?.country !==
                              undefined
                                ? "Require"
                                : ""
                            }
                          />
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setOpenModal(false)}>
              {t("saveButton")}
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 5 }}>
          <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 5 }}>
            {t("saveButton")}
          </Button>
        </Box>
      </form>
      }
    
    </Box>
  );
};

export default HeroSection;
