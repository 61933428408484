import { Box, Tab, Typography } from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import AgentLoginTab from "./tabs-content/AgentLogin";
import PassengerLoginTab from "./tabs-content/PassengerLogin";

export default function LoginPage() {
  const [value, setValue] = React.useState("agent-login");
const { t } = useTranslation();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ bgcolor: "background.paper", borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >          
            <Tab label={
                <Typography className="text-14 mb-10">
                  {
                     t( "loginPage_agentLoginTab")
                    }
                 
                </Typography>
              } value={"agent-login"} />
            <Tab label={
                <Typography className="text-14 mb-10">
                  {
                      t("loginPage_passengerLoginTab")
                    }
                </Typography>
              } value={"passenger-login"} />
           
          </TabList>
        </Box>
        <TabPanel value="agent-login" sx={{ px: 0 }}>
          <AgentLoginTab />
        </TabPanel>
        <TabPanel value="passenger-login" sx={{ px: 0 }}>
          <PassengerLoginTab />
        </TabPanel>
        
      </TabContext>
    </Box>
  );
}
