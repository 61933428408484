import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { array, object, string } from "yup";
import validationMessage from "../../../../../constants/validationMessage";
import { FindLocalAgentSearchResultForm } from "../../../../../interfaces/navigationMenu";
import { ItemHero } from "../../../../../interfaces/homePage";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";
import data from "../../../../../mockdata/data";

const defaultData = {
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
  highlightFeatureTitle: "",
  highlightFeatureCountries: [],
  resultTitle: "",
};
const validationSchema = object().shape({
  metaTitle: string().required(validationMessage.required),
  metaDescription: string().required(validationMessage.required),
  metaKeyword: string().required(validationMessage.required),
  highlightFeatureTitle: string().required(validationMessage.required),
  highlightFeatureCountries: array().required(validationMessage.required),
  resultTitle: string().required(validationMessage.required),
});
const SearchResultTab = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataSearchResult, setDataSearchResult] = useState<ItemHero | null>(null);
  const [dataSearchResultSaved, setDataSearchResultSaved] = useState<ItemHero | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FindLocalAgentSearchResultForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<FindLocalAgentSearchResultForm> = async (
    data
  ) => {
    const payload = {
      language: language ?? "en",
      type: "findlocalagent",
      section: "searchresult",
      contents: JSON.stringify(data),
    };
    
    setLoadingBtn(true);
    if (dataSearchResult) {
      const res = await homePageService.update(dataSearchResult.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Search Result has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Search Result has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }

    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<FindLocalAgentSearchResultForm> = (
    errors
  ) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("findlocalagent");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) => item.language === language && item.section === "searchresult"
      );
      if (data) {
        setDataSearchResult(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataSearchResultSaved(data);
        }
        const initData = JSON.parse(data.contents ?? "{}");
        reset(initData);
        setKeyReset(makeid(5));
      } else if (dataSearchResultSaved) {
        const dataSaved = JSON.parse(dataSearchResultSaved.contents ?? "{}");
        setDataSearchResult(null);
        reset(dataSaved);
      } else {
        setDataSearchResult(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <>
    {
      loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : <form onSubmit={handleSubmit(submit, error)}>
      {" "}
      <Grid container spacing={2} mb={2}>
        <Grid item md={12}>
          <Paper>
          <Box p={3} py={4}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("metaTitle") && watch("metaTitle").length > 61
                        ? "red"
                        : "black",
                  }}
                >
                  {t("checkLength")} :{" "}
                  {watch("metaTitle") ? watch("metaTitle").length : 0}/61
                </Typography>
                <TextField
                  {...register("metaTitle")}
                  label={t(
                    "homepage_navigationMenu_findLocalAgent_searchResult_metaTitle"
                  )}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.metaTitle !== undefined}
                  helperText={
                    errors.metaTitle !== undefined
                      ? errors.metaTitle.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("metaDescription") &&
                      watch("metaDescription").length > 160
                        ? "red"
                        : "black",
                  }}
                >
                  {t("checkLength")} :{" "}
                  {watch("metaDescription")
                    ? watch("metaDescription").length
                    : 0}
                  /160
                </Typography>
                <TextField
                  {...register("metaDescription")}
                  label={t(
                    "homepage_navigationMenu_findLocalAgent_searchResult_metaDescription"
                  )}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.metaDescription !== undefined}
                  helperText={
                    errors.metaDescription !== undefined
                      ? errors.metaDescription.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <TextField
                  {...register("metaKeyword")}
                  label={t(
                    "homepage_navigationMenu_findLocalAgent_searchResult_metaKeywords"
                  )}
                  fullWidth
                  multiline
                  rows={4}
                  inputProps={{
                    style: {
                      resize: "block",
                      padding: "0 !important",
                      borderRadius: "0 !important",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.metaKeyword !== undefined}
                  helperText={
                    errors.metaKeyword !== undefined
                      ? errors.metaKeyword.message
                      : ""
                  }
                />
              </Grid>
            </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Box>
              <Typography className="text-16 fw-600 p-10">
                {t(
                  "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_titleHeader"
                )}
              </Typography>
              <Divider />
            </Box>
            <Box p={3} py={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography
                    className="text-14 fw-600 mb-10"
                    color={
                      watch("highlightFeatureTitle")?.length > 30
                        ? "error"
                        : "green"
                    }
                  >
                    {t("checkLength")}: {watch("highlightFeatureTitle")?.length}
                    /30
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    {...register(`highlightFeatureTitle`)}
                    label={t(
                      "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_title"
                    )}
                    InputLabelProps={{ shrink: true }}
                    error={errors?.highlightFeatureTitle !== undefined}
                    helperText={
                      errors?.highlightFeatureTitle !== undefined
                        ? validationMessage.required
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography className="text-14 mb-10">
                    {t(
                      "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries_dataLimit"
                    )}
                    : 6{" "}
                    {t(
                      "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries_dataLimit_unit"
                    )}
                  </Typography>

                  <Controller
                    key={keyReset}
                    control={control}
                    name={`highlightFeatureCountries`}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <>
                          <Autocomplete
                            multiple
                            disablePortal
                            disableClearable
                            id="highlightFeatureCountries"
                            options={data.listCountry}
                            getOptionLabel={(option) => option.label}
                            getOptionDisabled={(options) => (value?.length >= 6 ? true : false)}
                            value={
                              value
                                ? data.listCountry.filter((item) =>
                                    value.includes(item.label)
                                  )
                                : undefined
                            }
                            onChange={(e, value) => {
                              if (value) {
                                onChange(value.map((item) => item.label));
                              } else {
                                onChange([]);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                label={t(
                                  "homepage_navigationMenu_findLocalAgent_highlightFeaturesBelowSearchBox_countries"
                                )}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  errors?.highlightFeatureCountries !==
                                  undefined
                                }
                                helperText={
                                  errors?.highlightFeatureCountries !==
                                  undefined
                                    ? validationMessage.required
                                    : ""
                                }
                              />
                            )}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
          <Box p={3} py={4}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("resultTitle")?.length > 60 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("resultTitle")?.length}/60
            </Typography>
            <TextField
              size="small"
              fullWidth
              {...register(`resultTitle`)}
              label={t(
                "homepage_navigationMenu_findLocalAgent_searchResult_resultTitle"
              )}
              InputLabelProps={{ shrink: true }}
              error={errors?.resultTitle !== undefined}
              helperText={
                errors?.resultTitle !== undefined
                  ? validationMessage.required
                  : ""
              }
            />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
    }
    </>
    
  );
};

export default SearchResultTab;
