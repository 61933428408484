import React from "react";
import { Box, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Overview from "./tabs-content/Overview";
import { useTranslation } from "react-i18next";
import HeroTab from "./tabs-content/Hero";
import SearchAgentTab from "./tabs-content/SearchAgentTab";
import DealsTab from "./tabs-content/DealsTab";
import SearchResultTab from "./tabs-content/SearchResultTab";
import AgentPageTab from "./tabs-content/AgentPageTab";
const FindLocalAgentPage = () => {
  const [value, setValue] = React.useState("overview");
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_findLocalAgent_overviewTab")}
                </Typography>
              }
              value={"overview"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_findLocalAgent_heroTab")}
                </Typography>
              }
              value={"hero"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_findLocalAgent_searchAgentTab")}
                </Typography>
              }
              value={"search-agent"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_findLocalAgent_dealsTab")}
                </Typography>
              }
              value={"deals"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_findLocalAgent_searchResultTab")}
                </Typography>
              }
              value={"search-result"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  {t("homepage_navigationMenu_findLocalAgent_agentPageTab")}
                </Typography>
              }
              value={"agent-page"}
            />
          </TabList>
        </Box>
        <TabPanel value="overview" sx={{ px: 0 }}>
          <Overview />
        </TabPanel>
        <TabPanel value="hero" sx={{ px: 0 }}>
          <HeroTab />
        </TabPanel>
        <TabPanel value="search-agent" sx={{ px: 0 }}>
          <SearchAgentTab />
        </TabPanel>
        <TabPanel value="deals" sx={{ px: 0 }}>
          <DealsTab />
        </TabPanel>
        <TabPanel value="search-result" sx={{ px: 0 }}>
          <SearchResultTab />
        </TabPanel>
        <TabPanel value="agent-page" sx={{ px: 0 }}>
          <AgentPageTab />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default FindLocalAgentPage;
