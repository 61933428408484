import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import { userAuth } from "../interfaces/authentication";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Menu,
  MenuItem,
  Tooltip,
  styled,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useGlobalContext } from "../context/MyGlobalContext";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${300}px)`,
    marginLeft: `${300}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
interface Props {
  open: boolean;
  handleDrawerOpen: () => void;
}
const MainSiteHeader = ({ open, handleDrawerOpen }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const openMenu = Boolean(anchorEl);
  const { companyLogo, mode, setMode } = useGlobalContext();



  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);

  const logout = async () => {

    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    navigate("/sign-in");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: mode === "dark" ? "#536370" : "#fff",
          color: mode === "dark" ? "#fff" : "#6b7280",
          fontSize: 16,
          fontWeight: 500,
          boxShadow: "unset",
          borderBottom: "1px solid #0000001f",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          pl: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {!companyLogo ? (
            <span style={{ fontSize: "20px", display: "block" }}>
              Your Logo Here
            </span>
          ) : (
            <img
              src={companyLogo}
              alt=""
              style={{ maxWidth: "135px", maxHeight: "38px" }}
            />
          )}
        </Box>

        <Toolbar style={{ display: "flex", justifyContent: "flex-end" }}>
          <Tooltip title={mode === "dark" ? "Light Mode" : "Dark Mode"}>
            <IconButton
              onClick={() => {
                if (mode === "dark") {
                  setMode("light");
                  localStorage.setItem("mode", "light");
                } else {
                  setMode("dark");
                  localStorage.setItem("mode", "dark");
                }
              }}
              color="inherit"
            >
              {mode === "dark" ? (
                <img src="/icons/moonIcon.svg" alt="moon mode" />
              ) : (
                <img src="/icons/sunIcon.svg" alt="sun mode" />
              )}
            </IconButton>
          </Tooltip>

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            onClick={handleClick}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "unset",
            }}
          >
            <Avatar sx={{ bgcolor: "#ff9800", marginRight: "10px" }}>
              {user?.avatarUrl !== null && user?.avatarUrl?.length > 0 ? (
                <img
                  width={300}
                  height={300}
                  src={`${process.env.REACT_APP_URL_S3_IMAGE}/${process.env.REACT_APP_BUCKET_FOLDER}${user?.avatarUrl}`}
                  alt="avatar"
                  className="img-ratio rounded-4"
                  style={{ objectFit: "cover", overflow: "hidden" }}
                />
              ) : (
                <span>
                  {user?.firstName.charAt(0)}
                  {user?.lastName.charAt(0)}
                </span>
              )}
            </Avatar>
            {user?.firstName} {user?.lastName}
          </Box>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => navigate(`/profile/${user.id}`)}>
              <ListItemIcon>
                <ManageAccountsIcon />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default MainSiteHeader;
