import { Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { Clear } from "@mui/icons-material";
import { Editor as TinyMCEEditor } from "tinymce";
import { Editor } from "@tinymce/tinymce-react";
import validationMessage from "../../../../../constants/validationMessage";
import configNote from "../../../../../config/configSimple";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import { MasterSetupResItem } from "../../../../../interfaces/masterSetup";
import { FindLocalAgentHeroForm } from "../../../../../interfaces/navigationMenu";
import homePageService from "../../../../../services/homePageService";
import { ItemHero } from "../../../../../interfaces/homePage";
import { makeid } from "../../../../../utils";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import ContentComponent from "../../../../../components/Editor/ContentComponent";

const defaultData = {
  icon: "",
  title: "",
  subtitle: "",
  bodyText: "",
  image: "",
};
const validationSchema = yup.object().shape({
  icon: yup.string().required(validationMessage.required),
  title: yup.string().required(validationMessage.required),
  subtitle: yup.string().required(validationMessage.required),
  bodyText: yup.string().required(validationMessage.required),
  image: yup.string().required(validationMessage.required),
});
const ContentItem = () => {
  const editorsRef = useRef<TinyMCEEditor | null>(null);
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataHero, setDataHero] = useState<MasterSetupResItem | null>(null);
  const [dataHeroSaved, setDataHeroSaved] = useState<MasterSetupResItem | null>(
    null
  );
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm<FindLocalAgentHeroForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const submit: SubmitHandler<FindLocalAgentHeroForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "findlocalagent",
      section: "hero",
      contents: JSON.stringify(data),
    };

    setLoadingBtn(true);
    if (dataHero) {
      const res = await homePageService.update(dataHero.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Hero has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Hero has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<FindLocalAgentHeroForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("findlocalagent");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "hero"
      );
      if (data) {
        setDataHero(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataHeroSaved(data);
        }
        const initData: FindLocalAgentHeroForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: FindLocalAgentHeroForm = JSON.parse(
            dataHeroSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              icon: initData.icon ?? dataSaved.icon,
              title: initData.title ?? dataSaved.title,
              subtitle: initData.subtitle ?? dataSaved.subtitle,
              bodyText: initData.bodyText ?? dataSaved.bodyText,
              image: dataSaved.image,
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        setKeyReset(makeid(5));
      } else if (dataHeroSaved) {
        const dataSaved = JSON.parse(dataHeroSaved?.contents ?? "{}");
        setDataHero(null);
        reset(dataSaved);
        setKeyReset(makeid(5));
      } else {
        setDataHero(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <Box>
      <form onSubmit={handleSubmit(submit, error)}>
        <Paper sx={{ p: 2 }}>
          {
            loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
            <CircularProgress />
          </Box> : <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 2,
                      color:
                        watch(`title`) && watch(`title`).length > 50
                          ? "red"
                          : "black",
                    }}
                  >
                    {t("checkLength")} :{" "}
                    {watch(`title`) ? watch(`title`).length : 0}
                    /50
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    label={t("homepage_navigationMenu_findLocalAgent_title")}
                    {...register(`title`)}
                    InputLabelProps={{ shrink: true }}
                    error={errors?.title !== undefined}
                    helperText={
                      errors?.title !== undefined
                        ? validationMessage.required
                        : ""
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Controller
                    key={keyReset}
                    control={control}
                    name={`icon`}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <Box sx={{ position: "relative" }}>
                          <UploaderCustom
                            page="hero"
                            documents={value ?? ""}
                            onChange={(data: any) => {
                              onChange(data);
                            }}
                            widthImg={60}
                            heightImg={60}
                            idBtnUpload="btn-upload"
                            isUploadIcon={true}
                            type="image"
                            isShowRequire={errors?.icon !== undefined}
                          />
                          {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                            <Button
                              variant="text"
                              sx={{
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                color: "red",
                                zIndex: 99,
                                background: "white",
                                p: 1,
                                minWidth: "30px",
                              }}
                              onClick={() => onChange("")}
                            >
                              <Clear />
                            </Button>
                          )}
                        </Box>
                      );
                    }}
                  />
                  {errors?.icon !== undefined && (
                    <Typography className="text-12 mt-5 text-danger">
                      {errors?.icon !== undefined
                        ? validationMessage.required
                        : ""}
                    </Typography>
                  )}
                  <Typography className="text-14 mt-5">
                    {t("fileUpload_size")}: 48 X 42 {t("fileUpload_unit")}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 2,
                      color:
                        watch(`subtitle`) && watch(`subtitle`).length > 30
                          ? "red"
                          : "black",
                    }}
                  >
                    {t("checkLength")} :{" "}
                    {watch(`subtitle`) ? watch(`subtitle`).length : 0}
                    /30
                  </Typography>
                  <TextField
                    size="small"
                    fullWidth
                    label={t("homepage_navigationMenu_findLocalAgent_subTitle")}
                    {...register(`subtitle`)}
                    InputLabelProps={{ shrink: true }}
                    error={errors?.subtitle !== undefined}
                    helperText={
                      errors?.subtitle !== undefined
                        ? validationMessage.required
                        : ""
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 2,
                      color:
                        watch(`bodyText`) && watch(`bodyText`).length > 300
                          ? "red"
                          : "black",
                    }}
                  >
                    {t("checkLength")} :{" "}
                    {watch(`bodyText`) ? watch(`bodyText`).length : 0}
                    /300
                  </Typography>
                  <Typography className="text-18 fw-600">
                    {" "}
                    {t("homepage_navigationMenu_findLocalAgent_bodyText")}
                  </Typography>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <ContentComponent data={value} onChange={onChange} />
                      );
                    }}
                    name={`bodyText`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                key={keyReset}
                control={control}
                name={`image`}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Box sx={{ position: "relative" }}>
                      <UploaderCustom
                        page="feature-destination"
                        documents={value ?? ""}
                        onChange={(data: any) => {
                          onChange(data);
                        }}
                        widthImg={""}
                        heightImg={""}
                        idBtnUpload="btn-upload"
                        imageViewHeight="458px"
                        isShowRequire={errors?.image !== undefined}
                        type="image"
                      />
                      {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                        <Button
                          variant="text"
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            color: "red",
                            zIndex: 99,
                            background: "white",
                            p: 1,
                            minWidth: "30px",
                          }}
                          onClick={() => onChange("")}
                        >
                          <Clear />
                        </Button>
                      )}
                    </Box>
                  );
                }}
              />

              <Typography className="text-14 mt-5">
                {t("fileUpload_size")}: 598 X 458 {t("fileUpload_unit")}
              </Typography>
            </Grid>
          </Grid>
          }
         
        </Paper>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
          <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
            {t("saveButton")}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ContentItem;
