import { createContext, useContext } from "react";
export type GlobalContent = {
  companyLogo: string;
  companyName: string;
  setCompanyLogo: (logo: string) => void;
  setCompanyName: (name: string) => void;
  // theme
  mode: string;
  setMode: (m: string) => void;
  language: string;
  setLanguage: (l: string) => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  companyLogo: localStorage.getItem("companyLogo") ?? "",
  companyName: localStorage.getItem("companyName") ?? "",
  setCompanyLogo: () => { },
  setCompanyName: () => { },

  // theme
  mode: "light",
  setMode: () => { },
  language: localStorage.getItem("language") ?? "en",
  setLanguage: () => { },
});

export const useGlobalContext = () => useContext(MyGlobalContext);
