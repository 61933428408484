import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../../components/Table/TableHeader";
import { useTranslation } from "react-i18next";

const TableHeading = () => {
  const { t } = useTranslation();

  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left">#</TableCell>
      <TableCell className="bg-slight-blue text-left">{t("navigationMenu_inspiration_list_table_header_name")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("navigationMenu_inspiration_list_table_header_type")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("navigationMenu_inspiration_list_table_header_country")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("navigationMenu_inspiration_list_table_header_destination")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("navigationMenu_inspiration_list_table_header_created")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("navigationMenu_inspiration_list_table_header_updated")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("navigationMenu_inspiration_list_table_header_actions")}</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
