import {
  Box,
  Tab,
  Typography,
} from "@mui/material";
import React from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InspirationList from "./tabs/InspirationList";

const Index = () => {
  const [value, setValue] = React.useState("inspiration");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };


  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box
          sx={{
            bgcolor: "background.paper",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="fullWidth"
            scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  Inspiration
                </Typography>
              }
              value={"inspiration"}
            />
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  More Information
                </Typography>
              }
              value={"more_information"}
            />
            
            <Tab
              label={
                <Typography className="text-14 mb-10">
                  Press
                </Typography>
              }
              value={"press"}
            /><Tab
              label={
                <Typography className="text-14 mb-10">
                  Other
                </Typography>
              }
              value={"other"}
            />
          </TabList>
        </Box>
        <TabPanel value="inspiration" sx={{ px: 0 }}>
          <InspirationList blogType="inspiration" />
        </TabPanel>
        <TabPanel value="more_information" sx={{ px: 0 }}>
        <InspirationList blogType="more_information" />
        </TabPanel>
       
        <TabPanel value="press" sx={{ px: 0 }}>
        <InspirationList blogType="press" />
        </TabPanel> <TabPanel value="other" sx={{ px: 0 }}>
        <InspirationList blogType="other" />
        </TabPanel>
     
      </TabContext>
    </Box>
  );
};

export default Index;
