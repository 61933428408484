import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  Clear,
  Delete,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../interfaces/masterSetup";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import Swal from "sweetalert2";
import { WhyChooseUsForm, ItemHero } from "../../../interfaces/homePage";
import { makeid } from "../../../utils";
import MetaSEO from "./MetaSEO";
import { Editor } from "@tinymce/tinymce-react";
import configNote from "../../../config/configSimple";
import homePageService from "../../../services/homePageService";
import { Editor as TinyMCEEditor } from "tinymce";
import ContentComponent from "../../../components/Editor/ContentComponent";


const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  images: yup.string().required(validationMessage.required),
  whyChooseUs: yup
    .array()
    .of(
      yup.object().shape({
        subtitle: yup.string().required(validationMessage.required),
        bodyText: yup.string().required(validationMessage.required),
        icon: yup.string().required(validationMessage.required),
        key: yup.string().required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});
const WhyChooseUsPage = () => {
  const defaultData = {
    title: "",
    images: "",
    whyChooseUs: [{ subtitle: "", bodyText: "", icon: "", key: `HOMEPAGE_WHY_CHOOSE_US_${makeid(8)}` }],
  };
  const editorsRef = useRef<TinyMCEEditor | null>(null);
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataFeatureDestination, setDataFeatureDestination] =
    useState<MasterSetupResItem | null>(null);
    const [dataFeatureDestinationSaved, setDataFeatureDestinationSaved] =
    useState<MasterSetupResItem | null>(null);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "whyChooseUs",
  });

  const submit: SubmitHandler<WhyChooseUsForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "why-choose-us-content",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataFeatureDestination) {
      const res = await homePageService.update(
        dataFeatureDestination.id,
        payload
      );
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Why Choose Us Content has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Why Choose Us Content has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<WhyChooseUsForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "why-choose-us-content"
      );
      if (data) {
        setDataFeatureDestination(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataFeatureDestinationSaved(data);
        }
        const initData: WhyChooseUsForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: WhyChooseUsForm = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              title: initData.title ?? dataSaved.title,
              whyChooseUs: dataSaved.whyChooseUs.map(item => ({
                ...item,
                subtitle: initData.whyChooseUs.find(el => el.key === item.key)?.subtitle ?? item.subtitle,
                bodyText: initData.whyChooseUs.find(el => el.key === item.key)?.bodyText ?? item.bodyText,
              })),
            };
            console.log(dataForm, initData, "if");

            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: WhyChooseUsForm = JSON.parse(
            dataFeatureDestinationSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              ...dataSaved,
              whyChooseUs: dataSaved.whyChooseUs.map(item => ({
                ...item,
                subtitle: initData.whyChooseUs.find(el => el.key === item.key)?.subtitle ?? item.subtitle,
                bodyText: initData.whyChooseUs.find(el => el.key === item.key)?.bodyText ?? item.bodyText,
              })),
            };

            console.log(dataForm, initData, "else");

            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataFeatureDestinationSaved) {
        const dataSaved = JSON.parse(dataFeatureDestinationSaved?.contents ?? "{}");

        setDataFeatureDestination(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataFeatureDestination(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <>
      <MetaSEO />
      <form onSubmit={handleSubmit(submit, error)}>
        <Paper sx={{ p: 3, my: 2 }}>
          {
              loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
              <CircularProgress />
            </Box> :   <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch(`title`) && watch(`title`).length > 80
                      ? "red"
                      : "black",
                }}
              >
                {t("checkLength")} :{" "}
                {watch(`title`) ? watch(`title`).length : 0}
                /80
              </Typography>
              <TextField
                size="small"
                fullWidth
                label={t("homepage_whyChooseUs_content_title")}
                {...register(`title`)}
                InputLabelProps={{ shrink: true }}
                error={errors?.title !== undefined}
                helperText={
                  errors?.title !== undefined ? validationMessage.required : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                key={keyReset}
                control={control}
                name={`images`}
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Box sx={{ position: "relative" }}>
                      <UploaderCustom
                        page="why-choose-us"
                        documents={value ?? ""}
                        onChange={(data: any) => {
                          onChange(data);
                        }}
                        widthImg={1440}
                        heightImg={""}
                        idBtnUpload="btn-upload"
                        imageViewHeight="463px"
                        isShowRequire={errors?.images !== undefined}
                        type="image"
                      />
                      {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                        <Button
                          variant="text"
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            color: "red",
                            zIndex: 99,
                            background: "white",
                            p: 1,
                            minWidth: "30px",
                          }}
                          onClick={() => onChange("")}
                        >
                          <Clear />
                        </Button>
                      )}
                    </Box>
                  );
                }}
              />
             
              <Typography className="text-14 mt-5">
                {t("fileUpload_size")}: 1440 X 463 {t("fileUpload_unit")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {fields.map((item, index) => {
                  return (
                    <Grid item xs={12} key={item.id}>
                      <Paper sx={{ p: 2 }}>
                        {" "}
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              justifyContent={"space-between"}
                            >
                              <Typography className="fw-600 text-tkg-blue">
                                Section {index + 1}
                              </Typography>
                              {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Delete
                                color="error"
                                onClick={() => remove(index)}
                              />}
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              sx={{
                                mb: 2,
                                color:
                                  watch(`whyChooseUs.${index}.subtitle`) &&
                                  watch(`whyChooseUs.${index}.subtitle`)
                                    .length > 80
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {t("checkLength")} :{" "}
                              {watch(`whyChooseUs.${index}.subtitle`)
                                ? watch(`whyChooseUs.${index}.subtitle`).length
                                : 0}
                              /80
                            </Typography>
                            <TextField
                              size="small"
                              fullWidth
                              label={t("homepage_whyChooseUs_content_subtitle")}
                              {...register(`whyChooseUs.${index}.subtitle`)}
                              InputLabelProps={{ shrink: true }}
                              error={
                                errors?.whyChooseUs?.[index]?.subtitle !==
                                undefined
                              }
                              helperText={
                                errors?.whyChooseUs?.[index]?.subtitle !==
                                undefined
                                  ? validationMessage.required
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              sx={{
                                mb: 2,
                                color:
                                  watch(`whyChooseUs.${index}.bodyText`) &&
                                  watch(`whyChooseUs.${index}.bodyText`)
                                    .length > 120
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {t("checkLength")} :{" "}
                              {watch(`whyChooseUs.${index}.bodyText`)
                                ? watch(`whyChooseUs.${index}.bodyText`).length
                                : 0}
                              /120
                            </Typography>
                            <Typography className="text-18 fw-600">
                              {" "}
                              {t("homepage_whyChooseUs_content_bodyText")}
                            </Typography>
                            <Controller
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => {
                                return (
                                  <ContentComponent data={value ?? ""} onChange={onChange} />
                                  // <Editor
                                  //   disabled={false}
                                  //   value={value ?? ""}
                                  //   apiKey={
                                  //     process.env.REACT_APP_EDITOR_API_KEY
                                  //   }
                                  //   init={{
                                  //     ...configNote.editorAttr,
                                  //     height: 300,
                                  //   }}
                                  //   onInit={(evt, editor) => {
                                  //     if (index) {
                                  //       editorsRef.current = editor;
                                  //     } else {
                                  //       editorsRef.current = editor;
                                  //     }
                                  //   }}
                                  //   onEditorChange={onChange}
                                  // />
                                );
                              }}
                              name={`whyChooseUs.${index}.bodyText`}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Box pl={2} pt={2}>
                              <Controller
                                key={keyReset}
                                control={control}
                                name={`whyChooseUs.${index}.icon`}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Box sx={{ position: "relative" }}>
                                      <UploaderCustom
                                        page="feature-service"
                                        documents={value ?? ""}
                                        onChange={(data: any) => {
                                          onChange(data);
                                        }}
                                        widthImg={48}
                                        heightImg={42}
                                        idBtnUpload="btn-upload"
                                        isUploadIcon={true}
                                        imageViewHeight="42px"
                                        isShowRequire={errors?.whyChooseUs?.[index]?.icon !==
                                          undefined}
                                        type="image"
                                      />
                                      {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                                        <Button
                                          variant="text"
                                          sx={{
                                            position: "absolute",
                                            top: "-5px",
                                            right: "-5px",
                                            color: "red",
                                            zIndex: 99,
                                            background: "white",
                                            p: 1,
                                            minWidth: "30px",
                                          }}
                                          onClick={() => onChange("")}
                                        >
                                          <Clear />
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                              
                              <Typography className="text-14 mt-5">
                                {t("fileUpload_size")}: 48 X 42{" "}
                                {t("fileUpload_unit")}
                              </Typography>
                            </Box>
                          </Grid>
                          {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Grid item xs={12}>
                            <Stack alignItems={"flex-end"}>
                              <Button
                                variant="outlined"
                                onClick={() =>
                                  append({
                                    subtitle: "",
                                    bodyText: "",
                                    icon: "",
                                    key: `HOMEPAGE_WHY_CHOOSE_US_${makeid(8)}`
                                  })
                                }
                              >
                                {t("addMoreButton")}
                              </Button>
                            </Stack>
                          </Grid>}
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
                  {t("saveButton")}
                </Button>
              </Box>
            </Grid>
          </Grid>
          }
        
        </Paper>
      </form>
    </>
  );
};

export default WhyChooseUsPage;
