import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ItemHero } from "../../../interfaces/homePage";
import { HandleMasterSEO } from "../../../interfaces/masterSeo";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import homePageService from "../../../services/homePageService";

const defaultData = {
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
};
const validationSchema = yup.object().shape({
  metaTitle: yup.string().required("Field Required"),
  metaDescription: yup.string().required("Field Required"),
  metaKeyword: yup.string().required("Field Required"),
});
const MetaSEO = () => {
  const { t } = useTranslation();
  const [dataSEO, setDataSEO] = useState<ItemHero | null>(null);
  const [dataSEOSaved, setDataSEOSaved] = useState<ItemHero | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<HandleMasterSEO>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<HandleMasterSEO> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "feature-service-seo",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true)
    if (dataSEO) {
      const res = await homePageService.update(dataSEO?.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Feature Service Meta SEO has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Feature Service Meta SEO has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "feature-service-seo"
      );
      if (data) {
        setDataSEO(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataSEOSaved(data);
        }
        const initData = JSON.parse(data.contents ?? "{}");
        reset(initData);
      } else if (dataSEOSaved) {
        const dataSaved = JSON.parse(dataSEOSaved.contents ?? "{}");
        setDataSEO(null);
        reset(dataSaved);
      } else {
        setDataSEO(null);
        reset(defaultData);
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  
  return (
    <Paper sx={{ p: 5 }}>
      
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch("metaTitle") && watch("metaTitle").length > 61
                      ? "red"
                      : "black",
                }}
              >
                {t("checkLength")} :{" "}
                {watch("metaTitle") ? watch("metaTitle").length : 0}/61
              </Typography>
              <TextField
                {...register("metaTitle")}
                label={t("seo_metaTitle")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.metaTitle !== undefined}
                helperText={
                  errors.metaTitle !== undefined ? errors.metaTitle.message : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch("metaDescription") &&
                    watch("metaDescription").length > 160
                      ? "red"
                      : "black",
                }}
              >
                {t("checkLength")} :{" "}
                {watch("metaDescription") ? watch("metaDescription").length : 0}
                /160
              </Typography>
              <TextField
                {...register("metaDescription")}
                label={t("seo_metaDescription")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.metaDescription !== undefined}
                helperText={
                  errors.metaDescription !== undefined
                    ? errors.metaDescription.message
                    : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                {...register("metaKeyword")}
                label={t("seo_metaKeywords")}
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.metaKeyword !== undefined}
                helperText={
                  errors.metaKeyword !== undefined
                    ? errors.metaKeyword.message
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
              {t("saveButton")}
            </Button>
          </Box>
        </form>
      )}
    </Paper>
  );
};

export default MetaSEO;
