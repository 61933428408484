import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { PayloadSEO } from "../interfaces/masterSeo";

const seoService = {

    findDetail: async (lang: string, section: string) => {
        try {
            return await apiInstance.get(`${endPointAPI.SEO.FIND_LANG_SECTION}/${lang}/${section}`)
        } catch (error) {
            return null
        }
    },

    store: async (payload: PayloadSEO) => {
        try {
            return await apiInstance.post(endPointAPI.SEO.STORE, payload)
        } catch (error) {
            return null
        }
    },


    update: async (id: number, payload: PayloadSEO) => {
        try {
            return await apiInstance.put(`${endPointAPI.SEO.UPDATE}/${id}`, payload)
        } catch (error) {
            return null
        }
    },
}
export default seoService;
