import ContentItem from "./ContentItem";
import MetaSEO from "./MetaSEO";


const FeatureServicePage = () => {
 
  return (
    <>
    <MetaSEO />
    <ContentItem />
    </>
    
  );
};

export default FeatureServicePage;
