import { Add, ArrowDropDown, Clear, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import {
  CustomFAQItem,
  HandleInspiration,
  InspirationContentSectionItem,
  ItemInspiration,
} from "../../../../interfaces/homePage";
import UploaderCustom from "../../../../components/Upload/UploaderCustom";
import validationMessage from "../../../../constants/validationMessage";
import { useGlobalContext } from "../../../../context/MyGlobalContext";
import dataMock from "../../../../mockdata/data";
import mockForm from "../../../../data/mockForm";
import inspirationService from "../../../../services/inspirationService";
import ContentComponent from "../../../../components/Editor/ContentComponent";
import { makeTitle, makeid } from "../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import locationService from "../../../../services/locationService";
import {
  CountryType,
  DestinationType,
} from "../../../../interfaces/navigationMenu";
export function MakeRandomSlug(length: number) {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
const validationSchema = yup.object().shape({
  metaTitle: yup.string().required("Field Required"),
  metaDescription: yup.string().required("Field Required"),
  metaKeyword: yup.string().required("Field Required"),
  type: yup.string().required("Field Required"),
  destination: yup.string(),
  country: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  tourType: yup.array(),
  title: yup.string().required("Field Required"),
  slug: yup.string().required("Field Required"),
  description: yup.string(),
  imageThumbnail: yup.string(),
  contents: yup.object().shape({
    imageSmall: yup.string(),
    section: yup
      .array(
        yup.object().shape({
          key: yup.string().required("Field Required"),
          title: yup
            .string()
            .nullable()
            .transform((value) => (value === undefined ? null : value)),
          contents: yup.string().required("Field Required"),
          image: yup
            .string()
            .nullable()
            .transform((value) => (value === undefined ? null : value)),
          imageDescription: yup
            .string()
            .nullable()
            .transform((value) => (value === undefined ? null : value)),
          heading: yup
            .string()
            .nullable()
            .transform((value) => (value === undefined ? null : value)),
        })
      )
      .required("Field Required"),
    customFAQs: yup
      .array(
        yup.object().shape({
          key: yup.string().required("Field Required"),
          question: yup
            .string()
            .nullable()
            .transform((value) => (value === undefined ? null : value)),
          answer: yup
            .string()
            .nullable()
            .transform((value) => (value === undefined ? null : value)),
        })
      )
      .nullable(),
  }),
});

const InspirationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [keyReset, setKeyReset] = useState("");
  const [dataDetail, setDataDetail] = useState<ItemInspiration | null>(null);
  const [dataSaved, setDataSaved] = useState<ItemInspiration | null>(null);
  const { language, setLanguage } = useGlobalContext();
  const { slug } = useParams();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listAllCountry, setListAllCountry] = useState<CountryType[]>([]);
  const [countriesSelect, setCountriesSelect] = useState<string>();
  const [listLocations, setListLocations] = useState<string[]>([]);
  const [loadingLocation, setLoadingLocation] = useState(false);
  // const [stateSlug, setStateSlug] = useState("");
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<HandleInspiration>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const defaultData = {
    metaTitle: "",
    metaDescription: "",
    metaKeyword: "",
    type: "",
    destination: "",
    country: "",
    tourType: [],
    title: "",
    slug: `${MakeRandomSlug(8)}`,
    description: "",
    imageThumbnail: "",
    contents: {
      imageSmall: "",
      section: [
        {
          key: `INSPIRATION_CONTENTS_SECTION_${makeid(8)}`,
          title: "",
          contents: "",
          image: "",
          imageDescription: "",
          heading: "",
        },
      ],
      customFAQs: [
        {
          key: `INSPIRATION_CONTENTS_CUSTOM_FAQS_${makeid(8)}`,
          question: "",
          answer: "",
        },
      ],
    },
  };
  const submit: SubmitHandler<HandleInspiration> = async (data) => {
    // console.log(data);
    const payload = {
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      metaKeyword: data.metaKeyword,
      language: language ?? "en",
      type: data.type,
      destination: data.destination ?? "",
      country: data.country,
      tourType: JSON.stringify(data.tourType ?? []),
      title: data.title,
      slug: data.slug ?? "",
      description: data.description ?? "",
      imageThumbnail: data.imageThumbnail ?? "",
      contents: JSON.stringify({
        imageSmall: data.contents.imageSmall,
        section: data.contents.section,
        customFAQs: data.contents.customFAQs,
      }),
    };

    setLoadingBtn(true);
    if (dataDetail && dataDetail?.id) {
      const response = await inspirationService.update(
        payload,
        Number(dataDetail?.id)
      );
      if (response?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `${makeTitle(data.type)} has been updated`,
          showConfirmButton: false,
          timer: 1500,
        });
        fetchDataDetail(response.data);
      }
    } else {
      try {
        const response = await inspirationService.store(payload);
        if (response?.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${makeTitle(data.type)} has been created`,
            showConfirmButton: false,
            timer: 1500,
          });
          // fetchDataDetail(response.data);
          // setStateSlug(response.data);
          navigate("/homepage/navigation-menu/inspiration");
        }
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          try {
            await inspirationService.store(
              { ...payload, language: "fr" }
            );
          } catch (error: any) {
            console.log(error);
          }
          try {
            await inspirationService.store(
              { ...payload, language: "cn" }
            );
          } catch (error) {
            console.log(error);
          }
        }
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message ?? "Something Went Wrong",
        });
      }
    }

    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<HandleInspiration> = (errors) => {
    console.log(errors);
  };

  const { fields, remove, insert } = useFieldArray({
    control,
    name: "contents.section",
  });
  const {
    fields: fieldsFAQ,
    remove: removeFAQ,
    append: appendFAQ,
  } = useFieldArray({
    control,
    name: "contents.customFAQs",
  });
  const fetchCountries = async () => {
    let response = await locationService.getListCountries();
    if (response && response?.status === 200) {
      const countries = response.data;
      setListAllCountry(countries);
    }
  };
  useEffect(() => {
    fetchCountries();
  }, []);
  const fetchLocation = async () => {
    setLoadingLocation(true);

    try {
      if (countriesSelect) {
        const countryId = listAllCountry.find(
          (item) => item.name === countriesSelect
        )?.id
        let response = await locationService.getDestinationsByCountries({
          countriesId: countryId ? [countryId] : [],
        });
        if (response?.status === 200) {
          let data: DestinationType[] = response?.data;
          setListLocations(data.map((item) => item.name));
        }
      }
    } catch (error) {
      console.log(error);
    }

    setLoadingLocation(false);
  };
  useEffect(() => {
    if (countriesSelect) {
      fetchLocation();
    } else {
      setListLocations([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesSelect]);
  const fetchDataDetail = async (inspirationSlug: string) => {
    setLoading(true);
    const response = await inspirationService.findDetail(
      language,
      String(inspirationSlug)
    );

    if (response?.status === 200) {
      const data = {
        ...response.data,
        // ...(response.data && {tourType: JSON.parse(response.data?.tourType ?? "[]")})
      };
      if (data) {
        setDataDetail(data);
        setCountriesSelect(
        data.country
        );
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataSaved(data);
        }
        const contents = JSON.parse(data?.contents ?? "[]");
        const initData: HandleInspiration = {
          ...data,
          tourType: JSON.parse(data?.tourType ?? "[]"),
          contents,
        };
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          if (dataSaved) {
            const contents = JSON.parse(dataSaved?.contents ?? "[]");
            const dataForm: HandleInspiration = {
              ...dataSaved,
              tourType:
                initData?.tourType ?? JSON.parse(dataSaved?.tourType ?? "[]"),
              title: initData.title ?? dataSaved?.title,
              slug: initData.slug ?? dataSaved?.slug,
              description: initData.description ?? dataSaved?.description,
              metaDescription:
                initData.metaDescription ?? dataSaved?.metaDescription,
              metaKeyword: initData.metaKeyword ?? dataSaved?.metaKeyword,
              metaTitle: initData.metaTitle ?? dataSaved?.metaTitle,
              contents: {
                imageSmall: contents?.imageSmall,
                section: (contents?.section ?? []).map(
                  (item: InspirationContentSectionItem, index: number) => ({
                    ...item,
                    title:
                      (initData?.contents?.section ?? []).length > index
                        ? initData?.contents?.section[index]?.title
                        : item?.title,
                    contents:
                      (initData?.contents?.section ?? []).length > index
                        ? initData?.contents?.section[index]?.contents
                        : item?.contents,
                    heading:
                      (initData?.contents?.section ?? []).length > index
                        ? initData?.contents?.section[index]?.heading
                        : item?.heading,
                  })
                ),
                customFAQs: (contents?.customFAQs ?? []).map(
                  (item: CustomFAQItem, index: number) => ({
                    ...item,
                    question:
                      (initData?.contents?.customFAQs ?? []).length > index
                        ? initData?.contents?.customFAQs?.[index]?.question
                        : item?.question,
                    answer:
                      (initData?.contents?.customFAQs ?? []).length > index
                        ? initData?.contents?.customFAQs?.[index]?.answer
                        : item?.answer,
                  })
                ),
              },
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataSaved) {
        setCountriesSelect(
          dataSaved.country
        );
        const dataForm = {
          ...dataSaved,
          tourType: JSON.parse(dataSaved?.tourType ?? "[]"),
          contents: JSON.parse(dataSaved?.contents ?? "[]"),
        };

        setDataDetail(null);
        reset(dataForm);

        setKeyReset(makeid(5));
      } else {
        setDataDetail(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (slug) {
      if (listAllCountry.length > 0) {
        fetchDataDetail(String(slug));
      }
    } else {
      setDataDetail(null);
      reset(defaultData);
      setKeyReset(makeid(5));
      setLoading(false);
    }
  }, [language, listAllCountry, slug]);

  return (
    <Box sx={{ background: "#ddd", p: 2 }}>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <form onSubmit={handleSubmit(submit, error)} id="form-inspiration">
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Paper sx={{ p: 2, my: 2 }}>
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      {" "}
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        {t("page_header_master_seo")}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch("title") && watch("title").length > 61
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch("title") ? watch("title").length : 0}/61
                      </Typography>
                      
                            <TextField
                            id="metaTitle"
                              {...register("metaTitle")}
                              label={t("seo_metaTitle")}
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={errors?.metaTitle !== undefined}
                              helperText={
                                errors?.metaTitle !== undefined
                                  ? errors?.metaTitle.message
                                  : ""
                              }
                            />
                         
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch("metaDescription") &&
                            watch("metaDescription").length > 160
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch("metaDescription")
                          ? watch("metaDescription").length
                          : 0}
                        /160
                      </Typography>
                      <TextField
                        {...register("metaDescription")}
                        label={t("seo_metaDescription")}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors.metaDescription !== undefined}
                        helperText={
                          errors.metaDescription !== undefined
                            ? errors.metaDescription.message
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        {...register("metaKeyword")}
                        label={t("seo_metaKeywords")}
                        fullWidth
                        multiline
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors.metaKeyword !== undefined}
                        helperText={
                          errors.metaKeyword !== undefined
                            ? errors.metaKeyword.message
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item md={12}>
                <Paper sx={{ p: 2, my: 2 }}>
                  {" "}
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        {t("contentField")}
                      </Typography>
                    </Grid>
                    <Grid item md={12}>
                      <Box sx={{ my: 1 }}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name={`imageThumbnail`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="inspiration"
                                  type="image"
                                  documents={value ?? ""}
                                  onChange={(data: any) => onChange(data)}
                                  widthImg={"1200px"}
                                  heightImg={"400px"}
                                  idBtnUpload="btn-upload"
                                  isUploadIcon={false}
                                  isShowRequire={
                                    errors?.imageThumbnail !== undefined
                                      ? true
                                      : false
                                  }
                                  cssHeight="280px"
                                />
                                <Button
                                  variant="text"
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    color: "red",
                                    zIndex: 99,
                                    background: "white",
                                    p: 1,
                                    minWidth: "30px",
                                  }}
                                  onClick={() => onChange("")}
                                >
                                  <Clear />
                                </Button>
                              </Box>
                            );
                          }}
                        />
                        <Typography
                          sx={{ fontSize: 12, p: "4px 14px 0" }}
                          className={
                            errors?.imageThumbnail !== undefined
                              ? "text-color-danger"
                              : ""
                          }
                        >
                          {errors?.imageThumbnail !== undefined
                            ? validationMessage.required
                            : ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Grid container spacing={2}>
                        <Grid item md={2}>
                          <Controller
                            control={control}
                            name="type"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="type"
                                  getOptionLabel={(option) => option.label}
                                  onChange={(e, value) => {
                                    onChange(value.value);
                                  }}
                                  value={dataMock.inspirationType.find(
                                    (item) => item.value === value
                                  )}
                                  size="small"
                                  options={dataMock.inspirationType}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ background: "#fff" }}
                                      {...params}
                                      label={t(
                                        "navigationMenu_inspiration_list_table_header_type"
                                      )}
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors?.type !== undefined}
                                      helperText={
                                        errors?.type !== undefined
                                          ? errors?.type?.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Controller
                            key={keyReset}
                            control={control}
                            name="country"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="country"
                                  freeSolo
                                  value={
                                    value
                                      ? value
                                      : undefined
                                  }
                                  getOptionLabel={(option) => option}
                                  onChange={(event, value) => {
                                    setCountriesSelect(value);
                                    onChange(value);
                                  }}
                                  size="small"
                                  options={listAllCountry.map((item) => item.name)}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ background: "#fff" }}
                                      {...params}
                                      label={t(
                                        "navigationMenu_inspiration_list_table_header_country"
                                      )}
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                height: "2em",
                                                width: "max-content",
                                                paddingRight: "0.5em",
                                                position: "absolute",
                                                right: 0,
                                              }}
                                            >
                                              <ArrowDropDown />
                                            </InputAdornment>
                                            {params.InputProps.endAdornment}
                                          </div>
                                        ),
                                      }}
                                      error={errors?.country !== undefined}
                                      helperText={
                                        errors?.country !== undefined
                                          ? errors?.country?.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={5}>
                          <Controller
                            key={keyReset}
                            control={control}
                            name="tourType"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  loading={loadingLocation}
                                  disablePortal
                                  isOptionEqualToValue={() => false}
                                  multiple
                                  freeSolo
                                  id="tourType"
                                  onChange={(e, value) => {
                                    onChange(value);
                                  }}
                                  value={value ? value : undefined}
                                  size="small"
                                  options={listLocations}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ background: "#fff" }}
                                      {...params}
                                      label={t(
                                        "navigationMenu_inspiration_list_table_header_destination"
                                      )}
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                height: "2em",
                                                width: "max-content",
                                                paddingRight: "0.5em",
                                                position: "absolute",
                                                right: 0,
                                              }}
                                            >
                                              <ArrowDropDown />
                                            </InputAdornment>
                                            {params.InputProps.endAdornment}
                                          </div>
                                        ),
                                      }}
                                      error={errors?.tourType !== undefined}
                                      helperText={
                                        errors?.tourType !== undefined
                                          ? errors?.tourType?.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={9}>
                      <Stack direction={"row"} alignItems={"center"} spacing={2} mb={2}>
                       
                              <TextField
                              className="mb-0"
                              id="title"
                               {...register("title")}
                                label={t("titleField")}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={errors?.title !== undefined}
                                helperText={
                                  errors?.title !== undefined
                                    ? errors?.title.message
                                    : ""
                                }
                              />
                           <Button variant="contained" sx={{minWidth: "120px", height: "50px"}} onClick={() => setValue("title", getValues("metaTitle"))}>Copy Title</Button>
                      </Stack>
                      <Box mb={2}>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                          {t(
                            "navigationMenu_inspiration_list_table_header_destination"
                          )}
                        </Typography>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <ContentComponent
                                data={value ? value : ""}
                                onChange={onChange}
                                heightContent="150px"
                              />
                            );
                          }}
                          name={`description`}
                        />
                        <Typography
                          sx={{ fontSize: 12, p: "4px 14px 0" }}
                          className={
                            errors?.description !== undefined
                              ? "text-color-danger"
                              : ""
                          }
                        >
                          {errors?.description !== undefined
                            ? validationMessage.required
                            : ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={3}>
                      <Box sx={{ my: 1 }}>
                        <Controller
                          key={keyReset}
                          control={control}
                          name={`contents.imageSmall`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="inspiration"
                                  type="image"
                                  documents={value ?? ""}
                                  onChange={(data: any) => onChange(data)}
                                  widthImg={""}
                                  heightImg={""}
                                  idBtnUpload="btn-upload"
                                  isUploadIcon={false}
                                  isShowRequire={
                                    errors?.contents?.imageSmall !== undefined
                                      ? true
                                      : false
                                  }
                                  cssHeight="280px"
                                />
                                <Button
                                  variant="text"
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    color: "red",
                                    zIndex: 99,
                                    background: "white",
                                    p: 1,
                                    minWidth: "30px",
                                  }}
                                  onClick={() => onChange("")}
                                >
                                  <Clear />
                                </Button>
                              </Box>
                            );
                          }}
                        />
                        <Typography
                          sx={{ fontSize: 12, p: "4px 14px 0" }}
                          className={
                            errors?.contents?.imageSmall !== undefined
                              ? "text-color-danger"
                              : ""
                          }
                        >
                          {errors?.contents?.imageSmall !== undefined
                            ? validationMessage.required
                            : ""}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      {fields.map((field, index) => (
                        <Card
                          key={field.id}
                          sx={{ position: "relative", p: 2, mb: 2 }}
                        >
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 500, mb: 2 }}
                          >
                            {t(
                              "navigationMenu_inspiration_store_sectionHeader"
                            )}{" "}
                            {index + 1}
                          </Typography>
                          {language ===
                            process.env.REACT_APP_DEFAULT_LANGUAGE && (
                            <Button
                              variant="contained"
                              sx={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                color: "red",
                                zIndex: 99,
                                background: "white",
                                p: 1,
                                minWidth: "30px",
                              }}
                              onClick={() => remove(index)}
                            >
                              <Delete />
                            </Button>
                          )}
                          <Grid container spacing={2}>
                            {/* <Grid item xs={12} md={9}>
                              <TextField
                              size="small"
                                {...register(`contents.section.${index}.title`)}
                                label={"Title"}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  errors.contents?.section?.[index]?.title !==
                                  undefined
                                }
                                helperText={
                                  errors.contents?.section?.[index]?.title !==
                                  undefined
                                    ? validationMessage.required
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Autocomplete
                                      size="small"
                                      disablePortal
                                      id={`contents.section.${index}.heading`}
                                      getOptionLabel={(option) =>
                                        makeTitle(option)
                                      }
                                      onChange={(e, value) => {
                                        onChange(value);
                                      }}
                                      value={dataMock.headingList.find(
                                        (item) => item === value
                                      )}
                                      options={dataMock.headingList}
                                      renderInput={(params) => (
                                        <TextField
                                          sx={{ background: "#fff" }}
                                          {...params}
                                          label={"Type"}
                                          defaultValue={""}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          error={
                                            errors.contents?.section?.[index]?.heading !==
                                            undefined
                                          }
                                          helperText={
                                            errors.contents?.section?.[index]?.heading !==
                                            undefined
                                              ? validationMessage.required
                                              : ""
                                          }
                                        />
                                      )}
                                    />
                                  );
                                }}
                                name={`contents.section.${index}.heading`}
                              />
                            </Grid> */}
                            <Grid item md={12}>
                              <Typography
                                sx={{ fontSize: 14, fontWeight: 500 }}
                              >
                                {t(
                                  "navigationMenu_inspiration_store_sectionContent"
                                )}
                              </Typography>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <ContentComponent
                                      error={
                                        !!errors.contents?.section?.[index]
                                          ?.contents
                                      }
                                      data={value ? value : ""}
                                      onChange={onChange}
                                      heightContent="150px"
                                    />
                                  );
                                }}
                                name={`contents.section.${index}.contents`}
                              />
                              <Typography
                                sx={{ fontSize: 12, p: "4px 14px 0" }}
                                className={
                                  errors?.contents?.section?.[index]
                                    ?.contents !== undefined
                                    ? "text-color-danger"
                                    : ""
                                }
                              >
                                {errors?.contents?.section?.[index]
                                  ?.contents !== undefined
                                  ? validationMessage.required
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <Controller
                                key={keyReset}
                                control={control}
                                name={`contents.section.${index}.image`}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Box sx={{ position: "relative" }}>
                                      <UploaderCustom
                                        page="inspiration"
                                        type="image"
                                        documents={value ?? ""}
                                        onChange={(data: any) => onChange(data)}
                                        widthImg={""}
                                        heightImg={""}
                                        idBtnUpload="btn-upload"
                                        isUploadIcon={false}
                                        isShowRequire={
                                          errors?.contents?.section?.[index]
                                            ?.image !== undefined
                                            ? true
                                            : false
                                        }
                                      />
                                      <Button
                                        variant="text"
                                        sx={{
                                          position: "absolute",
                                          top: "-5px",
                                          right: "-5px",
                                          color: "red",
                                          zIndex: 99,
                                          background: "white",
                                          p: 1,
                                          minWidth: "30px",
                                        }}
                                        onClick={() => onChange("")}
                                      >
                                        <Clear />
                                      </Button>
                                    </Box>
                                  );
                                }}
                              />
                              <Typography
                                sx={{ fontSize: 12, p: "4px 14px 0" }}
                                className={
                                  errors?.contents?.section?.[index]?.image !==
                                  undefined
                                    ? "text-color-danger"
                                    : ""
                                }
                              >
                                {errors?.contents?.section?.[index]?.image !==
                                undefined
                                  ? validationMessage.required
                                  : ""}
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <TextField
                                size="small"
                                {...register(
                                  `contents.section.${index}.imageDescription`
                                )}
                                label={t("imageDescriptionField")}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  errors.contents?.section?.[index]
                                    ?.imageDescription !== undefined
                                }
                                helperText={
                                  errors.contents?.section?.[index]
                                    ?.imageDescription !== undefined
                                    ? validationMessage.required
                                    : ""
                                }
                              />
                            </Grid>
                            {language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Grid item xs={12}>
                                <Box
                                  display={"flex"}
                                  justifyContent={"center"}
                                  mt={2}
                                >
                                  <Button
                                    variant="outlined"
                                    startIcon={<Add />}
                                    onClick={() =>
                                      insert(index + 1, {
                                        title: "",
                                        contents: "",
                                        image: "",
                                        key: `INSPIRATION_CONTENTS_SECTION_${makeid(
                                          8
                                        )}`,
                                      })
                                    }
                                  >
                                    {t("addMoreButton")}
                                  </Button>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Card>
                      ))}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, my: 2 }}>
                  {" "}
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography variant="h5" sx={{ mb: 1 }}>
                        {t("customFAQsField")}
                      </Typography>
                    </Grid>

                    <Grid item md={12}>
                      {fieldsFAQ.map((field, index) => (
                        <Card
                          key={field.id}
                          sx={{ position: "relative", p: 2, mb: 2 }}
                        >
                          <Typography
                            sx={{ fontSize: 16, fontWeight: 500, mb: 2 }}
                          >
                            {t("navigationMenu_inspiration_store_CustomFAQ")}{" "}
                            {index + 1}
                          </Typography>
                          {language ===
                            process.env.REACT_APP_DEFAULT_LANGUAGE && (
                            <Button
                              variant="contained"
                              sx={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                color: "red",
                                zIndex: 99,
                                background: "white",
                                p: 1,
                                minWidth: "30px",
                              }}
                              onClick={() => removeFAQ(index)}
                            >
                              <Delete />
                            </Button>
                          )}
                          <Grid container spacing={2}>
                            <Grid item md={12}>
                              <TextField
                                size="small"
                                {...register(
                                  `contents.customFAQs.${index}.question`
                                )}
                                label={t("homepage_faqs_questionField")}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  errors.contents?.customFAQs?.[index]
                                    ?.question !== undefined
                                }
                                helperText={
                                  errors.contents?.customFAQs?.[index]
                                    ?.question !== undefined
                                    ? validationMessage.required
                                    : ""
                                }
                              />
                            </Grid>
                            <Grid item md={12}>
                              <Typography
                                sx={{ fontSize: 14, fontWeight: 500 }}
                              >
                                {t("homepage_faqs_answerField")}
                              </Typography>
                              <Controller
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <ContentComponent
                                      error={
                                        !!errors.contents?.customFAQs?.[index]
                                          ?.answer
                                      }
                                      data={value ? value : ""}
                                      onChange={onChange}
                                      heightContent="150px"
                                    />
                                  );
                                }}
                                name={`contents.customFAQs.${index}.answer`}
                              />
                              <Typography
                                sx={{ fontSize: 12, p: "4px 14px 0" }}
                                className={
                                  errors?.contents?.customFAQs?.[index]
                                    ?.answer !== undefined
                                    ? "text-color-danger"
                                    : ""
                                }
                              >
                                {errors?.contents?.customFAQs?.[index]
                                  ?.answer !== undefined
                                  ? validationMessage.required
                                  : ""}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      ))}
                    </Grid>
                    {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                      <Grid item xs={12}>
                        <Box display={"flex"} justifyContent={"center"} mt={2}>
                          <Button
                            variant="outlined"
                            startIcon={<Add />}
                            onClick={() =>
                              appendFAQ({
                                question: "",
                                answer: "",
                                key: `INSPIRATION_CONTENTS_CUSTOM_FAQS_${makeid(
                                  8
                                )}`,
                              })
                            }
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </Box>
      )}

      <Box display={"flex"} justifyContent={"end"} alignItems={"center"}>
        <Button
          disabled={loadingBtn}
          variant="contained"
          type={"submit"}
          form="form-inspiration"
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default InspirationForm;
