export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const lowercaseLetter = (string: string) => {
    return string[0] + string.slice(1).toLowerCase();
}


export const makeSlugKey = (str: string) => {
    str = str
      .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, " ")
      .toLowerCase();
  
    str = str.replace(/^\s+|\s+$/gm, "");
  
    str = str.replace(/\s+/g, "_");
  
    return str;
  };
