import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Clear, Delete } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import { WhyChooseUsOverviewForm } from "../../../../../interfaces/navigationMenu";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { ItemHero } from "../../../../../interfaces/homePage";
import { makeid } from "../../../../../utils";

const defaultData = {
  title: "",
  description: "",
  images: "",
  keywords: "",
};
const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  description: yup.string().required(validationMessage.required),
  images: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  keywords: yup.string().required(validationMessage.required),
});
const Overview = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataOverview, setDataOverview] = useState<ItemHero | null>(null);
  const [dataOverviewSaved, setDataOverviewSaved] = useState<ItemHero | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsOverviewForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<WhyChooseUsOverviewForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "overview",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataOverview) {
      const res = await homePageService.update(dataOverview.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Overview has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Overview has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<WhyChooseUsOverviewForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "overview"
      );
      if (data) {
        setDataOverview(data);

        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataOverviewSaved(data);
        }
        const initData: WhyChooseUsOverviewForm = JSON.parse(data.contents ?? "{}");

        if(language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);

        } else {
          const dataSaved: WhyChooseUsOverviewForm = JSON.parse(dataOverviewSaved?.contents ?? "{}");

          reset({...initData, images: dataSaved.images ?? initData.images});

        }
        setKeyReset(makeid(5));
      } else if (dataOverviewSaved) {
        const dataSaved = JSON.parse(dataOverviewSaved.contents ?? "{}");
        setDataOverview(null);
        reset(dataSaved);
        setKeyReset(makeid(5));
      } else {
        setDataOverview(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      {" "}
      <Paper sx={{ p: 3 }}>
        {loading ? (
          <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Typography
                className="text-14 fw-600 mb-10"
                color={watch("title")?.length > 61 ? "error" : "green"}
              >
                {t("checkLength")}: {watch("title")?.length}/61
              </Typography>

              <TextField
                size="small"
                fullWidth
                {...register(`title`)}
                label={t(
                  "homepage_navigationMenu_whyChooseUs_overview_metaTitle"
                )}
                InputLabelProps={{ shrink: true }}
                error={errors?.title !== undefined}
                helperText={
                  errors?.title !== undefined ? validationMessage.required : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className="text-14 fw-600 mb-10"
                color={watch("description")?.length > 160 ? "error" : "green"}
              >
                {t("checkLength")}: {watch("description")?.length}/160
              </Typography>
              <TextField
                size="small"
                fullWidth
                {...register(`description`)}
                label={t(
                  "homepage_navigationMenu_whyChooseUs_overview_metaDescription"
                )}
                InputLabelProps={{ shrink: true }}
                error={errors?.description !== undefined}
                helperText={
                  errors?.description !== undefined
                    ? validationMessage.required
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                {...register(`keywords`)}
                multiline
                rows={4}
                label={t(
                  "homepage_navigationMenu_whyChooseUs_overview_metaKeywords"
                )}
                InputLabelProps={{ shrink: true }}
                error={errors?.keywords !== undefined}
                helperText={
                  errors?.keywords !== undefined
                    ? validationMessage.required
                    : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                key={keyReset}
                control={control}
                name="images"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Box sx={{ position: "relative" }}>
                      <UploaderCustom
                        page="master-setup"
                        documents={value ?? ""}
                        onChange={(data: any) => onChange(data)}
                        widthImg={1440}
                        heightImg={""}
                        idBtnUpload="btn-upload"
                        type="image"
                      />
                      {value && (
                        <Button
                          variant="text"
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            color: "red",
                            zIndex: 99,
                            background: "white",
                            p: 1,
                            minWidth: "30px",
                          }}
                          onClick={() => onChange("")}
                        >
                          <Clear />
                        </Button>
                      )}
                    </Box>
                  );
                }}
              />

              <Typography className="text-14 mt-5">
                {t("fileUpload_size")}: 1440 X 463 {t("fileUpload_unit")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
                <Button disabled={loadingBtn} variant="contained" type="submit">
                  {t("saveButton")}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Paper>
    </form>
  );
};

export default Overview;
