import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import sampleAvatar from "../../media/images/user.png";
import dayjs from "dayjs";
import { userAuth } from "../../interfaces/authentication";
import { useEffect, useState } from "react";

const UserProfile = () => {
  const user: userAuth = JSON.parse(localStorage.getItem("user") as string);
  const [avatar, setAvatar] = useState<any>([]);

  const personalDetail = [
    {
      title: "Title",
      value: `${user.title ? user.title : ""}`,
    },
    {
      title: "Fullname",
      value: `${user.firstName ? user.firstName : ""} ${
        user.middleName ? user.middleName : ""
      } ${user.lastName ? user.lastName : ""}`,
    },
    {
      title: "Date of Birth",
      value: `${user.dob ? dayjs(user.dob).format("DD MMM YYYY") : ""}`,
    },
    {
      title: "Email",
      value: `${user.email ? user.email : ""}`,
    },
    {
      title: "Country Code",
      value: `${user.countryCode ? user.countryCode : ""}`,
    },
    {
      title: "Phone Number",
      value: `${user.phoneNumber ? user.phoneNumber : ""}`,
    },
    {
      title: "Position",
      value: `${user.position ? user.position : ""}`,
    },
    {
      title: "Department",
      value: `${user.department ? user.department : ""}`,
    },
    // {
    //   title: "Role",
    //   value: dataSelect.rolesList.find((item) => item.id === user.roleId)?.name,
    // },
  ];

  useEffect(() => {
    if (user && user.avatarUrl) {
      const isvalidAvatar = user?.avatarUrl?.length > 0;
      const listImage: (string | undefined)[] =
        user.avatarUrl !== null && isvalidAvatar ? [user.avatarUrl] : [];
      setAvatar(listImage);
    }
    document.title = "Profiles";
  }, [user]);

  return (
    <Box sx={{ my: 3, py: 3, borderRadius: 2 }}>
      <Container maxWidth="xl">
        <Card sx={{ width: "100%", height: "100%", borderRadius: 3 }}>
          <Box
            sx={{
              minHeight: 54,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 3,
              py: "10px",
              fontWeight: 600,
              fontSize: 20,
              borderBottom: "1px solid #f0f0f0",
            }}
          >
            Profile
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Button sx={{ display: "flex" }}>
                <Chip
                  variant="outlined"
                  color="primary"
                  label={"Activities"}
                  sx={{
                    p: "2px",
                    fontSize: "14px",
                    height: "fit-content",
                    textTransform: "uppercase",
                    borderRadius: "8px",
                  }}
                />
              </Button>
            </Box> */}
          </Box>
          <CardContent sx={{ p: 3 }}>
            <Box sx={{ pb: 3 }}>

                <Grid
                  container
                  spacing={1}
                  sx={{
                    pr: 2,
                    m: 0,
                    height: "100%",
                    overflow: "hidden",
                    overflowY: "auto",
                  }}
                  justifyContent="space-around"
                >
                  <Grid item xs={12} md={12}>
                    <Paper
                      sx={{
                        p: 2,
                        borderRadius: "0.75rem",
                        boxShadow: "unset",
                      }}
                    >
            
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, md: 3 }}
                          mb={3}
                        >
                          <Grid
                            item
                            xs={12}
                            md={3}
                            display={"flex"}
                            justifyContent={"center"}
                            sx={{ mb: "0 !important" }}
                            borderRadius={"50%"}
                          >
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              alignItems={"center"}
                            >
                              <Box
                                sx={{
                                  width: "100%",
                                  maxHeight: "120px",
                                  maxWidth: "120px",
                                  aspectRatio: "1/1",
                                  color: "#5b5b5b",
                                  bgcolor: "#f5f5f5",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                }}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                              >
                                <img
                                  width={165}
                                  height={165}
                                  src={
                                    avatar?.length > 0
                                      ? avatar[0]
                                      : sampleAvatar
                                  }
                                  alt="img"
                                  className="img-ratio rounded-4"
                                  style={{ objectFit: "contain" }}
                                />
                              </Box>

                              <Typography
                                mt={1}
                                variant="h3"
                                component={"h3"}
                                fontSize={20}
                              >
                                {`${user.title ? user.title : ""} ${
                                  user.firstName ? user.firstName : ""
                                } ${user.lastName ? user.lastName : ""}`}
                              </Typography>
                              <Box sx={{ textAlign: "left", width: "100%" }}>
                                {user.email && (
                                  <Typography
                                    mt={1}
                                    variant="h4"
                                    component={"h4"}
                                    fontSize={14}
                                  >
                                    {`${user.email}`}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography
                              variant="h3"
                              component={"h3"}
                              fontSize={20}
                            >
                              Personal Details
                            </Typography>

                            <Box>
                              {personalDetail.map((item, index) => (
                                <Box key={index} mt={1} py={1} display={"flex"}>
                                  <Grid
                                    container
                                    rowSpacing={1}
                                    columnSpacing={1}
                                    borderBottom={"1px solid #eee"}
                                  >
                                    <Grid item xs={12} md={2} py={1}>
                                      <Typography
                                        variant="h3"
                                        component={"h3"}
                                        fontSize={14}
                                        fontWeight={600}
                                      >
                                        {item.title}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10} py={1}>
                                      <Typography
                                        variant="h3"
                                        component={"h3"}
                                        fontSize={14}
                                      >
                                        {item.value}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              ))}
                            </Box>
                          </Grid>
                        </Grid>
                    </Paper>
                  </Grid>
                </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default UserProfile;
