import { Add, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  HandleCustomerReview,
  HandleFinderForm,
  HandleInprirationUs,
  HandleKnowMore,
  ItemData,
  PayloadHeroSection,
} from "../../../interfaces/homePage";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import mockForm from "../../../data/mockForm";
import { makeid } from "../../../utils";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import homePageService from "../../../services/homePageService";

const validationSchema = yup.object().shape({
  title: yup.string().required("Field Required"),
  subTitle: yup.string().required("Field Required"),
  url: yup.string().required("Field Required"),
  image: yup.string().required("Field Required"),
});

const InspirationUs = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const { language } = useGlobalContext();
  const [dataInpirationByUs, setDataInpirationByUs] = useState<ItemData | null>(
    null
  );
  const [dataInpirationByUsSaved, setDataInpirationByUsSaved] =
    useState<ItemData | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<HandleInprirationUs>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<HandleInprirationUs> = async (data) => {
    const payload: PayloadHeroSection = {
      language: language ?? "en",
      type: "homepage",
      section: "know-more-inpriration-us",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataInpirationByUs) {
      const res = await homePageService.update(dataInpirationByUs.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Know More has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Know More has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<HandleKnowMore> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: any) =>
          item.language === language &&
          item.section === "know-more-inpriration-us"
      );
      if (data) {
        setDataInpirationByUs(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataInpirationByUsSaved(data);
        }
        const initData = JSON.parse(data.contents ?? "{}");
        reset(initData);
        setKeyReset(makeid(5));
      } else if (dataInpirationByUsSaved) {
        const dataSaved = JSON.parse(dataInpirationByUsSaved.contents ?? "{}");
        setDataInpirationByUs(null);
        reset(dataSaved);
      } else {
        setDataInpirationByUs(null);
        reset({
          title: "",
          subTitle: "",
          url: "",
          image: "",
        });
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit, error)}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6">
                  {t(
                    "homepage_knowMore_inspirationByUs_cardAdjustments_header"
                  )}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("title") && watch("title").length > 25
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("masterSeo_metaDescription_checkLength")} :{" "}
                      {watch("title") ? watch("title").length : 0}/25
                    </Typography>
                    <TextField
                      {...register("title")}
                      label={t("titleField")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.title !== undefined}
                      helperText={
                        errors?.title !== undefined ? errors?.title.message : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("subTitle") && watch("subTitle").length > 55
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("masterSeo_metaDescription_checkLength")} :{" "}
                      {watch("subTitle") ? watch("subTitle").length : 0}/55
                    </Typography>
                    <TextField
                      {...register("subTitle")}
                      label={t("subtitleField")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.subTitle !== undefined}
                      helperText={
                        errors?.subTitle !== undefined
                          ? errors?.subTitle.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("url") && watch("url").length > 55
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("masterSeo_metaDescription_checkLength")} :{" "}
                      {watch("url") ? watch("url").length : 0}/55
                    </Typography>
                    <TextField
                      {...register("url")}
                      label={t(
                        "homepage_knowMore_inspirationByUs_cardAdjustments_buttonURL"
                      )}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.url !== undefined}
                      helperText={
                        errors?.url !== undefined ? errors?.url.message : ""
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Controller
                      control={control}
                      name={`image`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="brochure-find-an-agent"
                              type="image"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={630}
                              heightImg={316}
                              idBtnUpload="btn-upload"
                              isShowRequire={errors.image !== undefined}
                            />
                            {language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Button
                                variant="contained"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => onChange("")}
                              >
                                <Delete />
                              </Button>
                            )}
                          </Box>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <Button disabled={loadingBtn} type="submit" variant="contained">
              {t("saveButton")}
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};

export default InspirationUs;
