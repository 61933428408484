import { Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../interfaces/masterSetup";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import Swal from "sweetalert2";
import { ItemHero } from "../../../interfaces/homePage";
import { makeid } from "../../../utils";
import homePageService from "../../../services/homePageService";

interface OtherFrom {
  url: string;
}
const defaultData = {
  url: "",
};
const validationSchema = yup.object().shape({
  url: yup.string().required(validationMessage.required),
});
const OtherPage = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataOtherPage, setDataOtherPage] = useState<MasterSetupResItem | null>(
    null
  );
  const [dataOtherPageSaved, setDataOtherPageSaved] = useState<MasterSetupResItem | null>(
    null
  );
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<OtherFrom>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const submit: SubmitHandler<OtherFrom> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "other",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataOtherPage) {
      const res = await homePageService.update(dataOtherPage.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Other Page has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Other Page has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<OtherFrom> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "other"
      );
      if (data) {
        setDataOtherPage(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataOtherPageSaved(data);
        }
        const initData = JSON.parse(data.contents);
        reset(initData);
        setKeyReset(makeid(5));
      }  else if (dataOtherPageSaved) {
        const dataSaved = JSON.parse(dataOtherPageSaved.contents ?? "{}");
        setDataOtherPage(null);
        reset(dataSaved);
      } else {
        setDataOtherPage(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ p: 3, mb: 2 }}>
        {
            loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
            <CircularProgress />
          </Box> :  <Grid container spacing={6} justifyContent={"center"}>
          <Grid item xs={12}>
            <Typography className="text-28 text-center fw-700">
              {t("homepage_otherpage_header")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              size="small"
              fullWidth
              label={t("urlField")}
              {...register(`url`)}
              InputLabelProps={{ shrink: true }}
              error={errors?.url !== undefined}
              helperText={
                errors?.url !== undefined ? validationMessage.required : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button variant="contained" className="h-45">
              {t("contactUsButton")}
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button disabled={loadingBtn} type="submit" variant="contained" sx={{ px: 3 }}>
                {t("saveButton")}
              </Button>
            </Box>
          </Grid>
        </Grid>
        }
       
      </Paper>
    </form>
  );
};

export default OtherPage;
