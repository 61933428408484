import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { CustomerReviewItem } from "../../../interfaces/homePage";
import inspirationService from "../../../services/inspirationService";
import { makeTitle } from "../../../utils";
import customerReviewService from "../../../services/customerReviewService";
const options = [
  "Edit",
  "Delete",
  "History"
];

const StyledTableCell = styled(TableCell)(({ width }: { width?: string }) => ({
  ...(width && { width: width, maxWidth: width }),
  fontSize: 14,
  padding: 8,
  "&:first-of-type": {
    paddingLeft: 20,
  },
  "&:last-of-type": {
    paddingRight: 20,
  },
}));

interface Props {
  data: CustomerReviewItem;
  dataKey: number;
  childrenCallback: () => void;
}

const TableItem = ({ data, dataKey, childrenCallback }: Props) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);

  const handleDelete = (id: number) => {
    Swal.fire({
      icon: "question",
      title: "Warning!",
      text: "Delete this? You can't revert this.",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      confirmButtonColor: "#d32f2f",
    }).then(async (res) => {
      if (res?.isConfirmed) {
        const response = await customerReviewService.delete(id);

    if (response?.status === 200) {
      Swal.fire({
        icon: "success",
        title: response ? response.data : `Customer Review has been deleted`,
      });
      childrenCallback();
    }
      }
    });
  };

  const editProduct = (data: CustomerReviewItem) => {
    navigate(`/homepage/customer-review/update/${data.id}/${data.keyLang}`)
    // setIsEdit(true)
  };

  const handleClick = (data: CustomerReviewItem) => {
    switch (options[selectedIndex]) {
      case "Edit":
        return editProduct(data);
      case "Delete":
        return handleDelete(data.id);
        case "History":
          return setShowHistory(true);
      default:
        return;
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
    data: CustomerReviewItem
  ) => {
    setSelectedIndex(index);
    setOpen(false);
    switch (options[index]) {
      case "Edit":
        return editProduct(data);
      case "Delete":
        return handleDelete(data.id);
        case "History":
          return setShowHistory(true);
      default:
        return;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <TableRow className={"item-hover"}>
        <StyledTableCell align="left">
          <img alt="svg icon" className="w-55 h-55 rounded-50" style={{objectFit: "cover"}} src={data.avatar} />
        </StyledTableCell>

        <StyledTableCell align="center" className="w-150"><img alt="country icon" className="w-50" src={data.flag} /></StyledTableCell>
        <StyledTableCell align="center" className="w-200">{data.fullname}</StyledTableCell>
        <StyledTableCell align="left">
          <Box dangerouslySetInnerHTML={{
            __html: data.review ? data.review : "",
          }} />
        </StyledTableCell>
        <TableCell align="center">
          <Box className="flex-center flex-1">
            <ButtonGroup
              variant="contained"
              ref={anchorRef}
              aria-label="split button"
              color={options[selectedIndex] === "Delete" ? "error" : "inherit"}
              className="box-unset rounded-20"
            >
              <Button
                onClick={() => handleClick(data)}
                sx={{ borderTopLeftRadius: 20, borderBottomLeftRadius: 20 }}
              >
                {options[selectedIndex]}
              </Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
                sx={{
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) =>
                          handleMenuItemClick(event, index, data)
                        }
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableItem;
