import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { makeid } from "../../../../utils";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGlobalContext } from "../../../../context/MyGlobalContext";
import { useTranslation } from "react-i18next";
import { ItemHero, ViewInspirationForm } from "../../../../interfaces/homePage";
import validationMessage from "../../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../../interfaces/masterSetup";
import homePageService from "../../../../services/homePageService";
import UploaderCustom from "../../../../components/Upload/UploaderCustom";
import { Clear } from "@mui/icons-material";

const validationSchema = yup.object().shape({
  youTubeLink: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  facebookLink: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  twitterLink: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  instagramLink: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  imageThumbnail: yup.string().required(validationMessage.required),
  imageFollowUs: yup.string().required(validationMessage.required),
});

const defaultData = {
  youTubeLink: "",
  facebookLink: "",
  twitterLink: "",
  instagramLink: "",
  imageThumbnail: "",
  imageFollowUs: "",
};

const ViewInspiration = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataInspiration, setDataInspiration] =
    useState<MasterSetupResItem | null>(null);
  const [dataInspirationSaved, setDataInspirationSaved] =
    useState<MasterSetupResItem | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useForm<ViewInspirationForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<ViewInspirationForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "inspiration",
      section: "view-inspiration",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataInspiration) {
      const res = await homePageService.update(dataInspiration.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Inspiration has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Inspiration has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<ViewInspirationForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    // setLoading(true)
    const response = await homePageService.getList("inspiration");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "view-inspiration"
      );
      if (data) {
        setDataInspiration(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataInspirationSaved(data);
        }
        const initData: ViewInspirationForm = JSON.parse(data.contents ?? "{}");
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: ViewInspirationForm = JSON.parse(
            dataInspirationSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              youTubeLink: initData.youTubeLink ?? dataSaved.youTubeLink,
              facebookLink: initData.facebookLink ?? dataSaved.facebookLink,
              twitterLink: initData.twitterLink ?? dataSaved.twitterLink,
              instagramLink: initData.instagramLink ?? dataSaved.instagramLink,
              imageThumbnail: dataSaved.imageThumbnail,
              imageFollowUs: dataSaved.imageFollowUs,
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataInspirationSaved) {
        const dataSaved = JSON.parse(dataInspirationSaved?.contents ?? "{}");

        setDataInspiration(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataInspiration(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2, my: 2 }}>
          <form onSubmit={handleSubmit(submit, error)} id="form-inspiration">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box pl={2} pt={2}>
                  <Controller
                    key={keyReset}
                    control={control}
                    name={`imageThumbnail`}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <Box sx={{ position: "relative" }}>
                          <UploaderCustom
                            page="feature-service"
                            documents={value ?? ""}
                            onChange={(data: any) => {
                              onChange(data);
                            }}
                            widthImg={1440}
                            heightImg={602}
                            idBtnUpload="btn-upload"
                            isUploadIcon={true}
                            type="image"
                            isShowRequire={!!errors?.imageThumbnail}
                            imageViewHeight="602px"
                          />
                          {value &&
                            language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Button
                                variant="text"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => onChange("")}
                              >
                                <Clear />
                              </Button>
                            )}
                        </Box>
                      );
                    }}
                  />

                  <Typography className="text-14 mt-5">
                    {t("fileUpload_size")}: 1440 X 602 {t("fileUpload_unit")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Stack spacing={2}>
                      <Typography
                        className="text-28 fw-600"
                        sx={{ color: "#ff7017" }}
                      >
                        {t("navigationMenu_inspiration_view_FollowUs_header")}
                      </Typography>
                      <Stack spacing={2}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img
                            src={"/icons/fab--youtube.svg"}
                            alt="svg icon"
                            className="w-40 pb-8"
                          />
                          <TextField
                            fullWidth
                            size="small"
                            label={t("urlField")}
                            InputLabelProps={{ shrink: true }}
                            {...register("youTubeLink")}
                          />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img
                            src={"/icons/fab--facebook.svg"}
                            alt="svg icon"
                            className="w-40 pb-8"
                          />
                          <TextField
                            fullWidth
                            size="small"
                            label={t("urlField")}
                            InputLabelProps={{ shrink: true }}
                            {...register("facebookLink")}
                          />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img
                            src={"/icons/fab--x.svg"}
                            alt="svg icon"
                            className="w-40 pb-8"
                          />
                          <TextField
                            fullWidth
                            size="small"
                            label={t("urlField")}
                            InputLabelProps={{ shrink: true }}
                            {...register("twitterLink")}
                          />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <img
                            src={"/icons/fab--instagram.svg"}
                            alt="svg icon"
                            className="w-40 pb-8"
                          />
                          <TextField
                            fullWidth
                            size="small"
                            label={t("urlField")}
                            InputLabelProps={{ shrink: true }}
                            {...register("instagramLink")}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Box pl={2} pt={2}>
                      <Controller
                        key={keyReset}
                        control={control}
                        name={`imageFollowUs`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UploaderCustom
                                page="inspiration"
                                documents={value ?? ""}
                                onChange={(data: any) => {
                                  onChange(data);
                                }}
                                widthImg={883}
                                heightImg={530}
                                idBtnUpload="btn-upload"
                                isUploadIcon={true}
                                type="image"
                                isShowRequire={!!errors?.imageFollowUs}
                                imageViewHeight="530px"
                              />
                              {value &&
                                language ===
                                  process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                            </Box>
                          );
                        }}
                      />

                      <Typography className="text-14 mt-5">
                        {t("fileUpload_size")}: 883 X 530 {t("fileUpload_unit")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
                  <Button
                    disabled={loadingBtn}
                    type="submit"
                    variant="contained"
                  >
                    {t("saveButton")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      )}
    </>
  );
};

export default ViewInspiration;
