import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import { WhyChooseUsSearchLinkForm } from "../../../../../interfaces/navigationMenu";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { ItemHero } from "../../../../../interfaces/homePage";
import { makeid } from "../../../../../utils";
import { Clear, Delete } from "@mui/icons-material";
const defaultData = {
  title: "",
  images: "",
  urlButton: "",
};
const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  images: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  urlButton: yup.string().required(validationMessage.required),
});
const SearchLink = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataSearchLink, setDataSearchLink] = useState<ItemHero | null>(null);
  const [dataSearchLinkSaved, setDataSearchLinkSaved] =
    useState<ItemHero | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsSearchLinkForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<WhyChooseUsSearchLinkForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "searchlink",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataSearchLink) {
      const res = await homePageService.update(dataSearchLink.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Search Link has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Search Link has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<WhyChooseUsSearchLinkForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "searchlink"
      );
      if (data) {
        setDataSearchLink(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataSearchLinkSaved(data);
        }
        const initData: WhyChooseUsSearchLinkForm = JSON.parse(data.contents);
        // reset(initData);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: WhyChooseUsSearchLinkForm = JSON.parse(
            dataSearchLinkSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              images: dataSaved.images,
              urlButton: initData.urlButton ?? dataSaved.urlButton,
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        setKeyReset(makeid(5));
      } else if (dataSearchLinkSaved) {
        const dataSaved = JSON.parse(dataSearchLinkSaved?.contents ?? "{}");

        setDataSearchLink(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataSearchLink(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ p: 3, mb: 2 }}>
        {
           loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
           <CircularProgress />
         </Box> : <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("title")?.length > 50 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("title")?.length}/50
            </Typography>
            <TextField
              size="small"
              fullWidth
              {...register(`title`)}
              label={t("homepage_navigationMenu_whyChooseUs_searchLink_title")}
              InputLabelProps={{ shrink: true }}
              error={errors?.title !== undefined}
              helperText={
                errors?.title !== undefined ? validationMessage.required : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              key={keyReset}
              control={control}
              name="images"
              render={({ field: { onChange, onBlur, value, ref } }) => {
                return (
                  <Box sx={{ position: "relative" }}>
                    <UploaderCustom
                      page="master-setup"
                      documents={value ?? ""}
                      onChange={(data: any) => onChange(data)}
                      widthImg={""}
                      heightImg={""}
                      idBtnUpload="btn-upload"
                      type="image"
                      imageViewHeight="364px"
                      isShowRequire={errors.images !== undefined}
                    />
                    {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                      <Button
                        variant="text"
                        sx={{
                          position: "absolute",
                          top: "-5px",
                          right: "-5px",
                          color: "red",
                          zIndex: 99,
                          background: "white",
                          p: 1,
                          minWidth: "30px",
                        }}
                        onClick={() => onChange("")}
                      >
                        <Clear />
                      </Button>
                    )}
                  </Box>
                );
              }}
            />

            <Typography className="text-14 mt-5">
              {t("fileUpload_size")}: 630 X 364 {t("fileUpload_unit")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12} md={6}>
                <Stack direction={"row"} spacing={3}>
                  <Button variant="contained" className="h-45">
                    {t(
                      "homepage_navigationMenu_whyChooseUs_searchLink_buttonContent"
                    )}
                  </Button>
                  <TextField
                    size="small"
                    fullWidth
                    {...register(`urlButton`)}
                    label={t(
                      "homepage_navigationMenu_whyChooseUs_searchLink_buttonURL"
                    )}
                    InputLabelProps={{ shrink: true }}
                    placeholder="https://tweettour.com/"
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        }
       
      </Paper>

      <Stack className="mt-30" alignItems={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
  );
};

export default SearchLink;
