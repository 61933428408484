import moment from "moment";
import "moment-timezone";
import { LanguageGroupItem, LanguageItem } from "../interfaces/contentLanguage";

export const formatDateTime = (date: string, hasHour: boolean = false) => {
  return hasHour
    ? moment(date).format("DD-MMM-YYYY h:mm")
    : moment(date).format("DD-MMM-YYYY");
};

export const formatDateTimeDay = (date: string, hasHour: boolean = false) => {
  return hasHour
    ? moment(date).format("dddd, DD-MMM-YYYY h:mm")
    : moment(date).format("dddd, DD-MMM-YYYY");
};

export const convertUTCtoLocalTime = (
  date: string,
  format: string = "DD-MMM-YYYY HH:mm"
) => {
  return moment
    .utc(date)
    .tz(
      localStorage.getItem("timeZone") ??
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )
    .format(format);
};

export const priceFormat = (price: number) => {
  return new Intl.NumberFormat("en-IN").format(price);
};

export const getReservationType = (key?: string) => {
  if (key) return key == "QUOTE" ? "QUOTATION" : "BOOKING";
  return "";
};

export const getCurrentDate = () => {
  return moment()
    .tz(localStorage.getItem("timeZone") ?? "UTC")
    .format("YYYY-MM-DD");
};
export const makeSlug = (str: string) => {
  str = str
    .replace(/[`~!@#$%^&*()_\-+=\[\]{};:'"\\|\/,.<>?\s]/g, " ")
    .toLowerCase();

  str = str.replace(/^\s+|\s+$/gm, "");

  str = str.replace(/\s+/g, "-");

  return str;
};

export const makeCamelCase = (str: string) => {
  // converting all characters to lowercase
  let ans = str.toLowerCase();

  // Returning string to camelcase
  return ans
    .split(" ")
    .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export function makeTitle(slug: string) {
  var words = slug.split("_");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ").replace(/&amp;/g, "&");
}

export function buildQueryString(object: any) {
  if (typeof object !== "object") return "";
  const args: any = [];
  for (const key in object) {
    destructure(key, object[key]);
  }
  return args.join("&");

  function destructure(key: any, value: any) {
    if (key && (value || value === false || value === 0)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else if (String(value) === "[object Object]") {
        for (const i in value) {
          destructure(key + "[" + i + "]", value[i]);
        }
      } else
        args.push(
          encodeURIComponent(key) +
            (value != null && value !== "" && value !== undefined
              ? "=" + encodeURIComponent(value)
              : "")
        );
    }
  }
}

// export function parseParams(queryString: string = "", options: any = {}) {
//   let queryObject: any = {};
//   queryString && decodeURIComponent(queryString.replace('?', '')).split('&').map((itemString) => {
//     let [itemKey, itemValue] = itemString.split("=");
//     if (options.hasOwnProperty(itemKey)) {
//       if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
//         queryObject[itemKey] = [];
//       }
//       Array.isArray(options[itemKey]) ? queryObject[itemKey].push(itemValue) : queryObject[itemKey] = typeof options[itemKey] === "number" ? parseInt(itemValue) : itemValue;}
//   });
//   return queryObject;
// };
export const parseParams = (querystring: string) => {
  const params: any = new URLSearchParams(querystring);
  const obj: any = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      if (params.get(key) !== "undefined") obj[key] = params.getAll(key);
    } else {
      if (params.get(key) !== "undefined") obj[key] = params.get(key);
    }
  }

  return obj;
};

export function getProductNameByType(type: string) {
  switch (type) {
    case "tour":
      return "TOUR";
    case "golf":
      return "GOLF PACKAGE";
    default:
      return "UNDEFINED";
  }
}

export function getValueFromCapitalizeString(string: string) {
  return string
    .replaceAll("_", " ")
    .trim()
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
}

export const getValueString = (data: string | undefined | null) => {
  return data === null || data === undefined ? "" : data;
};

export const getArrStringFromArrContent = (
  data: { content?: string | null }[] | null | undefined
) => {
  return data
    ? data
        .map((item) => {
          return item.content && item.content != "false" ? item.content : "";
        })
        .filter((item) => item)
    : [];
};

export function makeid(length: number) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function getDeadlineByCol(colId: string) {
  let timeZone = localStorage.getItem("timeZone");
  if (timeZone) {
    let now = new Date();
    let date = null;
    switch (colId) {
      case "1":
        date = moment.utc(now).tz(timeZone).subtract(1, "days").format("YYYY-MM-DD");
        break;
      case "2":
        date = moment.utc(now).tz(timeZone).format("YYYY-MM-DD");
        break;
      case "3":
        date = moment.utc(now).tz(timeZone).endOf("week").format("YYYY-MM-DD")
        break;
      case "4":
        date = moment.utc(now).tz(timeZone).endOf("week").add(7, 'days').format("YYYY-MM-DD")
        break;
      case "5":
        date = moment.utc(now).tz(timeZone).endOf("week").add(14, 'days').format("YYYY-MM-DD")
        break;
      default: 
        date = null;
        break;
    }
    return date;
  }
}

export function toNested(data: LanguageItem[], parentId = null) {
  return data.reduce((r: LanguageItem[], e: any) => {
    if (parentId === e.parentId) {
      const object = { ...e };
      const children = toNested(data, e.id);

      if (children.length) {
        object.children = children;
      }

      r.push(object);
    }

    return r;
  }, []);
}
export function toNestedLangGroup(data: LanguageGroupItem[], parentId = null) {
  return data.reduce((r: LanguageGroupItem[], e: any) => {
    if (parentId === e.parentId) {
      const object = { ...e };
      const children = toNestedLangGroup(data, e.id);

      if (children.length) {
        object.children = children;
      }

      r.push(object);
    }

    return r;
  }, []);
}