export default {
  priority: ['HIGH', 'MEDIUM', 'LOW'],
  headingList: ["heading 1", "heading 2", "heading 3", "heading 4"],
  statusTask: ['PENDING', 'INPROGRESS'],
  linkToFeature: ['CRM', 'Reservation', 'Products'],
  reservation: ['Booking', 'Quotation'],
  crm: ['Contact', 'Company'],
  product: ['Tour', 'Golf'],

  inspirationType: [{ label: "Inspiration", value: "inspiration" }, { label: "More Information", value: "more_information" }, { label: "Press", value: "press" },{ label: "Other", value: "other" }],
  termType: [
    { label: "Global Term", value: "global-term" },
    { label: "Term for Passenger", value: "term-for-passenger" }, 
  ],
  policyType: [
    { label: "Global Privacy Policy", value: "global-privacy-policy" },
    { label: "Privacy Policy for Passenger", value: "privacy-policy-for-passenger" }, 
  ],
  cookieType: [
    { label: "Global Cookie Policy", value: "global-cookie-policy" },
    { label: "Cookie Policy for Passenger", value: "cookie-policy-for-passenger" }, 
  ],
  tourType: [
    { label: "Luxury Tours", value: "Luxury Tours" },
    { label: "Discovery Tours", value: "Discovery Tours" },
    { label: "Classic Tours", value: "Classic Tours" },
    { label: "Adventure Tours", value: "Adventure Tours" },
    { label: "Private Tours", value: "Private Tours" },
    { label: "Family Tours", value: "Family Tours" },
    { label: "Independent Tours", value: "Independent Tours" },
    { label: "Luxury Holidays", value: "Luxury Holidays" },
    { label: "Wellness Tours", value: "Wellness Tours" },
    { label: "Spa & Wellness", value: "Spa & Wellness" },
    { label: "Yoga Tours", value: "Yoga Tours" },
    { label: "Romance & Honeymoon", value: "Romance & Honeymoon" },
    { label: "Wildlife & Safari Tours", value: "Wildlife & Safari Tours" },
    { label: "Motorbike & Bicycle Tours", value: "Motorbike & Bicycle Tours" },
    { label: "Multi-Country Tours", value: "Multi-Country Tours" },
    { label: "Small Group Tours", value: "Small Group Tours" },
    { label: "Escorted Tours", value: "Escorted Tours" },
    { label: "Battlefield Tours", value: "Battlefield Tours" },
    { label: "School Tours", value: "School Tours" }
  ],

  titles: [
    { label: "Mr", value: "Mr" },
    { label: "Mrs", value: "Mrs" },
    { label: "Ms", value: "Ms" },
    { label: "Mstr", value: "Mstr" },
    { label: "Miss", value: "Miss" },
    { label: "Dr", value: "Dr" },
    { label: "Prof", value: "Prof" },
    { label: "Sir", value: "Sir" },
    { label: "Lady", value: "Lady" },
    { label: "Other", value: "Other" },
    // { label: "Infant", value: "Infant" },
  ],

  countries: [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'France' },
    { code: 'es', label: 'Spain' },
    { code: 'cn', label: 'China' }
  ],

  languages: [
    { value: 'en', label: 'English' },
    { value: 'fr', label: 'Français' },
    { value: 'cn', label: '中国' }
  ],

  listCountry: [
    {
      label: "Afghanistan",
      value: "+93",
      code: "AF",
    },
    {
      label: "Aland Islands",
      value: "+358",
      code: "AX",
    },
    {
      label: "Albania",
      value: "+355",
      code: "AL",
    },
    {
      label: "Algeria",
      value: "+213",
      code: "DZ",
    },
    {
      label: "AmericanSamoa",
      value: "+1684",
      code: "AS",
    },
    {
      label: "Andorra",
      value: "+376",
      code: "AD",
    },
    {
      label: "Angola",
      value: "+244",
      code: "AO",
    },
    {
      label: "Anguilla",
      value: "+1264",
      code: "AI",
    },
    {
      label: "Antarctica",
      value: "+672",
      code: "AQ",
    },
    {
      label: "Antigua and Barbuda",
      value: "+1268",
      code: "AG",
    },
    {
      label: "Argentina",
      value: "+54",
      code: "AR",
    },
    {
      label: "Armenia",
      value: "+374",
      code: "AM",
    },
    {
      label: "Aruba",
      value: "+297",
      code: "AW",
    },
    {
      label: "Australia",
      value: "+61",
      code: "AU",
    },
    {
      label: "Austria",
      value: "+43",
      code: "AT",
    },
    {
      label: "Azerbaijan",
      value: "+994",
      code: "AZ",
    },
    {
      label: "Bahamas",
      value: "+1242",
      code: "BS",
    },
    {
      label: "Bahrain",
      value: "+973",
      code: "BH",
    },
    {
      label: "Bangladesh",
      value: "+880",
      code: "BD",
    },
    {
      label: "Barbados",
      value: "+1246",
      code: "BB",
    },
    {
      label: "Belarus",
      value: "+375",
      code: "BY",
    },
    {
      label: "Belgium",
      value: "+32",
      code: "BE",
    },
    {
      label: "Belize",
      value: "+501",
      code: "BZ",
    },
    {
      label: "Benin",
      value: "+229",
      code: "BJ",
    },
    {
      label: "Bermuda",
      value: "+1441",
      code: "BM",
    },
    {
      label: "Bhutan",
      value: "+975",
      code: "BT",
    },
    {
      label: "Bolivia, Plurinational State of",
      value: "+591",
      code: "BO",
    },
    {
      label: "Bosnia and Herzegovina",
      value: "+387",
      code: "BA",
    },
    {
      label: "Botswana",
      value: "+267",
      code: "BW",
    },
    {
      label: "Brazil",
      value: "+55",
      code: "BR",
    },
    {
      label: "British Indian Ocean Territory",
      value: "+246",
      code: "IO",
    },
    {
      label: "Brunei Darussalam",
      value: "+673",
      code: "BN",
    },
    {
      label: "Bulgaria",
      value: "+359",
      code: "BG",
    },
    {
      label: "Burkina Faso",
      value: "+226",
      code: "BF",
    },
    {
      label: "Burundi",
      value: "+257",
      code: "BI",
    },
    {
      label: "Cambodia",
      value: "+855",
      code: "KH",
    },
    {
      label: "Cameroon",
      value: "+237",
      code: "CM",
    },
    {
      label: "Canada",
      value: "+1",
      code: "CA",
    },
    {
      label: "Cape Verde",
      value: "+238",
      code: "CV",
    },
    {
      label: "Cayman Islands",
      value: "+ 345",
      code: "KY",
    },
    {
      label: "Central African Republic",
      value: "+236",
      code: "CF",
    },
    {
      label: "Chad",
      value: "+235",
      code: "TD",
    },
    {
      label: "Chile",
      value: "+56",
      code: "CL",
    },
    {
      label: "China",
      value: "+86",
      code: "CN",
    },
    {
      label: "Christmas Island",
      value: "+61",
      code: "CX",
    },
    {
      label: "Cocos (Keeling) Islands",
      value: "+61",
      code: "CC",
    },
    {
      label: "Colombia",
      value: "+57",
      code: "CO",
    },
    {
      label: "Comoros",
      value: "+269",
      code: "KM",
    },
    {
      label: "Congo",
      value: "+242",
      code: "CG",
    },
    {
      label: "Congo, The Democratic Republic of the Congo",
      value: "+243",
      code: "CD",
    },
    {
      label: "Cook Islands",
      value: "+682",
      code: "CK",
    },
    {
      label: "Costa Rica",
      value: "+506",
      code: "CR",
    },
    {
      label: "Cote d'Ivoire",
      value: "+225",
      code: "CI",
    },
    {
      label: "Croatia",
      value: "+385",
      code: "HR",
    },
    {
      label: "Cuba",
      value: "+53",
      code: "CU",
    },
    {
      label: "Cyprus",
      value: "+357",
      code: "CY",
    },
    {
      label: "Czech Republic",
      value: "+420",
      code: "CZ",
    },
    {
      label: "Denmark",
      value: "+45",
      code: "DK",
    },
    {
      label: "Djibouti",
      value: "+253",
      code: "DJ",
    },
    {
      label: "Dominica",
      value: "+1767",
      code: "DM",
    },
    {
      label: "Dominican Republic",
      value: "+1849",
      code: "DO",
    },
    {
      label: "Ecuador",
      value: "+593",
      code: "EC",
    },
    {
      label: "Egypt",
      value: "+20",
      code: "EG",
    },
    {
      label: "El Salvador",
      value: "+503",
      code: "SV",
    },
    {
      label: "Equatorial Guinea",
      value: "+240",
      code: "GQ",
    },
    {
      label: "Eritrea",
      value: "+291",
      code: "ER",
    },
    {
      label: "Estonia",
      value: "+372",
      code: "EE",
    },
    {
      label: "Ethiopia",
      value: "+251",
      code: "ET",
    },
    {
      label: "Falkland Islands (Malvinas)",
      value: "+500",
      code: "FK",
    },
    {
      label: "Faroe Islands",
      value: "+298",
      code: "FO",
    },
    {
      label: "Fiji",
      value: "+679",
      code: "FJ",
    },
    {
      label: "Finland",
      value: "+358",
      code: "FI",
    },
    {
      label: "France",
      value: "+33",
      code: "FR",
    },
    {
      label: "French Guiana",
      value: "+594",
      code: "GF",
    },
    {
      label: "French Polynesia",
      value: "+689",
      code: "PF",
    },
    {
      label: "Gabon",
      value: "+241",
      code: "GA",
    },
    {
      label: "Gambia",
      value: "+220",
      code: "GM",
    },
    {
      label: "Georgia",
      value: "+995",
      code: "GE",
    },
    {
      label: "Germany",
      value: "+49",
      code: "DE",
    },
    {
      label: "Ghana",
      value: "+233",
      code: "GH",
    },
    {
      label: "Gibraltar",
      value: "+350",
      code: "GI",
    },
    {
      label: "Greece",
      value: "+30",
      code: "GR",
    },
    {
      label: "Greenland",
      value: "+299",
      code: "GL",
    },
    {
      label: "Grenada",
      value: "+1473",
      code: "GD",
    },
    {
      label: "Guadeloupe",
      value: "+590",
      code: "GP",
    },
    {
      label: "Guam",
      value: "+1671",
      code: "GU",
    },
    {
      label: "Guatemala",
      value: "+502",
      code: "GT",
    },
    {
      label: "Guernsey",
      value: "+44",
      code: "GG",
    },
    {
      label: "Guinea",
      value: "+224",
      code: "GN",
    },
    {
      label: "Guinea-Bissau",
      value: "+245",
      code: "GW",
    },
    {
      label: "Guyana",
      value: "+595",
      code: "GY",
    },
    {
      label: "Haiti",
      value: "+509",
      code: "HT",
    },
    {
      label: "Holy See (Vatican City State)",
      value: "+379",
      code: "VA",
    },
    {
      label: "Honduras",
      value: "+504",
      code: "HN",
    },
    {
      label: "Hong Kong",
      value: "+852",
      code: "HK",
    },
    {
      label: "Hungary",
      value: "+36",
      code: "HU",
    },
    {
      label: "Iceland",
      value: "+354",
      code: "IS",
    },
    {
      label: "India",
      value: "+91",
      code: "IN",
    },
    {
      label: "Indonesia",
      value: "+62",
      code: "ID",
    },
    {
      label: "Iran, Islamic Republic of Persian Gulf",
      value: "+98",
      code: "IR",
    },
    {
      label: "Iraq",
      value: "+964",
      code: "IQ",
    },
    {
      label: "Ireland",
      value: "+353",
      code: "IE",
    },
    {
      label: "Isle of Man",
      value: "+44",
      code: "IM",
    },
    {
      label: "Israel",
      value: "+972",
      code: "IL",
    },
    {
      label: "Italy",
      value: "+39",
      code: "IT",
    },
    {
      label: "Jamaica",
      value: "+1876",
      code: "JM",
    },
    {
      label: "Japan",
      value: "+81",
      code: "JP",
    },
    {
      label: "Jersey",
      value: "+44",
      code: "JE",
    },
    {
      label: "Jordan",
      value: "+962",
      code: "JO",
    },
    {
      label: "Kazakhstan",
      value: "+77",
      code: "KZ",
    },
    {
      label: "Kenya",
      value: "+254",
      code: "KE",
    },
    {
      label: "Kiribati",
      value: "+686",
      code: "KI",
    },
    {
      label: "Korea, Democratic People's Republic of Korea",
      value: "+850",
      code: "KP",
    },
    {
      label: "South Korea",
      value: "+82",
      code: "KR",
    },
    {
      label: "Kuwait",
      value: "+965",
      code: "KW",
    },
    {
      label: "Kyrgyzstan",
      value: "+996",
      code: "KG",
    },
    {
      label: "Laos",
      value: "+856",
      code: "LA",
    },
    {
      label: "Latvia",
      value: "+371",
      code: "LV",
    },
    {
      label: "Lebanon",
      value: "+961",
      code: "LB",
    },
    {
      label: "Lesotho",
      value: "+266",
      code: "LS",
    },
    {
      label: "Liberia",
      value: "+231",
      code: "LR",
    },
    {
      label: "Libyan Arab Jamahiriya",
      value: "+218",
      code: "LY",
    },
    {
      label: "Liechtenstein",
      value: "+423",
      code: "LI",
    },
    {
      label: "Lithuania",
      value: "+370",
      code: "LT",
    },
    {
      label: "Luxembourg",
      value: "+352",
      code: "LU",
    },
    {
      label: "Macao",
      value: "+853",
      code: "MO",
    },
    {
      label: "Macedonia",
      value: "+389",
      code: "MK",
    },
    {
      label: "Madagascar",
      value: "+261",
      code: "MG",
    },
    {
      label: "Malawi",
      value: "+265",
      code: "MW",
    },
    {
      label: "Malaysia",
      value: "+60",
      code: "MY",
    },
    {
      label: "Maldives",
      value: "+960",
      code: "MV",
    },
    {
      label: "Mali",
      value: "+223",
      code: "ML",
    },
    {
      label: "Malta",
      value: "+356",
      code: "MT",
    },
    {
      label: "Marshall Islands",
      value: "+692",
      code: "MH",
    },
    {
      label: "Martinique",
      value: "+596",
      code: "MQ",
    },
    {
      label: "Mauritania",
      value: "+222",
      code: "MR",
    },
    {
      label: "Mauritius",
      value: "+230",
      code: "MU",
    },
    {
      label: "Mayotte",
      value: "+262",
      code: "YT",
    },
    {
      label: "Mexico",
      value: "+52",
      code: "MX",
    },
    {
      label: "Micronesia, Federated States of Micronesia",
      value: "+691",
      code: "FM",
    },
    {
      label: "Moldova",
      value: "+373",
      code: "MD",
    },
    {
      label: "Monaco",
      value: "+377",
      code: "MC",
    },
    {
      label: "Mongolia",
      value: "+976",
      code: "MN",
    },
    {
      label: "Montenegro",
      value: "+382",
      code: "ME",
    },
    {
      label: "Montserrat",
      value: "+1664",
      code: "MS",
    },
    {
      label: "Morocco",
      value: "+212",
      code: "MA",
    },
    {
      label: "Mozambique",
      value: "+258",
      code: "MZ",
    },
    {
      label: "Myanmar",
      value: "+95",
      code: "MM",
    },
    {
      label: "Namibia",
      value: "+264",
      code: "NA",
    },
    {
      label: "Nauru",
      value: "+674",
      code: "NR",
    },
    {
      label: "Nepal",
      value: "+977",
      code: "NP",
    },
    {
      label: "Netherlands",
      value: "+31",
      code: "NL",
    },
    {
      label: "Netherlands Antilles",
      value: "+599",
      code: "AN",
    },
    {
      label: "New Caledonia",
      value: "+687",
      code: "NC",
    },
    {
      label: "New Zealand",
      value: "+64",
      code: "NZ",
    },
    {
      label: "Nicaragua",
      value: "+505",
      code: "NI",
    },
    {
      label: "Niger",
      value: "+227",
      code: "NE",
    },
    {
      label: "Nigeria",
      value: "+234",
      code: "NG",
    },
    {
      label: "Niue",
      value: "+683",
      code: "NU",
    },
    {
      label: "Norfolk Island",
      value: "+672",
      code: "NF",
    },
    {
      label: "Northern Mariana Islands",
      value: "+1670",
      code: "MP",
    },
    {
      label: "Norway",
      value: "+47",
      code: "NO",
    },
    {
      label: "Oman",
      value: "+968",
      code: "OM",
    },
    {
      label: "Pakistan",
      value: "+92",
      code: "PK",
    },
    {
      label: "Palau",
      value: "+680",
      code: "PW",
    },
    {
      label: "Palestinian Territory, Occupied",
      value: "+970",
      code: "PS",
    },
    {
      label: "Panama",
      value: "+507",
      code: "PA",
    },
    {
      label: "Papua New Guinea",
      value: "+675",
      code: "PG",
    },
    {
      label: "Paraguay",
      value: "+595",
      code: "PY",
    },
    {
      label: "Peru",
      value: "+51",
      code: "PE",
    },
    {
      label: "Philippines",
      value: "+63",
      code: "PH",
    },
    {
      label: "Pitcairn",
      value: "+872",
      code: "PN",
    },
    {
      label: "Poland",
      value: "+48",
      code: "PL",
    },
    {
      label: "Portugal",
      value: "+351",
      code: "PT",
    },
    {
      label: "Puerto Rico",
      value: "+1939",
      code: "PR",
    },
    {
      label: "Qatar",
      value: "+974",
      code: "QA",
    },
    {
      label: "Romania",
      value: "+40",
      code: "RO",
    },
    {
      label: "Russia",
      value: "+7",
      code: "RU",
    },
    {
      label: "Rwanda",
      value: "+250",
      code: "RW",
    },
    {
      label: "Reunion",
      value: "+262",
      code: "RE",
    },
    {
      label: "Saint Barthelemy",
      value: "+590",
      code: "BL",
    },
    {
      label: "Saint Helena, Ascension and Tristan Da Cunha",
      value: "+290",
      code: "SH",
    },
    {
      label: "Saint Kitts and Nevis",
      value: "+1869",
      code: "KN",
    },
    {
      label: "Saint Lucia",
      value: "+1758",
      code: "LC",
    },
    {
      label: "Saint Martin",
      value: "+590",
      code: "MF",
    },
    {
      label: "Saint Pierre and Miquelon",
      value: "+508",
      code: "PM",
    },
    {
      label: "Saint Vincent and the Grenadines",
      value: "+1784",
      code: "VC",
    },
    {
      label: "Samoa",
      value: "+685",
      code: "WS",
    },
    {
      label: "San Marino",
      value: "+378",
      code: "SM",
    },
    {
      label: "Sao Tome and Principe",
      value: "+239",
      code: "ST",
    },
    {
      label: "Saudi Arabia",
      value: "+966",
      code: "SA",
    },
    {
      label: "Senegal",
      value: "+221",
      code: "SN",
    },
    {
      label: "Serbia",
      value: "+381",
      code: "RS",
    },
    {
      label: "Seychelles",
      value: "+248",
      code: "SC",
    },
    {
      label: "Sierra Leone",
      value: "+232",
      code: "SL",
    },
    {
      label: "Singapore",
      value: "+65",
      code: "SG",
    },
    {
      label: "Slovakia",
      value: "+421",
      code: "SK",
    },
    {
      label: "Slovenia",
      value: "+386",
      code: "SI",
    },
    {
      label: "Solomon Islands",
      value: "+677",
      code: "SB",
    },
    {
      label: "Somalia",
      value: "+252",
      code: "SO",
    },
    {
      label: "South Africa",
      value: "+27",
      code: "ZA",
    },
    {
      label: "South Sudan",
      value: "+211",
      code: "SS",
    },
    {
      label: "South Georgia and the South Sandwich Islands",
      value: "+500",
      code: "GS",
    },
    {
      label: "Spain",
      value: "+34",
      code: "ES",
    },
    {
      label: "Sri Lanka",
      value: "+94",
      code: "LK",
    },
    {
      label: "Sudan",
      value: "+249",
      code: "SD",
    },
    {
      label: "Suriname",
      value: "+597",
      code: "SR",
    },
    {
      label: "Svalbard and Jan Mayen",
      value: "+47",
      code: "SJ",
    },
    {
      label: "Swaziland",
      value: "+268",
      code: "SZ",
    },
    {
      label: "Sweden",
      value: "+46",
      code: "SE",
    },
    {
      label: "Switzerland",
      value: "+41",
      code: "CH",
    },
    {
      label: "Syrian Arab Republic",
      value: "+963",
      code: "SY",
    },
    {
      label: "Taiwan",
      value: "+886",
      code: "TW",
    },
    {
      label: "Tajikistan",
      value: "+992",
      code: "TJ",
    },
    {
      label: "Tanzania, United Republic of Tanzania",
      value: "+255",
      code: "TZ",
    },
    {
      label: "Thailand",
      value: "+66",
      code: "TH",
    },
    {
      label: "Timor-Leste",
      value: "+670",
      code: "TL",
    },
    {
      label: "Togo",
      value: "+228",
      code: "TG",
    },
    {
      label: "Tokelau",
      value: "+690",
      code: "TK",
    },
    {
      label: "Tonga",
      value: "+676",
      code: "TO",
    },
    {
      label: "Trinidad and Tobago",
      value: "+1868",
      code: "TT",
    },
    {
      label: "Tunisia",
      value: "+216",
      code: "TN",
    },
    {
      label: "Turkey",
      value: "+90",
      code: "TR",
    },
    {
      label: "Turkmenistan",
      value: "+993",
      code: "TM",
    },
    {
      label: "Turks and Caicos Islands",
      value: "+1649",
      code: "TC",
    },
    {
      label: "Tuvalu",
      value: "+688",
      code: "TV",
    },
    {
      label: "Uganda",
      value: "+256",
      code: "UG",
    },
    {
      label: "Ukraine",
      value: "+380",
      code: "UA",
    },
    {
      label: "United Arab Emirates",
      value: "+971",
      code: "AE",
    },
    {
      label: "United Kingdom",
      value: "+44",
      code: "GB",
    },
    {
      label: "United States",
      value: "+1",
      code: "US",
    },
    {
      label: "Uruguay",
      value: "+598",
      code: "UY",
    },
    {
      label: "Uzbekistan",
      value: "+998",
      code: "UZ",
    },
    {
      label: "Vanuatu",
      value: "+678",
      code: "VU",
    },
    {
      label: "Venezuela, Bolivarian Republic of Venezuela",
      value: "+58",
      code: "VE",
    },
    {
      label: "Vietnam",
      value: "+84",
      code: "VN",
    },
    {
      label: "Virgin Islands, British",
      value: "+1284",
      code: "VG",
    },
    {
      label: "Virgin Islands, U.S.",
      value: "+1340",
      code: "VI",
    },
    {
      label: "Wallis and Futuna",
      value: "+681",
      code: "WF",
    },
    {
      label: "Yemen",
      value: "+967",
      code: "YE",
    },
    {
      label: "Zambia",
      value: "+260",
      code: "ZM",
    },
    {
      label: "Zimbabwe",
      value: "+263",
      code: "ZW",
    },
  ],

} 