import i18t from "i18next";
import { initReactI18next } from "react-i18next";

const dataLang = JSON.parse(localStorage.getItem("lang") ?? "{}");
const resources = {
  en: {
    translation: Object.assign({}, ...((dataLang && dataLang?.en) ? (dataLang.en ?? []).map((item: any) => ({ [item.section]: item.content })) : []))},
  fr: {
    translation: Object.assign({}, ...((dataLang && dataLang?.fr) ? (dataLang.fr ?? []).map((item: any) => ({ [item.section]: item.content })) : []))},
  cn: {
    translation: Object.assign({}, ...((dataLang && dataLang?.cn) ? (dataLang.cn ?? []).map((item: any) => ({ [item.section]: item.content })) : []))
  },
};

i18t.use(initReactI18next).init({
  lng: "en",
  debug: false,
  resources,
});

export default i18t;
