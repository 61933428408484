import { Add, Clear, Close, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { makeid } from "../../../utils";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { CustomerReviewItem, HandleCustomerReview, ItemData, PayloadHeroSection } from "../../../interfaces/homePage";
import homePageService from "../../../services/homePageService";
import inspirationService from "../../../services/inspirationService";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import data from "../../../mockdata/data";
import validationMessage from "../../../constants/validationMessage";
import ContentComponent from "../../../components/Editor/ContentComponent";
import customerReviewService from "../../../services/customerReviewService";

const validationSchema = yup.object().shape({
  keyLang: yup.string().required("Field Required"),
        fullname: yup.string().required("Field Required"),
        avatar: yup.string().nullable().transform((value) => (value === undefined ? null : value)),
        country: yup.string().required("Field Required"),
        flag: yup.string().required("Field Required"),
        review: yup.string().required("Field Required"),
});



const CustomerReviewForm = () => {
  const defaultData = {
  keyLang: `CUSTOMER_REVIEW_${makeid(10)}`,
      fullname: "",
      avatar: "",
      country: "",
      flag: "",
      review: "",
};
  const { t, i18n } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataCustomerReview, setDataCustomerReview] = useState<CustomerReviewItem | null>(
    null
  );
  const [dataCustomerReviewSaved, setDataCustomerReviewSaved] =
    useState<CustomerReviewItem | null>(null);
  const { language, setLanguage } = useGlobalContext();
  const { id, keyLang } = useParams();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<HandleCustomerReview>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  console.log(language);
  
  const submit: SubmitHandler<HandleCustomerReview> = async (data) => {
    // console.log(data);
    const payload = {
      ...data,
      language: language ?? "en",
      avatar: String(data.avatar)
    };
    console.log(payload);
    
    setLoadingBtn(true);
    if (dataCustomerReview && dataCustomerReview?.id) {
      const response = await customerReviewService.update(
        payload, dataCustomerReview.id
      );
      if (response?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `Customer Review has been updated`,
          showConfirmButton: false,
          timer: 1500,
        });
        fetchDataDetail(response.data);
      }
    } else {
      try {
        const response = await customerReviewService.store(payload);
        if (response?.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `Customer Review has been created`,
            showConfirmButton: false,
            timer: 1500,
          });
          fetchDataDetail(response.data);
        }
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message ?? "Something Went Wrong",
        });
      }
    }

    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<HandleCustomerReview> = (errors) => {
    console.log(errors);
  };

  const fetchDataDetail = async (keyLang: string) => {
    setLoading(true);
    const response = await customerReviewService.findDetail(
      language,
      String(keyLang)
    );

    if (response?.status === 200) {
      const data = response.data;
      if (data) {
        setDataCustomerReview(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataCustomerReviewSaved(data);
        }
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(data);
        } else {
          if (dataCustomerReviewSaved) {
            const dataForm: HandleCustomerReview = {
              ...dataCustomerReviewSaved,
              review: data.review ?? dataCustomerReviewSaved.review,
            };
            reset(dataForm);
          } else {
            reset(data);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataCustomerReviewSaved) {
        setDataCustomerReview(null);
        reset(dataCustomerReviewSaved);

        setKeyReset(makeid(5));
      } else {
        setDataCustomerReview(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keyLang) {
      fetchDataDetail(String(keyLang));
    } else {
      setDataCustomerReview(null);
      reset(defaultData);
      setKeyReset(makeid(5));
    }
  }, [keyLang, language]);

  return (
    <Box sx={{ background: "#ddd", p: 2 }}>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2, my: 2 }}>
         <form onSubmit={handleSubmit(submit, error)}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Grid container spacing={2}>
                <Grid item md={6}>
                  <Box
                    sx={{
                      boxShadow: 1,
                      p: 2,
                      borderRadius: 2,
                      position: "relative",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={5}>
                        <Controller
                          control={control}
                          name={`avatar`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <Box sx={{ position: "relative" }}>
                                <UploaderCustom
                                  page="customer-review"
                                  type="image"
                                  documents={value ?? ""}
                                  onChange={(data: any) => onChange(data)}
                                  widthImg={65}
                                  heightImg={65}
                                  idBtnUpload="btn-upload"
                                  isShowRequire={errors?.avatar !==
                                    undefined}
                                />
                                {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                              </Box>
                            );
                          }}
                        />
                      </Grid>
                      <Grid item md={7}>
                        <Grid item md={12}>
                          <TextField
                            {...register(`fullname`)}
                            label={t("homepage_customerReview_fullNameField")}
                            size="small"
                            fullWidth
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={
                              errors?.fullname !==
                              undefined
                            }
                            helperText={
                              errors?.fullname !==
                              undefined
                                ? errors?.fullname?.message
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item md={12}>
                          <Controller
                            key={keyReset}
                            control={control}
                            name={`country`}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <>
                                  <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="countries"
                                    options={data.listCountry}
                                    getOptionLabel={(option) => option.label}
                                    value={data.listCountry.find(
                                      (item) => item.label === value
                                    )}
                                    onChange={(e, value) => {
                                      if (value) {
                                        onChange(value.label);
                                        setValue(
                                          `flag`,
                                          `https://flagcdn.com/w20/${value.code.toLowerCase()}.png`
                                        );
                                      }
                                    }}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        <img
                                          loading="lazy"
                                          width="20"
                                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                          alt=""
                                        />
                                        {option.label}
                                      </Box>
                                    )}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        label={t(
                                          "homepage_customerReview_countryField"
                                        )}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        error={
                                          errors
                                            ?.country !== undefined
                                        }
                                        helperText={
                                          errors
                                            ?.country !== undefined
                                            ? validationMessage.required
                                            : ""
                                        }
                                      />
                                    )}
                                  />
                                </>
                              );
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item md={12} xs={12}>
                        <Typography className="text-14 fw-500">
                          {" "}
                          {t(
                            "homepage_customerReview_bodyTextField"
                          )}
                        </Typography>
                        <Controller
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            return (
                              <ContentComponent data={value ?? ""} onChange={onChange} error={errors?.review !== undefined} />
                            );
                          }}
                          name={`review`}
                        />
                        <Typography
                          sx={{ fontSize: 12, p: "4px 14px 0" }}
                          className={
                            errors?.review !== undefined
                              ? "text-color-danger"
                              : ""
                          }
                        >
                          {errors?.review !== undefined
                            ? validationMessage.required
                            : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
             
            </Grid>
          </Grid>
        </Grid>

        <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
          <Button disabled={loadingBtn} type="submit" variant="contained">
            {t("saveButton")}
          </Button>
        </Box>
      </form>
        </Paper>
      )}
    </Box>
  );
};

export default CustomerReviewForm;
