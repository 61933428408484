import { Paper, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';

const ViewFaqs = () => {
  const { t } = useTranslation();

  return (
    <Paper sx={{p: 3, mb: 2}}>
        <Typography component={"h1"} className="text-32 text-center fw-600 mb-20 text-tkg-blue">{t("view_faqs_title")}</Typography>
        <Typography component={"p"} className="text-18 text-center fw-600 mb-20">{t("view_faqs_subtitle")}</Typography>
    </Paper>
  )
}

export default ViewFaqs