import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../constants/validationMessage";
import { MasterSetupResItem } from "../../../interfaces/masterSetup";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { FooterForm, ItemHero } from "../../../interfaces/homePage";
import homePageService from "../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../utils";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import { Clear, Delete } from "@mui/icons-material";
import ContentComponent from "../../../components/Editor/ContentComponent";
import FooterContentItem from "./FooterContentItem";

const validationSchema = yup.object().shape({
  followUs: yup.object().shape({
    youTubeLink: yup.string().required(validationMessage.required),
    facebookLink: yup.string().required(validationMessage.required),
    twitterLink: yup.string().required(validationMessage.required),
    instagramLink: yup.string().required(validationMessage.required),
  }),
  downloadApp: yup.object().shape({
    appName: yup.string().required(validationMessage.required),
    googlePlayLink: yup.string().required(validationMessage.required),
    appStoreLink: yup.string().required(validationMessage.required),
  }),
  footerContents: yup
    .array()
    .of(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        header: yup.string().required(validationMessage.required),
        contents: yup
          .array()
          .of(
            yup.object().shape({
              keyContent: yup.string().required(validationMessage.required),
              text: yup.string().required(validationMessage.required),
              link: yup.string().required(validationMessage.required),
              icon: yup
                .string()
                .nullable()
                .transform((value) => (value === undefined ? null : value)),
              includeIcon: yup.boolean().nullable().transform((value) =>
                value === undefined ? null : value),
            })
          )
          .required(validationMessage.required),
      })
    )
    .required(validationMessage.required),
});
const FooterPage = () => {
  const defaultData = {
    followUs: {
      youTubeLink: "",
      facebookLink: "",
      twitterLink: "",
      instagramLink: "",
    },
    downloadApp: {
      appName: "",
      googlePlayLink: "",
      appStoreLink: "",
    },
    footerContents: [
      {
        key: `HOMEPAGE_FOOTER_${makeid(8)}`,
        header: "",
        contents: [
          {
            keyContent: `HOMEPAGE_FOOTER_CONTENT_${makeid(8)}`,
            text: "",
            link: "",
            icon: "",
            includeIcon: false,
          },
        ],
      },
    ],
  };
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataFooter, setDataFooter] = useState<MasterSetupResItem | null>(null);
  const [dataFooterSaved, setDataFooterSaved] =
    useState<MasterSetupResItem | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm<FooterForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultData,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "footerContents",
  });

  const submit: SubmitHandler<FooterForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "footer",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataFooter) {
      const res = await homePageService.update(dataFooter.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Footer Content has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Footer Content has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<FooterForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    // setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "footer"
      );
      if (data) {
        setDataFooter(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataFooterSaved(data);
        }
        const initData: FooterForm = JSON.parse(data.contents ?? "{}");
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: FooterForm = JSON.parse(
            dataFooterSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              followUs: {
                youTubeLink:
                  initData.followUs?.youTubeLink ??
                  dataSaved.followUs?.youTubeLink,
                facebookLink:
                  initData.followUs?.facebookLink ??
                  dataSaved.followUs?.facebookLink,
                twitterLink:
                  initData.followUs?.twitterLink ??
                  dataSaved.followUs?.twitterLink,
                instagramLink:
                  initData.followUs?.instagramLink ??
                  dataSaved.followUs?.instagramLink,
              },
              downloadApp: {
                appName:
                  initData.downloadApp?.appName ?? dataSaved.downloadApp?.appName,
                googlePlayLink:
                  initData.downloadApp?.googlePlayLink ??
                  dataSaved.downloadApp?.googlePlayLink,
                appStoreLink:
                  initData.downloadApp?.appStoreLink ??
                  dataSaved.downloadApp?.appStoreLink,
              },
              footerContents: dataSaved.footerContents.map((item) => ({
                key: item.key,
                header:
                  initData.footerContents.find((el) => el.key === item.key)
                    ?.header ?? item.header,
                contents: item.contents.map((content) => ({
                  keyContent: content.keyContent,
                  text:
                    initData.footerContents
                      .find((el) => el.key === item.key)
                      ?.contents.find(
                        (it) => it.keyContent === content.keyContent
                      )?.text ?? content.text,
                      link: initData.footerContents
                      .find((el) => el.key === item.key)
                      ?.contents.find(
                        (it) => it.keyContent === content.keyContent
                      )?.link ?? content.link,
                  icon: content.icon,
                  includeIcon: content.includeIcon,
                })),
              })),
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataFooterSaved) {
        const dataSaved = JSON.parse(dataFooterSaved?.contents ?? "{}");

        setDataFooter(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataFooter(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    // setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <Box mt={2}>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2, mb: 2 }}>
          <form onSubmit={handleSubmit(submit, error)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Divider textAlign="left" className="mb-20">
                      <Chip color="primary" label={t("footer_followUs")} />
                    </Divider>
                    <Stack spacing={2}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <img
                          src={"/icons/fab--youtube.svg"}
                          alt="svg icon"
                          className="w-40 pb-8"
                        />
                        <TextField
                          fullWidth
                          size="small"
                          label={t("urlField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                              errors?.followUs?.youTubeLink !==
                              undefined
                            }
                            helperText={
                              errors?.followUs?.youTubeLink !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("followUs.youTubeLink")}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <img
                          src={"/icons/fab--facebook.svg"}
                          alt="svg icon"
                          className="w-40 pb-8"
                        />
                        <TextField
                          fullWidth
                          size="small"
                          label={t("urlField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                            errors?.followUs?.facebookLink !==
                              undefined
                            }
                            helperText={
                              errors?.followUs?.facebookLink !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("followUs.facebookLink")}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <img
                          src={"/icons/fab--x.svg"}
                          alt="svg icon"
                          className="w-40 pb-8"
                        />
                        <TextField
                          fullWidth
                          size="small"
                          label={t("urlField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                              errors?.followUs?.twitterLink !==
                              undefined
                            }
                            helperText={
                              errors?.followUs?.twitterLink !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("followUs.twitterLink")}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <img
                          src={"/icons/fab--instagram.svg"}
                          alt="svg icon"
                          className="w-40 pb-8"
                        />
                        <TextField
                          fullWidth
                          size="small"
                          label={t("urlField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                              errors?.followUs?.instagramLink !==
                              undefined
                            }
                            helperText={
                              errors?.followUs?.instagramLink !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("followUs.instagramLink")}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Divider textAlign="left" className="mb-20">
                      <Chip color="primary" label={t("footer_downloadTweetTripApp")} />
                    </Divider>
                    <Stack spacing={2}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          fullWidth
                          size="small"
                          label={t("appNameField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                              errors?.downloadApp?.appName !==
                              undefined
                            }
                            helperText={
                              errors?.downloadApp?.appName !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("downloadApp.appName")}
                        />
                      </Stack>
                      <Stack direction="row" spacing={2} alignItems="center">
                        
                        <TextField
                          fullWidth
                          size="small"
                          label={t("googlePlayField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                              errors?.downloadApp?.googlePlayLink !==
                              undefined
                            }
                            helperText={
                              errors?.downloadApp?.googlePlayLink !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("downloadApp.googlePlayLink")}
                        />
                      </Stack>
                     
                      <Stack direction="row" spacing={2} alignItems="center">
                        
                        <TextField
                          fullWidth
                          size="small"
                          label={t("appStroreField")}
                          InputLabelProps={{ shrink: true }}
                          error={
                              errors?.downloadApp?.appStoreLink !==
                              undefined
                            }
                            helperText={
                              errors?.downloadApp?.appStoreLink !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          {...register("downloadApp.appStoreLink")}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
              <Divider textAlign="left" className="mb-20">
                <Chip color="primary" label={t("contentField")} />
              </Divider>
              </Grid>
              {fields.map((item, index) => {
                return (
                  <Grid item xs={12} key={item.id} md={4}>
                    <Paper sx={{ p: 2 }}>
                      {language === process.env.REACT_APP_DEFAULT_LANGUAGE ? (
                        <Stack alignItems={"flex-end"}>
                          <IconButton onClick={() => remove(index)}>
                            <Delete color="error" />
                          </IconButton>
                        </Stack>
                      ) : null}
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography
                            variant="body2"
                            sx={{
                              mb: 2,
                              color:
                                watch(`footerContents.${index}.header`) &&
                                watch(`footerContents.${index}.header`).length >
                                  50
                                  ? "red"
                                  : "black",
                            }}
                          >
                            {t("checkLength")} :{" "}
                            {watch(`footerContents.${index}.header`)
                              ? watch(`footerContents.${index}.header`).length
                              : 0}
                            /50
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            label={t("titleField")}
                            {...register(`footerContents.${index}.header`)}
                            InputLabelProps={{ shrink: true }}
                            error={
                              errors?.footerContents?.[index]?.header !==
                              undefined
                            }
                            helperText={
                              errors?.footerContents?.[index]?.header !==
                              undefined
                                ? validationMessage.required
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Divider textAlign="left">
                            <Chip label={t("contentField")} />
                          </Divider>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <FooterContentItem
                            index={index}
                            keyReset={keyReset}
                            control={control}
                            watch={watch}
                            errors={errors}
                            register={register}
                            setValue={setValue}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={3}>
                {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        append({
                          key: `HOMEPAGE_FOOTER_${makeid(8)}`,
                          header: "",
                          contents: [
                            {
                              keyContent: `HOMEPAGE_FOOTER_CONTENT_${makeid(
                                8
                              )}`,
                              text: "",
                              link: "",
                              icon: "",
                              includeIcon: false,
                            },
                          ],
                        })
                      }
                    >
                      {t("addMoreButton")}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                disabled={loadingBtn}
                type="submit"
                variant="contained"
                sx={{ px: 3 }}
              >
                {t("saveButton")}
              </Button>
            </Box>
          </form>
        </Paper>
      )}
    </Box>
  );
};

export default FooterPage;
