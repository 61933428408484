import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { HandleMasterSEO, ItemSEO } from "../../interfaces/masterSeo";
import { useTranslation } from "react-i18next";
import seoService from "../../services/seoService";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../context/MyGlobalContext";

const validationSchema = yup.object().shape({
  metaTitle: yup.string().required("Field Required"),
  metaDescription: yup.string().required("Field Required"),
  metaKeyword: yup.string().required("Field Required"),
});
const MasterSeo = () => {
  const { t } = useTranslation();
  const [dataSEO, setDataSEO] = useState<ItemSEO | null>(null);
  const [dataSEOSaved, setDataSEOSaved] = useState<ItemSEO | null>(null);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();

  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<HandleMasterSEO>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<HandleMasterSEO> = async (data) => {
    const payload = {
      language: language ?? "en",
      section: "master",
      metaTitle: data.metaTitle,
      metaDescription: data.metaDescription,
      metaKeyword: data.metaKeyword,
    };

    if (dataSEO === null) {
      const res = await seoService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "SEO Master has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }
    } else {
      const res = await seoService.update(dataSEO?.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "SEO master has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        getData();
      }
    }
  };

  const getData = async () => {
    setLoading(true);
    const res = await seoService.findDetail(language ?? "en", "master");
    if (res?.status === 200) {
      if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
        setDataSEOSaved(res?.data);
      }
      setDataSEO(res?.data);
      reset(res?.data);
      setLoading(false);
    } else if (dataSEOSaved) {
      setDataSEO(null);
      reset(dataSEOSaved);
    } else {
      setDataSEO(null);
      reset({
        metaTitle: "",
        metaDescription: "",
        metaKeyword: "",
      });
    }
  };

  useEffect(() => {
    getData();
  }, [language]);

  return (
    <Paper sx={{ p: 5 }}>
      <Typography variant="h5" sx={{ mb: 5 }}>
        {t("page_header_master_seo")}
      </Typography>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch("metaTitle") && watch("metaTitle").length > 61
                      ? "red"
                      : "black",
                }}
              >
                {t("checkLength")} :{" "}
                {watch("metaTitle") ? watch("metaTitle").length : 0}/61
              </Typography>
              <TextField
                {...register("metaTitle")}
                label={t("masterSeo_metaTitle")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.metaTitle !== undefined}
                helperText={
                  errors.metaTitle !== undefined ? errors.metaTitle.message : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  color:
                    watch("metaDescription") &&
                    watch("metaDescription").length > 160
                      ? "red"
                      : "black",
                }}
              >
                {t("checkLength")} :{" "}
                {watch("metaDescription") ? watch("metaDescription").length : 0}
                /160
              </Typography>
              <TextField
                {...register("metaDescription")}
                label={t("masterSeo_metaDescription")}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.metaDescription !== undefined}
                helperText={
                  errors.metaDescription !== undefined
                    ? errors.metaDescription.message
                    : ""
                }
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                {...register("metaKeyword")}
                label={t("masterSeo_metaKeywords")}
                fullWidth
                multiline
                rows={4}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.metaKeyword !== undefined}
                helperText={
                  errors.metaKeyword !== undefined
                    ? errors.metaKeyword.message
                    : ""
                }
              />
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button type="submit" variant="contained" sx={{ px: 3 }}>
              {t("saveButton")}
            </Button>
          </Box>
        </form>
      )}
    </Paper>
  );
};

export default MasterSeo;
