import { Add, Search } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import { ItemInspiration, SearchInspiration } from "../../../../../interfaces/homePage";
import inspirationService from "../../../../../services/inspirationService";
import ContentLayout from "../../../../../components/Table/ContentLayout";
import mockForm from "../../../../../data/mockForm";
import TableHeading from "../TableHeading";
import TableItem from "../TableItem";
import dataMock from '../../../../../mockdata/data';

type Props = {
    blogType: string;
};
const InspirationList = ({ blogType }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchparams] = useSearchParams();

  const { language } = useGlobalContext();
  const [loading, setLoading] = React.useState(false);
  const [keyWord, setKeyWord] = React.useState(searchParams.get("keyWord") ? searchParams.get("keyWord") : "");
  const [country, setCountry] = React.useState(searchParams.get("country") ? searchParams.get("country") : "");
  const [type, setType] = React.useState(blogType);
  const [tourType, setTourType] = React.useState(searchParams.get("tourType") ? searchParams.get("tourType") : "");
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState<ItemInspiration[] | null>(null);
  const [page, setPage] = React.useState(Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1);
  const [perPage, setPerPage] = React.useState(Number(searchParams.get("pageSize")) ? Number(searchParams.get("pageSize")) : 1000);
  const fetchListData = async (payload: SearchInspiration) => {
    setSearchparams({
      ...(payload.tourType && { tourType: payload.tourType }),
      ...(payload.keyWord && { keyWord: payload.keyWord }),
      ...(payload.type && { type: payload.type }),
      ...(payload.country && {
        country: String(payload.country),
      }),
      page: String(payload.pageNumber),
      pageSize: String(payload.pageSize),
     });
    setLoading(true);
    const response = await inspirationService.getList(payload);

    if (response?.status === 200) {
      setData(response.data.data);
      setTotal(response.data.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData({
      language: language ?? "en",
      pageSize: perPage,
      pageNumber: page,
      ...(country && { country: String(country) }),
      ...(keyWord && { keyWord: keyWord }),
      ...(tourType && { tourType: tourType }),
      ...(type && {type: type})
    });
  }, [language, perPage, page]);


  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <ContentLayout
        isReverse={true}
        extraActions={
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
                <Box sx={{boxShadow: 1}} className="rounded-10 py-20 flex-center">
                   <Button
                   className="h-45"
                variant="contained"
                endIcon={<Add />}
                onClick={() => navigate("/homepage/navigation-menu/inspiration/store")}
              >
                {t("addNewButton")}
              </Button> 
                </Box>
              
            </Grid>
            <Grid item xs={12} md={10}>
            <Box sx={{boxShadow: 1}} className="rounded-10 pt-20 pb-12 px-20 flex-center">

              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextField
                    fullWidth
                    size="small"
                    label={t("navigationMenu_inspiration_list_filter_keyWordField")}
                    value={keyWord}
                    placeholder="Enter keywords"
                    onChange={(e) => setKeyWord(e?.target?.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    sx={{
                      ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                        padding: "16.5px 14px",
                      },
                    }}
                    size="small"
                    readOnly
                    id="type"
                    getOptionLabel={(option: any) => option.label}
                    defaultValue={type ? dataMock.inspirationType.find(item => item.value === type) : null}
                    onChange={(e, value) => {
                      if (value) {
                        setType(value.value);
                      } else {
                        setType("");
                      }
                    }}
                    options={dataMock.inspirationType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("navigationMenu_inspiration_list_filter_typeField")}
                        defaultValue={""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Select Type"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    sx={{
                      ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                        padding: "16.5px 14px",
                      },
                    }}
                    size="small"
                    id="country"
                    getOptionLabel={(option) => option.label}
                   value={country ? mockForm.countryOptions.find(item => item.label === country) : null}
                    onChange={(e, value) => {
                      if (value) {
                        setCountry(value.label);
                      } else {
                        setCountry("");
                      }
                    }}
                    options={mockForm.countryOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("navigationMenu_inspiration_list_filter_countryField")}
                        defaultValue={""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Select country"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Autocomplete
                    sx={{
                      ".MuiOutlinedInput-root .MuiAutocomplete-input": {
                        padding: "16.5px 14px",
                      },
                    }}
                    size="small"
                    id="tourType"
                    getOptionLabel={(option) => option.label}
                    value={tourType ? dataMock.tourType.find(item => item.label === tourType) : null}
                    onChange={(e, value) => {
                      if (value) {
                        setTourType(value.label);
                      } else {
                        setTourType("");
                      }
                    }}
                    options={dataMock.tourType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("navigationMenu_inspiration_list_filter_tourTypeField")}
                        defaultValue={""}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Select Tour Type"
                      />
                    )}
                  />
                </Grid>
              
                 <Grid
                        item
                        xs={12}
                        md={3}
                        className="d-flex align-center justify-center gap-16"
                      >
                        <Button
                          variant={"contained"}
                          onClick={() => {
                            fetchListData({
                              language: language ?? "en",
                              pageSize: perPage,
                              pageNumber: 1,
                              ...(country && { country: String(country) }),
                              ...(keyWord && { keyWord: keyWord }),
                              ...(tourType && { tourType: tourType }),
                              ...(type && { type: type }),
                            })
                            // setSearchparams({
                            //   ...(keyWord.length > 0 && { review: keyWord }),
                            //   ...(keyWord.length > 0 && { fullname: keyWord }),
                            //   ...(country && {
                            //     country: String(country),
                            //   }),
                            //   page: String(1),
                            //   pageSize: String(perPage),
                            //  });
                          }}
                          className="text-capitalize h-45"
                          endIcon={<Search />}
                        >
                          {t("searchButton")}
                        </Button>
                        <Button
                          variant={"contained"}
                          color="inherit"
                          onClick={() => {
                            setKeyWord("");
                            setCountry("");
                            setTourType("");
                            fetchListData({
                              language: language ?? "en",
                              pageSize: perPage,
                              pageNumber: page,
                            })
                            // setSearchparams({});
                          }}
                          className="text-capitalize h-45"
                        >
                          {t("clearButton")}
                        </Button>
                      </Grid>
              </Grid>
              </Box>
            </Grid>
          </Grid>
        }
        total={total}
        tableHeading={<TableHeading />}
        tableContent={
          loading ? (
            <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : !data ? (
            <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : data.length > 0 ? (
            data.map((e: ItemInspiration, i: number) => (
              <TableItem
                key={i}
                data={e}
                dataKey={i}
                childrenCallback={() => fetchListData({
                  language: language ?? "en",
                  pageSize: perPage,
                  pageNumber: page,
                })}
              />
            ))
          ) : (
            <TableRow className="item-hover">
              <TableCell colSpan={15} align="center">
                {t("table_data_notFound")}
              </TableCell>
            </TableRow>
          )
        }
        page={page}
        setPage={setPage}
        perPage={perPage}
        setPerPage={setPerPage}
      />
    </Paper>
  );
};

export default InspirationList;
