import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { PayloadInspiration, SearchInspiration } from "../interfaces/homePage";
import { buildQueryString } from "../utils";

const inspirationService = {

    store: async (payload: PayloadInspiration) => {
      
            return await apiInstance.post(endPointAPI.INSPIRATION.STORE, payload)

    },

    update: async (payload: PayloadInspiration, id: number) => {
        try {
            return await apiInstance.put(`${endPointAPI.INSPIRATION.UPDATE}/${id}`, payload)
        } catch (error) {
            return null
        }
    },

    getList: async (payload: SearchInspiration) => {
        try {
            // return await apiInstance.get(endPointAPI.INSPIRATION.GET_LIST + "?" + queryString)
            return await apiInstance.get(endPointAPI.INSPIRATION.GET_LIST + `?${buildQueryString(payload)}`);
        } catch (error) {
            return null
        }
    },
    getAllList: async (language: string, type: string) => {
        try {
            return await apiInstance.get(`${endPointAPI.INSPIRATION.GET_ALL_LIST}/${language}/${type}`);
        } catch (error) {
            return null
        }
    },

    findDetail: async (language: string, slug: string) => {
        try {
            return await apiInstance.get(`${endPointAPI.INSPIRATION.FIND_DETAIL}/${language}/${slug}`)
        } catch (error) {
            return null
        }
    },
    delete: async (id: number) => {
        try {
            return await apiInstance.delete(`${endPointAPI.INSPIRATION.DELETE}/${id}`)
        } catch (error) {
            return null
        }
    },


}
export default inspirationService;
