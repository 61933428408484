import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import { ItemHero } from "../../../../../interfaces/homePage";
import { WhyChooseUsWhyUsForm } from "../../../../../interfaces/navigationMenu";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";
import { Clear, Delete } from "@mui/icons-material";


const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  urlButton: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  dataLimit: yup
    .array(
      yup.object().shape({
        key: yup.string().required(validationMessage.required),
        title: yup.string().required(validationMessage.required),
        textBody: yup.string().required(validationMessage.required),
        images: yup
          .string()
          .nullable()
          .transform((value) => (value === undefined ? null : value)),
      })
    )
    .required(validationMessage.required),
});
const WhyUs = () => {
  const defaultData = {
    title: "",
    urlButton: "",
    dataLimit: [
      {
        key: `NAVIGATION_WHY_CHOOSE_US_WHY_US_${makeid(8)}`,
        title: "",
        textBody: "",
        images: "",
      },
    ],
  };
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [dataWhyUs, setDataWhyUs] = useState<ItemHero | null>(null);
  const [dataWhyUsSaved, setDataWhyUsSaved] = useState<ItemHero | null>(null);
  const [keyReset, setKeyReset] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsWhyUsForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "dataLimit",
  });
  const submit: SubmitHandler<WhyChooseUsWhyUsForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "whyus",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataWhyUs) {
      const res = await homePageService.update(dataWhyUs.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Why Us has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Why Us has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<WhyChooseUsWhyUsForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "whyus"
      );
      if (data) {
        setDataWhyUs(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataWhyUsSaved(data);
        }
        const initData: WhyChooseUsWhyUsForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: WhyChooseUsWhyUsForm = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              urlButton: initData.urlButton ?? dataSaved.urlButton,
              dataLimit: dataSaved.dataLimit.map((item) => ({
                // key: item.key,
                key: `NAVIGATION_WHY_CHOOSE_US_WHY_US_${makeid(8)}`,
                title:
                  initData.dataLimit.find((el) => el.key === item.key)?.title ??
                  item.title,
                textBody:
                  initData.dataLimit.find((el) => el.key === item.key)
                    ?.textBody ?? item.textBody,
                images:
                  initData.dataLimit.find((el) => el.key === item.key)
                    ?.images ?? item.images,
              })),
            };
            reset(dataForm);
            console.log(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: WhyChooseUsWhyUsForm = JSON.parse(
            dataWhyUsSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              urlButton: initData.urlButton ?? dataSaved.urlButton,
              dataLimit: dataSaved.dataLimit.map((item) => ({
                key: item.key,
                title:
                  initData.dataLimit.find((el) => el.key === item.key)?.title ??
                  item.title,
                textBody:
                  initData.dataLimit.find((el) => el.key === item.key)
                    ?.textBody ?? item.textBody,
                images:
                  initData.dataLimit.find((el) => el.key === item.key)
                    ?.images ?? item.images,
              })),
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataWhyUsSaved) {
        const dataSaved = JSON.parse(dataWhyUsSaved?.contents ?? "{}");
        setDataWhyUs(null);
        reset(dataSaved);
        setKeyReset(makeid(5));
      } else {
        setDataWhyUs(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit, error)}>
          <Paper sx={{ p: 3, mb: 2 }}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12}>
                <Typography
                  className="text-14 fw-600 mb-10"
                  color={watch("title")?.length > 50 ? "error" : "green"}
                >
                  {t("checkLength")}: {watch("title")?.length}/50
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  {...register(`title`)}
                  label={t("homepage_navigationMenu_whyChooseUs_whyUs_title")}
                  InputLabelProps={{ shrink: true }}
                  error={errors?.title !== undefined}
                  helperText={
                    errors?.title !== undefined
                      ? validationMessage.required
                      : ""
                  }
                />
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ p: 3, mb: 2 }}>
            <Grid container spacing={2} p={2}>
              <Grid item xs={12} md={10}>
                <TextField
                  size="small"
                  fullWidth
                  {...register(`urlButton`)}
                  label={t(
                    "homepage_navigationMenu_whyChooseUs_whyUs_buttonURL"
                  )}
                  InputLabelProps={{ shrink: true }}
                  placeholder="https://tweettour.com/"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="contained">
                  {t("homepage_navigationMenu_whyChooseUs_whyUs_buttonContent")}
                </Button>
              </Grid>
            </Grid>
          </Paper>

          <>
            <Typography className="text-24 fw-600">
              {" "}
              {t("dataLimit_length")} : 3{" "}
            </Typography>

            {fields.map((item, index) => (
              <Paper sx={{ p: 3, mb: 2 }} key={index}>
                <Grid container spacing={2} p={2}>
                  {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                    <Grid item xs={12}>
                      <Stack alignItems={"flex-end"}>
                        <Delete onClick={() => remove(index)} color="error" />
                      </Stack>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      className="text-14 fw-600 mb-10"
                      color={
                        watch(`dataLimit.${index}.title`)?.length > 35
                          ? "error"
                          : "green"
                      }
                    >
                      {t("checkLength")}:{" "}
                      {watch(`dataLimit.${index}.title`)?.length}/35
                    </Typography>

                    <TextField
                      size="small"
                      fullWidth
                      {...register(`dataLimit.${index}.title`)}
                      label={t("dataLimit_title")}
                      InputLabelProps={{ shrink: true }}
                      error={errors?.dataLimit?.[index]?.title !== undefined}
                      helperText={
                        errors?.dataLimit?.[index]?.title !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className="text-14 fw-600 mb-10"
                      color={watch("title")?.length > 90 ? "error" : "green"}
                    >
                      {t("checkLength")}: {watch("title")?.length}/90
                    </Typography>

                    <TextField
                      size="small"
                      fullWidth
                      {...register(`dataLimit.${index}.textBody`)}
                      label={t("dataLimit_textBody")}
                      InputLabelProps={{ shrink: true }}
                      error={errors?.dataLimit?.[index]?.textBody !== undefined}
                      helperText={
                        errors?.dataLimit?.[index]?.textBody !== undefined
                          ? validationMessage.required
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Controller
                      key={keyReset}
                      control={control}
                      name={`dataLimit.${index}.images`}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box sx={{ position: "relative" }}>
                            <UploaderCustom
                              page="master-setup"
                              documents={value ?? ""}
                              onChange={(data: any) => onChange(data)}
                              widthImg={""}
                              heightImg={""}
                              idBtnUpload="btn-upload"
                              type="image"
                              imageViewHeight="305px"
                              isShowRequire={errors?.dataLimit?.[index]?.images !== undefined}
                            />
                            {value &&
                              language ===
                                process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                <Button
                                  variant="text"
                                  sx={{
                                    position: "absolute",
                                    top: "-5px",
                                    right: "-5px",
                                    color: "red",
                                    zIndex: 99,
                                    background: "white",
                                    p: 1,
                                    minWidth: "30px",
                                  }}
                                  onClick={() => onChange("")}
                                >
                                  <Clear />
                                </Button>
                              )}
                          </Box>
                        );
                      }}
                    />

                    <Typography className="text-14 mt-5">
                      {t("fileUpload_size")}: 390 X 305 {t("fileUpload_unit")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    {index + 1 === fields.length &&
                      language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                        <Stack
                          className="mt-30 h-half"
                          alignItems={"flex-end"}
                          justifyContent={"flex-end"}
                        >
                          <Button
                            disabled={fields.length >= 3}
                            variant="contained"
                            onClick={() =>
                              append({
                                title: "",
                                textBody: "",
                                images: "",
                                key: `NAVIGATION_WHY_CHOOSE_US_WHY_US_${makeid(
                                  8
                                )}`,
                              })
                            }
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Stack>
                      )}
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </>

          <Stack className="mt-30" alignItems={"flex-end"}>
            <Button disabled={loadingBtn} variant="contained" type="submit">
              {t("saveButton")}
            </Button>
          </Stack>
        </form>
      )}
    </>
  );
};

export default WhyUs;
