import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { ItemHero, TermAndConditionForm } from "../../../interfaces/homePage";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import homePageService from "../../../services/homePageService";
import ContentComponent from "../../../components/Editor/ContentComponent";
import dataMock from "../../../mockdata/data";

const defaultData = {
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
  type: "",
  title: "",
  content: "",
};
const validationSchema = yup.object().shape({
  metaTitle: yup.string().required("Field Required"),
  metaDescription: yup.string().required("Field Required"),
  metaKeyword: yup.string().required("Field Required"),
  type: yup.string().required("Field Required"),
  title: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  content: yup.string().required("Field Required"),
});
const CookiePolicyPage = () => {
  const { t } = useTranslation();
  const [dataTermCondition, setDataTermCondition] = useState<ItemHero | null>(
    null
  );
  const [dataTermConditionSaved, setDataTermConditionSaved] =
    useState<ItemHero | null>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { language } = useGlobalContext();

  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<TermAndConditionForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<TermAndConditionForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "homepage",
      section: "cookie-policy",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataTermCondition) {
      const res = await homePageService.update(dataTermCondition?.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cookie Policy has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Cookie Policy has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "cookie-policy"
      );
      if (data) {
        setDataTermCondition(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataTermConditionSaved(data);
        }
        const initData = JSON.parse(data.contents ?? "{}");
        reset(initData);
      } else if (dataTermConditionSaved) {
        const dataSaved = JSON.parse(dataTermConditionSaved.contents ?? "{}");
        setDataTermCondition(null);
        reset(dataSaved);
      } else {
        setDataTermCondition(null);
        reset(defaultData);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <Box>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    {" "}
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      {t("page_header_master_seo")}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("metaTitle") && watch("metaTitle").length > 61
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("checkLength")} :{" "}
                      {watch("metaTitle") ? watch("metaTitle").length : 0}/61
                    </Typography>
                    <TextField
                      {...register("metaTitle")}
                      label={t("seo_metaTitle")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors.metaTitle !== undefined}
                      helperText={
                        errors.metaTitle !== undefined
                          ? errors.metaTitle.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("metaDescription") &&
                          watch("metaDescription").length > 160
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("checkLength")} :{" "}
                      {watch("metaDescription")
                        ? watch("metaDescription").length
                        : 0}
                      /160
                    </Typography>
                    <TextField
                      {...register("metaDescription")}
                      label={t("seo_metaDescription")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors.metaDescription !== undefined}
                      helperText={
                        errors.metaDescription !== undefined
                          ? errors.metaDescription.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextField
                      {...register("metaKeyword")}
                      label={t("seo_metaKeywords")}
                      fullWidth
                      multiline
                      rows={4}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors.metaKeyword !== undefined}
                      helperText={
                        errors.metaKeyword !== undefined
                          ? errors.metaKeyword.message
                          : ""
                      }
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper sx={{ p: 2, mb: 3 }}>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      {t("contentField")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                          <Controller
                            control={control}
                            name="type"
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => {
                              return (
                                <Autocomplete
                                  disablePortal
                                  disableClearable
                                  id="type"
                                  getOptionLabel={(option) => option.label}
                                  onChange={(e, value) => {
                                    onChange(value.value);
                                  }}
                                  value={dataMock.cookieType.find(
                                    (item) => item.value === value
                                  )}
                                  size="small"
                                  options={dataMock.cookieType}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ background: "#fff" }}
                                      {...params}
                                      label={t("navigationMenu_inspiration_list_table_header_type")}
                                      defaultValue={""}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      error={errors?.type !== undefined}
                                      helperText={
                                        errors?.type !== undefined
                                          ? errors?.type?.message
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              );
                            }}
                          />
                        </Grid>
                  <Grid item xs={12}>
                    <TextField
                      {...register("title")}
                      label={t("homePage_termAndCondition_titleField")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors.title !== undefined}
                      helperText={
                        errors.title !== undefined ? errors.title.message : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className="text-14 fw-600">
                      {t("homePage_termAndCondition_contentField")}
                    </Typography>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => {
                        return (
                          <Box
                            className={`${
                              errors?.content !== undefined
                                ? "editor-validate"
                                : ""
                            }`}
                          >
                            <ContentComponent
                              data={value}
                              onChange={onChange}
                              error={errors?.content !== undefined}
                            />
                          </Box>
                        );
                      }}
                      name={`content`}
                    />
                    <Typography
                      sx={{ fontSize: 12, p: "4px 14px 0" }}
                      className={
                        errors?.content !== undefined ? "text-color-danger" : ""
                      }
                    >
                      {errors?.content !== undefined ? "Field Required" : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            <Button
              disabled={loadingBtn}
              type="submit"
              variant="contained"
              sx={{ px: 3 }}
            >
              {t("saveButton")}
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default CookiePolicyPage;
