import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  Controller,
  FieldErrors,
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from "react-hook-form";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import { Clear, Delete } from "@mui/icons-material";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import { useTranslation } from "react-i18next";
import validationMessage from "../../../constants/validationMessage";
import { makeSlug, makeid } from "../../../utils";
import { FooterForm } from "../../../interfaces/homePage";

interface Props {
  index: number;
  idx: number;
  control: any;
  keyReset: string;
  watch: UseFormWatch<FooterForm>;
  errors: FieldErrors<FooterForm>;
  register: UseFormRegister<FooterForm>;
  setValue: UseFormSetValue<FooterForm>
  remove: UseFieldArrayRemove;
  fields: Record<"id", string>[];
  append: UseFieldArrayAppend<FieldValues, `footerContents.${number}.contents`>;
}
const ContentTextItem = ({
  index,
  idx,
  control,
  keyReset,
  watch,
  errors,
  register,
  setValue,
  fields,
  remove,
  append,
}: Props) => {
  const { language } = useGlobalContext();
  const { t } = useTranslation();
  return (
    <Grid item xs={12} key={idx}>
      <Box sx={{ p: 2 }}>
        {idx && language === process.env.REACT_APP_DEFAULT_LANGUAGE ? (
          <Stack alignItems={"flex-end"}>
            <IconButton onClick={() => remove(idx)}>
              <Delete color="error" />
            </IconButton>
          </Stack>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  key={keyReset}
                  control={control}
                  name={`footerContents.${index}.contents.${idx}.includeIcon`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (<Checkbox
                  checked={value ? value : false}
                  onChange={(e, checked) => onChange(checked)}
                />)
 }}
                />
                
              }
              label={t("footer_contentItem_includeIcon")}
            />
          </Grid>
          {watch(`footerContents.${index}.contents.${idx}.includeIcon`) && (
            <Grid item xs={12} md={6}>
              <Box pl={2} pt={2}>
                <Controller
                  key={keyReset}
                  control={control}
                  name={`footerContents.${index}.contents.${idx}.icon`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Box sx={{ position: "relative" }}>
                        <UploaderCustom
                          page="footer"
                          documents={value ?? ""}
                          onChange={(data: any) => {
                            onChange(data);
                          }}
                          widthImg={60}
                          heightImg={60}
                          idBtnUpload="btn-upload"
                          isUploadIcon={true}
                          type="image"
                          isShowRequire={
                            errors?.footerContents?.[index]?.contents?.[idx]
                              ?.icon !== undefined
                          }
                        />
                        {value &&
                          language ===
                            process.env.REACT_APP_DEFAULT_LANGUAGE && (
                            <Button
                              variant="text"
                              sx={{
                                position: "absolute",
                                top: "-5px",
                                right: "-5px",
                                color: "red",
                                zIndex: 99,
                                background: "white",
                                p: 1,
                                minWidth: "30px",
                              }}
                              onClick={() => onChange("")}
                            >
                              <Clear />
                            </Button>
                          )}
                      </Box>
                    );
                  }}
                />
                <Typography className="text-14 mt-5">
                  {t("fileUpload_size")}: 48 X 42 {t("fileUpload_unit")}
                </Typography>
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={watch(`footerContents.${index}.contents.${idx}.includeIcon`) ? 6 : 12}>
            <Typography
              variant="body2"
              sx={{
                mb: 2,
                color:
                  watch(`footerContents.${index}.contents.${idx}.text`) &&
                  watch(`footerContents.${index}.contents.${idx}.text`).length >
                    50
                    ? "red"
                    : "black",
              }}
            >
              {t("checkLength")} :{" "}
              {watch(`footerContents.${index}.contents.${idx}.text`)
                ? watch(`footerContents.${index}.contents.${idx}.text`).length
                : 0}
              /50
            </Typography>
           
                    <TextField
                  size="small"
                  fullWidth
                  label={t("textField")}
                  {...register(`footerContents.${index}.contents.${idx}.text`)}
                  InputLabelProps={{ shrink: true }}
                  error={
                    errors?.footerContents?.[index]?.contents?.[idx]?.text !==
                    undefined
                  }
                  helperText={
                    errors?.footerContents?.[index]?.contents?.[idx]?.text !==
                    undefined
                      ? validationMessage.required
                      : ""
                  }
                />
            <Box mt={2} />
            <TextField
                  size="small"
                  fullWidth
                  label={t("urlField")}
                  {...register(`footerContents.${index}.contents.${idx}.link`)}
                  InputLabelProps={{ shrink: true }}
                  error={
                    errors?.footerContents?.[index]?.contents?.[idx]?.link !==
                    undefined
                  }
                  helperText={
                    errors?.footerContents?.[index]?.contents?.[idx]?.link !==
                    undefined
                      ? "Field Required"
                      : ""
                  }
                />
          </Grid>
          
          {idx + 1 === fields.length &&
            language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
              <Grid item xs={12}>
                <Stack alignItems={"flex-end"}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      append({
                        keyContent: `HOMEPAGE_FOOTER_CONTENT_${makeid(8)}`,
                        text: "",
                        icon: "",
                      })
                    }
                  >
                    {t("addContentButton")}
                  </Button>
                </Stack>
              </Grid>
            )}
        </Grid>
      </Box>
      <Divider />
    </Grid>
  );
};

export default ContentTextItem;
