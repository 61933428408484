import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { SearchCustomerReviewQuery, StoreCustomerReviewPayload } from "../interfaces/homePage";
import { buildQueryString } from "../utils";

const customerReviewService = {

    store: async (payload: StoreCustomerReviewPayload) => {
      
            return await apiInstance.post(endPointAPI.CUSTOMER_REVIEW.STORE, payload)

    },

    update: async (payload: StoreCustomerReviewPayload, id: number) => {
        try {
            return await apiInstance.put(`${endPointAPI.CUSTOMER_REVIEW.UPDATE}/${id}`, payload)
        } catch (error) {
            return null
        }
    },

    getList: async (payload: SearchCustomerReviewQuery) => {
        try {
            // return await apiInstance.get(endPointAPI.CUSTOMER_REVIEW.GET_LIST + "?" + queryString)
            return await apiInstance.get(endPointAPI.CUSTOMER_REVIEW.GET_LIST + `?${buildQueryString(payload)}`);
        } catch (error) {
            return null
        }
    },

    findDetail: async (language: string, keyLang: string) => {
        try {
            return await apiInstance.get(`${endPointAPI.CUSTOMER_REVIEW.FIND_DETAIL}/${language}/${keyLang}`)
        } catch (error) {
            return null
        }
    },
    delete: async (id: number) => {
        try {
            return await apiInstance.delete(`${endPointAPI.CUSTOMER_REVIEW.DELETE}/${id}`)
        } catch (error) {
            return null
        }
    },


}
export default customerReviewService;
