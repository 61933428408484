import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../components/Table/TableHeader";
import { useTranslation } from "react-i18next";

const TableHeading = () => {
  const { t } = useTranslation();

  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left">{t("homePage_customerReview_list_table_header_avatar")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("homePage_customerReview_list_table_header_country")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("homePage_customerReview_list_table_header_fullName")}</TableCell>
      <TableCell className="bg-slight-blue text-left">{t("homePage_customerReview_list_table_header_review")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("homePage_customerReview_list_table_header_actions")}</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
