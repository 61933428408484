import { Add, Clear, Close, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { makeTitle, makeid } from "../../../utils";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import {
  FAQItem,
  FAQsForm,
} from "../../../interfaces/homePage";
import data from "../../../mockdata/data";
import validationMessage from "../../../constants/validationMessage";
import ContentComponent from "../../../components/Editor/ContentComponent";
import faqService from "../../../services/faqService";

const validationSchema = yup.object().shape({
  keyLang: yup.string().required("Field Required"),
  type: yup.string().nullable().transform((value) => (value === undefined ? null : value)),
  question: yup.string().required("Field Required"),
  country: yup.string().nullable().transform((value) => (value === undefined ? null : value)),
  flag: yup.string().nullable().transform((value) => (value === undefined ? null : value)),
  answer: yup.string().required("Field Required"),
});



const FAQForm = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataFAQ, setDataFAQ] = useState<FAQItem | null>(null);
  const [dataFAQSaved, setDataFAQSaved] = useState<FAQItem | null>(null);
  const { language } = useGlobalContext();
  const { keyLang } = useParams();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<FAQsForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
const defaultData = {
  keyLang: `FAQ_${makeid(10)}`,
  type: "",
  question: "",
  country: "",
  flag: "",
  answer: "",
};
  const submit: SubmitHandler<FAQsForm> = async (data) => {
    // console.log(data);
    const payload = {
      ...data,
      language: language ?? "en",
    };
    setLoadingBtn(true);
    if (dataFAQ && dataFAQ?.id) {
      const response = await faqService.update(payload, dataFAQ.id);
      if (response?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `FAQ has been updated`,
          showConfirmButton: false,
          timer: 1500,
        });
        fetchDataDetail(response.data);
      }
    } else {
      try {
        const response = await faqService.store(payload);
        if (response?.status === 200) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `FAQ has been created`,
            showConfirmButton: false,
            timer: 1500,
          });
          // fetchDataDetail(response.data);
          navigate(`/homepage/faqs`)
        }
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: error.response.data.message ?? "Something Went Wrong",
        });
      }
    }

    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<FAQsForm> = (errors) => {
    console.log(errors);
  };

  const fetchDataDetail = async (keyLang: string) => {
    setLoading(true);
    const response = await faqService.findDetail(language, String(keyLang));

    if (response?.status === 200) {
      const data = response.data;
      if (data) {
        setDataFAQ(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataFAQSaved(data);
        }
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(data);
        } else {
          if (dataFAQSaved) {
            const dataForm: FAQsForm = {
              ...dataFAQSaved,
              question: data.question ?? dataFAQSaved.question,
              answer: data.answer ?? dataFAQSaved.answer,
            };
            reset(dataForm);
          } else {
            reset(data);
          }
        }

        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataFAQSaved) {
        setDataFAQ(null);
        reset(dataFAQSaved);

        setKeyReset(makeid(5));
      } else {
        setDataFAQ(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (keyLang) {
      fetchDataDetail(String(keyLang));
    } else {
      setDataFAQ(null);
      reset(defaultData);
      setKeyReset(makeid(5));
    }
  }, [keyLang, language]);

  return (
    <Box sx={{ background: "#ddd", p: 2 }}>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ p: 2, my: 2 }}>
          <form onSubmit={handleSubmit(submit, error)}>
            <Grid container spacing={2}>
              <Grid item md={3}>
                <Controller
                  control={control}
                  name={`type`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Autocomplete
                        disablePortal
                        disableClearable
                        id="type"
                        options={[]}
                        getOptionLabel={(option) => makeTitle(option)}
                        value={value ? value : undefined}
                        onChange={(e, value) => {
                          if (value) {
                            onChange(value);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t(
                              "homepage_faqs_typeField"
                            )}
                            size="small"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            error={errors?.type !== undefined}
                            helperText={
                              errors?.type !== undefined
                                ? errors?.type?.message
                                : ""
                            }
                          />
                        )}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Controller
                  key={keyReset}
                  control={control}
                  name={`country`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <>
                        <Autocomplete
                          disablePortal
                          disableClearable
                          id="countries"
                          options={data.listCountry}
                          getOptionLabel={(option) => option.label}
                          value={data.listCountry.find(
                            (item) => item.label === value
                          )}
                          onChange={(e, value) => {
                            if (value) {
                              onChange(value.label);
                              setValue(
                                `flag`,
                                `https://flagcdn.com/w20/${value.code.toLowerCase()}.png`
                              );
                            }
                          }}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                alt=""
                              />
                              {option.label}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              label={t("homepage_faqs_countryField")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              error={errors?.country !== undefined}
                              helperText={
                                errors?.country !== undefined
                                  ? validationMessage.required
                                  : ""
                              }
                            />
                          )}
                        />
                      </>
                    );
                  }}
                />
              </Grid>{" "}
              <Grid item md={12}>
                <TextField
                  {...register(`question`)}
                  label={t("homepage_faqs_questionField")}
                  size="small"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.question !== undefined}
                  helperText={
                    errors?.question !== undefined
                      ? errors?.question?.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography className="text-14 fw-500">
                  {" "}
                  {t("homepage_faqs_answerField")}
                </Typography>
                <Controller
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <ContentComponent
                        data={value ?? ""}
                        onChange={onChange}
                        error={errors?.answer !== undefined}
                      />
                    );
                  }}
                  name={`answer`}
                />
                <Typography
                  sx={{ fontSize: 12, p: "4px 14px 0" }}
                  className={
                    errors?.answer !== undefined ? "text-color-danger" : ""
                  }
                >
                  {errors?.answer !== undefined
                    ? validationMessage.required
                    : ""}
                </Typography>
              </Grid>
            </Grid>

            <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
              <Button disabled={loadingBtn} type="submit" variant="contained">
                {t("saveButton")}
              </Button>
            </Box>
          </form>
        </Paper>
      )}
    </Box>
  );
};

export default FAQForm;
