import { Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  HandleFinderForm,
  HandleKnowMore,
  ItemData,
  PayloadHeroSection,
} from "../../../interfaces/homePage";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import { makeid } from "../../../utils";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import homePageService from "../../../services/homePageService";

const validationSchema = yup.object().shape({
  title: yup.string().required("Field Required"),
  findAnAgent: yup
    .object()
    .shape({
      title: yup.string().required("Field Required"),
      subTitle: yup.string().required("Field Required"),
      image: yup.string().required("Field Required"),
    })
    .required("Field Required"),
  sendingBrochure: yup
    .object()
    .shape({
      title: yup.string().required("Field Required"),
      subTitle: yup.string().required("Field Required"),
      image: yup.string().required("Field Required"),
      url: yup.string().required("Field Required"),
    })
    .required("Field Required"),
});

const FinderForm = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const { language } = useGlobalContext();
  const [dataKnowMore, setDataKnowMore] = useState<ItemData | null>(null);
  const [dataKnowMoreSaved, setDataKnowMoreSaved] = useState<ItemData | null>(
    null
  );
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<HandleFinderForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<HandleFinderForm> = async (data) => {
    const payload: PayloadHeroSection = {
      language: language ?? "en",
      type: "homepage",
      section: "know-more-finder-form",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataKnowMore) {
      const res = await homePageService.update(dataKnowMore.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Know More has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Know More has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false)
  };

  const error: SubmitErrorHandler<HandleKnowMore> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true)
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: any) =>
          item.language === language && item.section === "know-more-finder-form"
      );
      if (data) {
        setDataKnowMore(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataKnowMoreSaved(data);
        }
        const initData = JSON.parse(data.contents);
        reset(initData);
        setKeyReset(makeid(5));
      } else if (dataKnowMoreSaved) {
        const dataSaved = JSON.parse(dataKnowMoreSaved.contents ?? "{}");
        setDataKnowMore(null);
        reset(dataSaved);
      } else {
        setDataKnowMore(null);
        reset({
          title: "",
          findAnAgent: {
            title: "",
            subTitle: "",
            image: "",
          },
          sendingBrochure: {
            title: "",
            subTitle: "",
            image: "",
          },
        });
        setKeyReset(makeid(5));
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <>
    {
        loading ? <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
        <CircularProgress />
      </Box> :  <form onSubmit={handleSubmit(submit, error)}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Paper sx={{ p: 3 }}>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("title") && watch("title").length > 25
                        ? "red"
                        : "black",
                  }}
                >
                  {t("masterSeo_metaDescription_checkLength")} :{" "}
                  {watch("title") ? watch("title").length : 0}/55
                </Typography>
                <TextField
                  {...register("title")}
                  label={t("titleField")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors.title !== undefined}
                  helperText={
                    errors.title !== undefined ? errors.title.message : ""
                  }
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6">
              {t("homepage_knowMore_agentFinderForm_agentFinder_header")}
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("findAnAgent.title") &&
                      watch("findAnAgent.title").length > 25
                        ? "red"
                        : "black",
                  }}
                >
                  {t("masterSeo_metaDescription_checkLength")} :{" "}
                  {watch("findAnAgent.title")
                    ? watch("findAnAgent.title").length
                    : 0}
                  /25
                </Typography>
                <TextField
                  {...register("findAnAgent.title")}
                  label={t("titleField")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.findAnAgent?.title !== undefined}
                  helperText={
                    errors?.findAnAgent?.title !== undefined
                      ? errors?.findAnAgent?.title.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("findAnAgent.subTitle") &&
                      watch("findAnAgent.subTitle").length > 55
                        ? "red"
                        : "black",
                  }}
                >
                  {t("masterSeo_metaDescription_checkLength")} :{" "}
                  {watch("findAnAgent.subTitle")
                    ? watch("findAnAgent.subTitle").length
                    : 0}
                  /55
                </Typography>
                <TextField
                  {...register("findAnAgent.subTitle")}
                  label={t("subtitleField")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.findAnAgent?.subTitle !== undefined}
                  helperText={
                    errors?.findAnAgent?.subTitle !== undefined
                      ? errors?.findAnAgent?.subTitle.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={`findAnAgent.image`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Box sx={{ position: "relative" }}>
                        <UploaderCustom
                          page="brochure-find-an-agent"
                          type="image"
                          documents={value ?? ""}
                          onChange={(data: any) => onChange(data)}
                          widthImg={630}
                          heightImg={316}
                          idBtnUpload="btn-upload"
                          isShowRequire={errors.findAnAgent?.image !== undefined}
                        />
                        {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Button
                          variant="contained"
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            color: "red",
                            zIndex: 99,
                            background: "white",
                            p: 1,
                            minWidth: "30px",
                          }}
                          onClick={() => onChange("")}
                        >
                          <Delete />
                        </Button>}
                      </Box>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6">
              {t("homepage_knowMore_agentFinderForm_sendingBrochure_header")}
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <Grid container spacing={2}>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("sendingBrochure.title") &&
                      watch("sendingBrochure.title").length > 25
                        ? "red"
                        : "black",
                  }}
                >
                  {t("masterSeo_metaDescription_checkLength")} :{" "}
                  {watch("sendingBrochure.title")
                    ? watch("sendingBrochure.title").length
                    : 0}
                  /25
                </Typography>
                <TextField
                  {...register("sendingBrochure.title")}
                  label={t("titleField")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.sendingBrochure?.title !== undefined}
                  helperText={
                    errors?.sendingBrochure?.title !== undefined
                      ? errors?.sendingBrochure?.title.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={12}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("sendingBrochure.subTitle") &&
                      watch("sendingBrochure.subTitle").length > 55
                        ? "red"
                        : "black",
                  }}
                >
                  {t("masterSeo_metaDescription_checkLength")} :{" "}
                  {watch("sendingBrochure.subTitle")
                    ? watch("sendingBrochure.subTitle").length
                    : 0}
                  /55
                </Typography>
                <TextField
                  {...register("sendingBrochure.subTitle")}
                  label={t("subtitleField")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.sendingBrochure?.subTitle !== undefined}
                  helperText={
                    errors?.sendingBrochure?.subTitle !== undefined
                      ? errors?.sendingBrochure?.subTitle.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Typography
                  variant="body2"
                  sx={{
                    mb: 2,
                    color:
                      watch("sendingBrochure.url") &&
                      watch("sendingBrochure.url").length > 55
                        ? "red"
                        : "black",
                  }}
                >
                  {t("masterSeo_metaDescription_checkLength")} :{" "}
                  {watch("sendingBrochure.subTitle")
                    ? watch("sendingBrochure.subTitle").length
                    : 0}
                  /55
                </Typography>
                <TextField
                  {...register("sendingBrochure.url")}
                  label={t("dataLimit_buttonURL")}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={errors?.sendingBrochure?.url !== undefined}
                  helperText={
                    errors?.sendingBrochure?.url !== undefined
                      ? errors?.sendingBrochure?.url.message
                      : ""
                  }
                />
              </Grid>
              <Grid item md={6}>
                <Controller
                  control={control}
                  name={`sendingBrochure.image`}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <Box sx={{ position: "relative" }}>
                        <UploaderCustom
                          page="brochure-find-an-agent"
                          type="image"
                          documents={value ?? ""}
                          onChange={(data: any) => onChange(data)}
                          widthImg={630}
                          heightImg={316}
                          idBtnUpload="btn-upload"
                          isShowRequire={errors.sendingBrochure?.image !== undefined}
                        />
                        {language === process.env.REACT_APP_DEFAULT_LANGUAGE && <Button
                          variant="contained"
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            color: "red",
                            zIndex: 99,
                            background: "white",
                            p: 1,
                            minWidth: "30px",
                          }}
                          onClick={() => onChange("")}
                        >
                          <Delete />
                        </Button>}
                      </Box>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <Button disabled={loadingBtn} type="submit" variant="contained">
          {t("saveButton")}
        </Button>
      </Box>
    </form>
    }
    </>
   
  );
};

export default FinderForm;
