import { Add, Clear, Delete, DeleteOutline } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  HandleBrochureUpload,
  ItemData,
  PayloadHeroSection,
} from "../../../interfaces/homePage";
import UploaderCustom from "../../../components/Upload/UploaderCustom";
import { makeid } from "../../../utils";
import Swal from "sweetalert2";
import { useGlobalContext } from "../../../context/MyGlobalContext";
import homePageService from "../../../services/homePageService";

const validationSchema = yup.object().shape({
  title: yup.string().required("Field Required"),
  subTitle: yup.string().required("Field Required"),
  pdfInfo: yup
    .array(
      yup.object().shape({
        name: yup.string().required("Field Required"),
        image: yup.string().required("Field Required"),
        url: yup.string().required("Field Required"),
        key: yup.string().required("Field Required"),
      })
    )
    .required("Field Required"),
});

const BrochureUpload = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const { language } = useGlobalContext();
  const [dataKnowMore, setDataKnowMore] = useState<ItemData | null>(null);
  const [dataKnowMoreSaved, setDataKnowMoreSaved] = useState<ItemData | null>(
    null
  );
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm<HandleBrochureUpload>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<HandleBrochureUpload> = async (data) => {
    const payload: PayloadHeroSection = {
      language: language ?? "en",
      type: "homepage",
      section: "know-more-brochure-upload",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataKnowMore) {
      const res = await homePageService.update(dataKnowMore.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Know More Brochure has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Know More Brochure has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };

  const error: SubmitErrorHandler<HandleBrochureUpload> = (errors) => {
    console.log(errors);
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "pdfInfo",
  });

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("homepage");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: any) =>
          item.language === language &&
          item.section === "know-more-brochure-upload"
      );
      if (data) {
        setDataKnowMore(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataKnowMoreSaved(data);
        }
        const initData: HandleBrochureUpload = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          const dataSaved: HandleBrochureUpload = JSON.parse(
            data?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              subTitle: initData.subTitle ?? dataSaved.subTitle,
              pdfInfo: dataSaved.pdfInfo.map((item) => ({
                ...item,
                name:
                  initData.pdfInfo.find((el) => el.key === item.key)?.name ??
                  item.name,
                url:
                  initData.pdfInfo.find((el) => el.key === item.key)?.url ??
                  item.url,
              })),
            };
            console.log(dataForm, initData, "if");

            reset(dataForm);
          } else {
            reset(initData);
          }
        } else {
          const dataSaved: HandleBrochureUpload = JSON.parse(
            dataKnowMoreSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              subTitle: initData.subTitle ?? dataSaved.subTitle,
              pdfInfo: dataSaved.pdfInfo.map((item) => ({
                ...item,
                name:
                  initData.pdfInfo.find((el) => el.key === item.key)?.name ??
                  item.name,
                url:
                  initData.pdfInfo.find((el) => el.key === item.key)?.url ??
                  item.url,
              })),
            };
            console.log(dataForm, initData, "else");

            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        // reset(initData);
        setKeyReset(makeid(5));
      } else if (dataKnowMoreSaved) {
        const dataSaved = JSON.parse(dataKnowMoreSaved?.contents ?? "{}");

        setDataKnowMore(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataKnowMore(null);
        reset({
          title: "",
          subTitle: "",
          pdfInfo: [
            {
              name: "",
              image: "",
              url: "",
              key: `BROCHURE_UPLOAD_${makeid(8)}`,
            },
          ],
        });
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);

  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit, error)}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Paper sx={{ p: 3 }}>
                <Typography variant="h6">
                  {t(
                    "homepage_knowMore_inspirationByUs_cardAdjustments_header"
                  )}
                </Typography>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("title") && watch("title").length > 25
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("masterSeo_metaDescription_checkLength")} :{" "}
                      {watch("title") ? watch("title").length : 0}/25
                    </Typography>
                    <TextField
                      {...register("title")}
                      label={t("titleField")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.title !== undefined}
                      helperText={
                        errors?.title !== undefined ? errors?.title.message : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        mb: 2,
                        color:
                          watch("subTitle") && watch("subTitle").length > 55
                            ? "red"
                            : "black",
                      }}
                    >
                      {t("masterSeo_metaDescription_checkLength")} :{" "}
                      {watch("subTitle") ? watch("subTitle").length : 0}/55
                    </Typography>
                    <TextField
                      {...register("subTitle")}
                      label={t("subtitleField")}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={errors?.subTitle !== undefined}
                      helperText={
                        errors?.subTitle !== undefined
                          ? errors?.subTitle.message
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item md={12}>
                    <Grid container spacing={2}>
                      {fields.map((_, index) => (
                        <Grid item md={3} key={index}>
                          <Box
                            sx={{
                              boxShadow: 1,
                              p: 2,
                              borderRadius: 2,
                              position: "relative",
                            }}
                          >
                            {language ===
                              process.env.REACT_APP_DEFAULT_LANGUAGE && (
                              <Button
                                variant="contained"
                                sx={{
                                  position: "absolute",
                                  top: "-5px",
                                  right: "-5px",
                                  color: "red",
                                  zIndex: 99,
                                  background: "white",
                                  p: 1,
                                  minWidth: "30px",
                                }}
                                onClick={() => {
                                  remove(index);
                                  setKeyReset(makeid(5));
                                }}
                              >
                                <Delete />
                              </Button>
                            )}
                            <Box sx={{ mb: 1 }}>
                              <TextField
                                {...register(`pdfInfo.${index}.name`)}
                                label={t("titleField")}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                error={
                                  errors?.pdfInfo?.[index]?.name !== undefined
                                }
                                helperText={
                                  errors?.pdfInfo?.[index]?.name !== undefined
                                    ? errors?.pdfInfo?.[index]?.name?.message
                                    : ""
                                }
                              />
                            </Box>
                            <Box sx={{ mb: 1 }}>
                              <Controller
                                key={keyReset}
                                control={control}
                                name={`pdfInfo.${index}.image`}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Box sx={{ position: "relative" }}>
                                      <UploaderCustom
                                        page="brochure-image"
                                        type="image"
                                        documents={value ?? ""}
                                        onChange={(data: any) => onChange(data)}
                                        widthImg={""}
                                        heightImg={""}
                                        idBtnUpload="btn-upload"
                                        imageViewHeight="275px"
                                        isShowRequire={
                                          errors.pdfInfo?.[index]?.image !==
                                          undefined
                                        }
                                      />

                                      {language ===
                                        process.env
                                          .REACT_APP_DEFAULT_LANGUAGE && (
                                        <Button
                                          variant="text"
                                          sx={{
                                            position: "absolute",
                                            top: "-5px",
                                            right: "-5px",
                                            color: "red",
                                            zIndex: 99,
                                            background: "white",
                                            p: 1,
                                            minWidth: "30px",
                                          }}
                                          onClick={() => onChange("")}
                                        >
                                          <Clear />
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ mb: 1 }}>
                              <Controller
                                key={keyReset}
                                control={control}
                                name={`pdfInfo.${index}.url`}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => {
                                  return (
                                    <Box sx={{ position: "relative" }}>
                                      <UploaderCustom
                                        page="brochure-url"
                                        type="pdf"
                                        documents={value ?? ""}
                                        onChange={(data: any) => onChange(data)}
                                        widthImg={""}
                                        heightImg={""}
                                        idBtnUpload="btn-upload"
                                        isUploadIcon={false}
                                        isShowRequire={
                                          errors.pdfInfo?.[index]?.url !==
                                          undefined
                                        }
                                      />
                                      {language ===
                                        process.env
                                          .REACT_APP_DEFAULT_LANGUAGE && (
                                        <Button
                                          variant="text"
                                          sx={{
                                            position: "absolute",
                                            top: "-5px",
                                            right: "-5px",
                                            color: "red",
                                            zIndex: 99,
                                            background: "white",
                                            p: 1,
                                            minWidth: "30px",
                                          }}
                                          onClick={() => onChange("")}
                                        >
                                          <Clear />
                                        </Button>
                                      )}
                                    </Box>
                                  );
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                      {language === process.env.REACT_APP_DEFAULT_LANGUAGE && (
                        <Grid item md={3}>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              append({
                                name: "",
                                url: "",
                                image: "",
                                key: `BROCHURE_UPLOAD_${makeid(8)}`,
                              })
                            }
                            startIcon={<Add />}
                          >
                            {t("addMoreButton")}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
            <Button disabled={loadingBtn} type="submit" variant="contained">
              {t("saveButton")}
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};

export default BrochureUpload;
