import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import validationMessage from "../../../../../constants/validationMessage";
import { FindLocalAgentAgentPageForm } from "../../../../../interfaces/navigationMenu";
import { ItemHero } from "../../../../../interfaces/homePage";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import { Clear } from "@mui/icons-material";
import ContentComponent from "../../../../../components/Editor/ContentComponent";

const defaultData = {
  metaTitle: "",
  metaDescription: "",
  metaKeyword: "",
  agentName: "",
  agencyAddress: "",
  agencyAddressIcon: "",
  agencyEmail: "",
  agencyEmailIcon: "",
  agencyPhone: "",
  agencyPhoneIcon: "",
  agencyWebsite: "",
  agencyWebsiteIcon: "",
  agencyURL: "",
  aboutAgency: "",
  googleMapLatitude: "",
  googleMapLongitude: "",
  locationIndicatorIcon: "",
  searchResultTitle: "",
  searchResultSubtitle: "",
};
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = object().shape({
  metaTitle: string().required(validationMessage.required),
  metaDescription: string().required(validationMessage.required),
  metaKeyword: string().required(validationMessage.required),
  agentName: string().required(validationMessage.required),
  agencyAddress: string().required(validationMessage.required),
  agencyAddressIcon: string().required(validationMessage.required),
  agencyEmail: string()
    .required(validationMessage.required)
    .matches(
      new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      ),
      {
        message: validationMessage.email,
      }
    ),
  agencyEmailIcon: string().required(validationMessage.required),
  agencyPhone: string()
    .required(validationMessage.phone)
    .matches(phoneRegExp, validationMessage.phone),
  agencyPhoneIcon: string().required(validationMessage.required),
  agencyWebsite: string().required(validationMessage.required),
  agencyWebsiteIcon: string().required(validationMessage.required),
  agencyURL: string().required(validationMessage.required),
  aboutAgency: string().required(validationMessage.required),
  googleMapLatitude: string().required(validationMessage.required),
  googleMapLongitude: string().required(validationMessage.required),
  locationIndicatorIcon: string().required(validationMessage.required),
  searchResultTitle: string().required(validationMessage.required),
  searchResultSubtitle: string().required(validationMessage.required),
});
const AgentPageTab = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataAgentPage, setDataAgentPage] = useState<ItemHero | null>(null);
  const [dataAgentPageSaved, setDataAgentPageSaved] = useState<ItemHero | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<FindLocalAgentAgentPageForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<FindLocalAgentAgentPageForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "findlocalagent",
      section: "agentpage",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataAgentPage) {
      const res = await homePageService.update(dataAgentPage.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Agent Page has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Agent Page has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<FindLocalAgentAgentPageForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("findlocalagent");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "agentpage"
      );
      if (data) {
        setDataAgentPage(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataAgentPageSaved(data);
        }
        const initData: FindLocalAgentAgentPageForm = JSON.parse(data.contents);

        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: FindLocalAgentAgentPageForm = JSON.parse(
            dataAgentPageSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              metaTitle: initData.metaTitle ?? dataSaved.metaTitle,
              metaDescription:
                initData.metaDescription ?? dataSaved.metaDescription,
              metaKeyword: initData.metaKeyword ?? dataSaved.metaKeyword,
              agentName: initData.agentName ?? dataSaved.agentName,
              agencyAddress: initData.agencyAddress ?? dataSaved.agencyAddress,
              agencyAddressIcon: dataSaved.agencyAddressIcon,
              agencyEmail: initData.agencyEmail ?? dataSaved.agencyEmail,
              agencyEmailIcon: dataSaved.agencyEmailIcon,
              agencyPhone: initData.agencyPhone ?? dataSaved.agencyPhone,
              agencyPhoneIcon: dataSaved.agencyPhoneIcon,
              agencyWebsite: initData.agencyWebsite ?? dataSaved.agencyWebsite,
              agencyWebsiteIcon: dataSaved.agencyWebsiteIcon,
              agencyURL: initData.agencyURL ?? dataSaved.agencyURL,
              aboutAgency: initData.aboutAgency ?? dataSaved.aboutAgency,
              googleMapLatitude:
                initData.googleMapLatitude ?? dataSaved.googleMapLatitude,
              googleMapLongitude:
                initData.googleMapLongitude ?? dataSaved.googleMapLongitude,
              locationIndicatorIcon: dataSaved.locationIndicatorIcon,
              searchResultTitle:
                initData.searchResultTitle ?? dataSaved.searchResultTitle,
              searchResultSubtitle:
                initData.searchResultSubtitle ?? dataSaved.searchResultSubtitle,
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        setKeyReset(makeid(5));
      } else if (dataAgentPageSaved) {
        const dataSaved = JSON.parse(dataAgentPageSaved?.contents ?? "{}");

        setDataAgentPage(null);
        reset(dataSaved);

        setKeyReset(makeid(5));
      } else {
        setDataAgentPage(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <>
      {loading ? (
        <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
          <CircularProgress />
        </Box>
      ) : (
        <form onSubmit={handleSubmit(submit, error)}>
          {" "}
          <Grid container spacing={2} mb={2}>
            <Grid item md={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch("metaTitle") && watch("metaTitle").length > 61
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch("metaTitle") ? watch("metaTitle").length : 0}/61
                      </Typography>
                      <TextField
                        {...register("metaTitle")}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_metaTitle"
                        )}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors.metaTitle !== undefined}
                        helperText={
                          errors.metaTitle !== undefined
                            ? errors.metaTitle.message
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        variant="body2"
                        sx={{
                          mb: 2,
                          color:
                            watch("metaDescription") &&
                            watch("metaDescription").length > 160
                              ? "red"
                              : "black",
                        }}
                      >
                        {t("checkLength")} :{" "}
                        {watch("metaDescription")
                          ? watch("metaDescription").length
                          : 0}
                        /160
                      </Typography>
                      <TextField
                        {...register("metaDescription")}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_metaDescription"
                        )}
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors.metaDescription !== undefined}
                        helperText={
                          errors.metaDescription !== undefined
                            ? errors.metaDescription.message
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        {...register("metaKeyword")}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_metaKeywords"
                        )}
                        fullWidth
                        multiline
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        error={errors.metaKeyword !== undefined}
                        helperText={
                          errors.metaKeyword !== undefined
                            ? errors.metaKeyword.message
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        className="text-14 fw-600 mb-10"
                        color={
                          watch("agentName")?.length > 60 ? "error" : "green"
                        }
                      >
                        {t("checkLength")}: {watch("agentName")?.length}
                        /60
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`agentName`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_agencyName"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.agentName !== undefined}
                        helperText={
                          errors?.agentName !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Controller
                        key={keyReset}
                        control={control}
                        name={`agencyAddressIcon`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UploaderCustom
                                page="agent-page"
                                documents={value ?? ""}
                                onChange={(data: any) => onChange(data)}
                                widthImg={""}
                                heightImg={""}
                                idBtnUpload="btn-upload"
                                isUploadIcon={true}
                                type="image"
                                imageViewHeight="20px"
                                isShowRequire={
                                  errors.agencyAddressIcon !== undefined
                                }
                              />
                              {value &&
                                language ===
                                  process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                            </Box>
                          );
                        }}
                      />

                      <Typography className="text-14 mt-5">
                        {t("iconUpload_size")}: 20 X 20 {t("iconUpload_unit")}
                      </Typography>
                      <Typography className="text-14 mt-5">
                        {t("iconUpload_format_title")}:{" "}
                        {t("iconUpload_format_unit")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography
                        className="text-14 fw-600 mb-10"
                        color={
                          watch("agencyAddress")?.length > 80
                            ? "error"
                            : "green"
                        }
                      >
                        {t("checkLength")}: {watch("agencyAddress")?.length}
                        /80
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`agencyAddress`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_agencyAddress"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.agencyAddress !== undefined}
                        helperText={
                          errors?.agencyAddress !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Controller
                        key={keyReset}
                        control={control}
                        name={`agencyEmailIcon`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UploaderCustom
                                page="agent-page"
                                documents={value ?? ""}
                                onChange={(data: any) => onChange(data)}
                                widthImg={""}
                                heightImg={""}
                                idBtnUpload="btn-upload"
                                isUploadIcon={true}
                                type="image"
                                imageViewHeight="20px"
                                isShowRequire={
                                  errors?.agencyEmailIcon !== undefined
                                }
                              />
                              {value &&
                                language ===
                                  process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                            </Box>
                          );
                        }}
                      />

                      <Typography className="text-14 mt-5">
                        {t("iconUpload_size")}: 20 X 20 {t("iconUpload_unit")}
                      </Typography>
                      <Typography className="text-14 mt-5">
                        {t("iconUpload_format_title")}:{" "}
                        {t("iconUpload_format_unit")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography
                        className="text-14 fw-600 mb-10"
                        color={
                          watch("agencyEmail")?.length > 80 ? "error" : "green"
                        }
                      >
                        {t("checkLength")}: {watch("agencyEmail")?.length}
                        /80
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`agencyEmail`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_agencyEmail"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.agencyEmail !== undefined}
                        helperText={
                          errors?.agencyEmail !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Controller
                        key={keyReset}
                        control={control}
                        name={`agencyPhoneIcon`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UploaderCustom
                                page="agent-page"
                                documents={value ?? ""}
                                onChange={(data: any) => onChange(data)}
                                widthImg={""}
                                heightImg={""}
                                idBtnUpload="btn-upload"
                                isUploadIcon={true}
                                type="image"
                                imageViewHeight="20px"
                                isShowRequire={
                                  errors?.agencyPhoneIcon !== undefined
                                }
                              />
                              {value &&
                                language ===
                                  process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                            </Box>
                          );
                        }}
                      />

                      <Typography className="text-14 mt-5">
                        {t("iconUpload_size")}: 20 X 20 {t("iconUpload_unit")}
                      </Typography>
                      <Typography className="text-14 mt-5">
                        {t("iconUpload_format_title")}:{" "}
                        {t("iconUpload_format_unit")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Typography
                        className="text-14 fw-600 mb-10"
                        color={
                          watch("agencyPhone")?.length > 80 ? "error" : "green"
                        }
                      >
                        {t("checkLength")}: {watch("agencyPhone")?.length}
                        /80
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`agencyPhone`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_agencyPhoneNumber"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.agencyPhone !== undefined}
                        helperText={
                          errors?.agencyPhone !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                      <Controller
                        key={keyReset}
                        control={control}
                        name={`agencyWebsiteIcon`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UploaderCustom
                                page="agent-page"
                                documents={value ?? ""}
                                onChange={(data: any) => onChange(data)}
                                widthImg={""}
                                heightImg={""}
                                idBtnUpload="btn-upload"
                                isUploadIcon={true}
                                type="image"
                                imageViewHeight="20px"
                                isShowRequire={
                                  errors?.agencyWebsiteIcon !== undefined
                                }
                              />
                              {value &&
                                language ===
                                  process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                            </Box>
                          );
                        }}
                      />

                      <Typography className="text-14 mt-5">
                        {t("iconUpload_size")}: 20 X 20 {t("iconUpload_unit")}
                      </Typography>
                      <Typography className="text-14 mt-5">
                        {t("iconUpload_format_title")}:{" "}
                        {t("iconUpload_format_unit")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            className="text-14 fw-600 mb-10"
                            color={
                              watch("agencyWebsite")?.length > 80
                                ? "error"
                                : "green"
                            }
                          >
                            {t("checkLength")}: {watch("agencyWebsite")?.length}
                            /80
                          </Typography>
                          <TextField
                            size="small"
                            fullWidth
                            {...register(`agencyWebsite`)}
                            label={t(
                              "homepage_navigationMenu_findLocalAgent_agentPage_agencyWebsite"
                            )}
                            InputLabelProps={{ shrink: true }}
                            error={errors?.agencyWebsite !== undefined}
                            helperText={
                              errors?.agencyWebsite !== undefined
                                ? validationMessage.required
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            fullWidth
                            {...register(`agencyURL`)}
                            label={t(
                              "homepage_navigationMenu_findLocalAgent_agentPage_agencyWebsiteURL"
                            )}
                            InputLabelProps={{ shrink: true }}
                            error={errors?.agencyURL !== undefined}
                            helperText={
                              errors?.agencyURL !== undefined
                                ? validationMessage.required
                                : ""
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Typography className="text-18 fw-600">
                    {" "}
                    {t(
                      "homepage_navigationMenu_findLocalAgent_agentPage_aboutAgency"
                    )}
                  </Typography>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => {
                      return (
                        <ContentComponent data={value} onChange={onChange} />
                      );
                    }}
                    name={`aboutAgency`}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`googleMapLatitude`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_googleMapLatitude"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.googleMapLatitude !== undefined}
                        helperText={
                          errors?.googleMapLatitude !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`googleMapLongitude`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_googleMapLongitude"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.googleMapLongitude !== undefined}
                        helperText={
                          errors?.googleMapLongitude !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Controller
                        key={keyReset}
                        control={control}
                        name={`locationIndicatorIcon`}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => {
                          return (
                            <Box sx={{ position: "relative" }}>
                              <UploaderCustom
                                page="agent-page"
                                documents={value ?? ""}
                                onChange={(data: any) => onChange(data)}
                                widthImg={""}
                                heightImg={""}
                                idBtnUpload="btn-upload"
                                isUploadIcon={true}
                                type="image"
                                isShowRequire={
                                  errors?.locationIndicatorIcon !== undefined
                                }
                                imageViewHeight="36px"
                              />
                              {value &&
                                language ===
                                  process.env.REACT_APP_DEFAULT_LANGUAGE && (
                                  <Button
                                    variant="text"
                                    sx={{
                                      position: "absolute",
                                      top: "-5px",
                                      right: "-5px",
                                      color: "red",
                                      zIndex: 99,
                                      background: "white",
                                      p: 1,
                                      minWidth: "30px",
                                    }}
                                    onClick={() => onChange("")}
                                  >
                                    <Clear />
                                  </Button>
                                )}
                            </Box>
                          );
                        }}
                      />

                      <Typography className="text-14 mt-5">
                        {t("iconUpload_size")}: 36 X 36 {t("iconUpload_unit")}
                      </Typography>
                      <Typography className="text-14 mt-5">
                        {t("iconUpload_format_title")}:{" "}
                        {t("iconUpload_format_unit")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Box p={3} py={4}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        className="text-14 fw-600 mb-10"
                        color={
                          watch("searchResultTitle")?.length > 90
                            ? "error"
                            : "green"
                        }
                      >
                        {t("checkLength")}: {watch("searchResultTitle")?.length}
                        /90
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`searchResultTitle`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_searchResultTitle"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.searchResultTitle !== undefined}
                        helperText={
                          errors?.searchResultTitle !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        className="text-14 fw-600 mb-10"
                        color={
                          watch("searchResultSubtitle")?.length > 90
                            ? "error"
                            : "green"
                        }
                      >
                        {t("checkLength")}:{" "}
                        {watch("searchResultSubtitle")?.length}
                        /90
                      </Typography>
                      <TextField
                        size="small"
                        fullWidth
                        {...register(`searchResultSubtitle`)}
                        label={t(
                          "homepage_navigationMenu_findLocalAgent_agentPage_searchResultSubtitle"
                        )}
                        InputLabelProps={{ shrink: true }}
                        error={errors?.searchResultSubtitle !== undefined}
                        helperText={
                          errors?.searchResultSubtitle !== undefined
                            ? validationMessage.required
                            : ""
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
            <Button disabled={loadingBtn} variant="contained" type="submit">
              {t("saveButton")}
            </Button>
          </Stack>
        </form>
      )}
    </>
  );
};

export default AgentPageTab;
