import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UploaderCustom from "../../../../../components/Upload/UploaderCustom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import validationMessage from "../../../../../constants/validationMessage";
import * as yup from "yup";
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import { ItemHero } from "../../../../../interfaces/homePage";
import { WhyChooseUsSubscribeForm } from "../../../../../interfaces/navigationMenu";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";
import { Clear } from "@mui/icons-material";

const defaultData = {
  title: "",
  images: "",
  isRequiredFirstName: false,
  isRequiredLastName: false,
  isRequiredEmail: false,
  isRobotCheck: false,
};
const validationSchema = yup.object().shape({
  title: yup.string().required(validationMessage.required),
  images: yup
    .string()
    .nullable()
    .transform((value) => (value === undefined ? null : value)),
  isRequiredFirstName: yup.boolean().required(validationMessage.required),
  isRequiredLastName: yup.boolean().required(validationMessage.required),
  isRequiredEmail: yup.boolean().required(validationMessage.required),
  isRobotCheck: yup.boolean().required(validationMessage.required),
});
const Subscribe = () => {
  const { t } = useTranslation();
  const { language } = useGlobalContext();
  const [dataSubscribe, setDataSubscribe] = useState<ItemHero | null>(null);
  const [dataSubscribeSaved, setDataSubscribeSaved] = useState<ItemHero | null>(
    null
  );
  const [keyReset, setKeyReset] = useState("");
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<WhyChooseUsSubscribeForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const submit: SubmitHandler<WhyChooseUsSubscribeForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "subscribe",
      contents: JSON.stringify(data),
    };
    setLoadingBtn(true);
    if (dataSubscribe) {
      const res = await homePageService.update(dataSubscribe.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Subscribe has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Subscribe has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }
    setLoadingBtn(false);
  };
  const error: SubmitErrorHandler<WhyChooseUsSubscribeForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) =>
          item.language === language && item.section === "subscribe"
      );
      if (data) {
        setDataSubscribe(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataSubscribeSaved(data);
        }
        const initData: WhyChooseUsSubscribeForm = JSON.parse(data.contents);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          reset(initData);
        } else {
          const dataSaved: WhyChooseUsSubscribeForm = JSON.parse(
            dataSubscribeSaved?.contents ?? "{}"
          );

          if (Object.keys(dataSaved ?? {}).length > 0) {
            const dataForm = {
              title: initData.title ?? dataSaved.title,
              images: dataSaved.images,
              isRequiredFirstName:
                initData.isRequiredFirstName ?? dataSaved.isRequiredFirstName,
              isRequiredLastName:
                initData.isRequiredLastName ?? dataSaved.isRequiredLastName,
              isRequiredEmail:
                initData.isRequiredEmail ?? dataSaved.isRequiredEmail,
              isRobotCheck: initData.isRobotCheck ?? dataSaved.isRobotCheck,
            };
            reset(dataForm);
          } else {
            reset(initData);
          }
        }
        setKeyReset(makeid(5));
      } else if (dataSubscribeSaved) {
        const dataSaved = JSON.parse(dataSubscribeSaved?.contents ?? "{}");
        setDataSubscribe(null);
        reset(dataSaved);
        setKeyReset(makeid(5));
      } else {
        setDataSubscribe(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      <Paper sx={{ px: "75px", py: 5, mb: 2 }}>
        {loading ? (
          <Box display={"flex"} justifyContent={"center"} sx={{ my: 10 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2} p={2}>
            <Grid item xs={12}>
              <Typography
                className="text-14 fw-600 mb-10"
                color={watch("title")?.length > 50 ? "error" : "green"}
              >
                {t("checkLength")}: {watch("title")?.length}/50
              </Typography>

              <TextField
                size="small"
                fullWidth
                {...register(`title`)}
                label={t("homepage_navigationMenu_whyChooseUs_subscribe_title")}
                InputLabelProps={{ shrink: true }}
                error={errors?.title !== undefined}
                helperText={
                  errors?.title !== undefined ? validationMessage.required : ""
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Controller
                key={keyReset}
                control={control}
                name="images"
                render={({ field: { onChange, onBlur, value, ref } }) => {
                  return (
                    <Box sx={{ position: "relative" }}>
                      <UploaderCustom
                        page="master-setup"
                        documents={value ?? ""}
                        onChange={(data: any) => onChange(data)}
                        widthImg={""}
                        heightImg={""}
                        idBtnUpload="btn-upload"
                        type="image"
                        imageViewHeight="364px"
                        isShowRequire={errors?.images !== undefined}
                      />
                      {(value && language === process.env.REACT_APP_DEFAULT_LANGUAGE) && (
                        <Button
                          variant="text"
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            right: "-5px",
                            color: "red",
                            zIndex: 99,
                            background: "white",
                            p: 1,
                            minWidth: "30px",
                          }}
                          onClick={() => onChange("")}
                        >
                          <Clear />
                        </Button>
                      )}
                    </Box>
                  );
                }}
              />

              <Typography className="text-14 mt-5">
                {t("fileUpload_size")}: 630 X 364 {t("fileUpload_unit")}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Paper>

      <Stack className="mt-30" alignItems={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
  );
};

export default Subscribe;
