import {
  Grid,
} from "@mui/material";
import React from "react";
import { FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch, useFieldArray } from "react-hook-form";
import ContentTextItem from "./ContentTextItem";
import { FooterForm } from "../../../interfaces/homePage";

interface Props {
  index: number;
  control: any;
  keyReset: string;
  watch: UseFormWatch<FooterForm>
  errors: FieldErrors<FooterForm>
  register: UseFormRegister<FooterForm>
  setValue: UseFormSetValue<FooterForm>
}
const FooterContentItem = ({
  index,
  control,
  keyReset,
  watch,
  errors,
  register,
  setValue
}: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `footerContents.${index}.contents`,
  });
  return (
    <div>
      <Grid container spacing={2}>
        {fields.map((item, idx) => {
          return (
            <ContentTextItem key={item.id} index={index} idx={idx} control={control} keyReset={keyReset} watch={watch} errors={errors} register={register} setValue={setValue} remove={remove} fields={fields} append={append} />
          );
        })}
      </Grid>
    </div>
  );
};

export default FooterContentItem;
