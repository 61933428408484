import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";
import validationMessage from "../../../../../constants/validationMessage";
import { WhyChooseUsFAQForm } from "../../../../../interfaces/navigationMenu";
import { ItemHero } from "../../../../../interfaces/homePage";
import { useGlobalContext } from "../../../../../context/MyGlobalContext";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import homePageService from "../../../../../services/homePageService";
import Swal from "sweetalert2";
import { makeid } from "../../../../../utils";

const defaultData = {
  title: "",
};
const validationSchema = object().shape({
  title: string().required(validationMessage.required),
});
const FAQ = () => {
  const { t } = useTranslation();
  const [keyReset, setKeyReset] = useState("");
  const [dataFAQ, setDataFAQ] = useState<ItemHero | null>(null);
  const [dataFAQSaved, setDataFAQSaved] = useState<ItemHero | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const { language } = useGlobalContext();
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm<WhyChooseUsFAQForm>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });
  const submit: SubmitHandler<WhyChooseUsFAQForm> = async (data) => {
    const payload = {
      language: language ?? "en",
      type: "whychooseus",
      section: "faq",
      contents: JSON.stringify(data),
    };
setLoadingBtn(true)
    if (dataFAQ) {
      const res = await homePageService.update(dataFAQ.id, payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "FAQ has been updated",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    } else {
      const res = await homePageService.store(payload);
      if (res?.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "FAQ has been created",
          showConfirmButton: false,
          timer: 1500,
        });
        fetchListData();
      }
    }

    setLoadingBtn(false)
  };
  const error: SubmitErrorHandler<WhyChooseUsFAQForm> = (errors) => {
    console.log(errors);
  };

  const fetchListData = async () => {
    setLoading(true);
    const response = await homePageService.getList("whychooseus");

    if (response?.status === 200) {
      const data = response.data.find(
        (item: ItemHero) => item.language === language && item.section === "faq"
      );
      if (data) {
        setDataFAQ(data);
        if (language === process.env.REACT_APP_DEFAULT_LANGUAGE) {
          setDataFAQSaved(data);
        }
        const initData = JSON.parse(data.contents ?? "{}");
        reset(initData);
        setKeyReset(makeid(5));
      } else if (dataFAQSaved) {
        const dataSaved = JSON.parse(dataFAQSaved.contents ?? "{}");
        setDataFAQ(null);
        reset(dataSaved);
      } else {
        setDataFAQ(null);
        reset(defaultData);
        setKeyReset(makeid(5));
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchListData();
  }, [language]);
  return (
    <form onSubmit={handleSubmit(submit, error)}>
      {" "}
      <Paper sx={{ p: 3, mb: 2 }}>
        {
          loading ? (
            <Box display={"flex"} justifyContent={"center"} sx={{ my: 5 }}>
              <CircularProgress />
            </Box>
          ) : <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Typography
              className="text-14 fw-600 mb-10"
              color={watch("title")?.length > 61 ? "error" : "green"}
            >
              {t("checkLength")}: {watch("title")?.length}/61
            </Typography>

            <TextField
              size="small"
              fullWidth
              {...register(`title`)}
              label={t("homepage_navigationMenu_whyChooseUs_faq_title")}
              InputLabelProps={{ shrink: true }}
              error={errors?.title !== undefined}
              helperText={
                errors?.title !== undefined ? validationMessage.required : ""
              }
            />
          </Grid>
        
         
        </Grid>
        }
        
      </Paper>
      <Stack alignItems={"flex-end"} justifyContent={"flex-end"}>
        <Button disabled={loadingBtn} variant="contained" type="submit">
          {t("saveButton")}
        </Button>
      </Stack>
    </form>
  );
};

export default FAQ;
