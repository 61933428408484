import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { SearchCustomerReviewQuery, StoreFAQPayload } from "../interfaces/homePage";
import { buildQueryString } from "../utils";

const faqService = {

    store: async (payload: StoreFAQPayload) => {
      
            return await apiInstance.post(endPointAPI.FAQs.STORE, payload)

    },

    update: async (payload: StoreFAQPayload, id: number) => {
        try {
            return await apiInstance.put(`${endPointAPI.FAQs.UPDATE}/${id}`, payload)
        } catch (error) {
            return null
        }
    },
    getList: async (payload: SearchCustomerReviewQuery) => {
        try {
            // return await apiInstance.get(endPointAPI.CUSTOMER_REVIEW.GET_LIST + "?" + queryString)
            return await apiInstance.get( `${endPointAPI.FAQs.GET_LIST}/${payload.language}?${buildQueryString(payload)}`);
        } catch (error) {
            return null
        }
    },
    getListRandom: async (language: string, numberItem?: number) => {
        try {
            return await apiInstance.get(`${endPointAPI.FAQs.GET_LIST_RANDOM}/${language}/${numberItem}`);
        } catch (error) {
            return null
        }
    },

    findDetail: async (language: string, keyLang: string) => {
        try {
            return await apiInstance.get(`${endPointAPI.FAQs.FIND_DETAIL}/${language}/${keyLang}`)
        } catch (error) {
            return null
        }
    },
    delete: async (id: number) => {
        try {
            return await apiInstance.delete(`${endPointAPI.FAQs.DELETE}/${id}`)
        } catch (error) {
            return null
        }
    },


}
export default faqService;
