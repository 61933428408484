import TableCell from "@mui/material/TableCell";
import TableHeader from "../../../components/Table/TableHeader";
import { useTranslation } from "react-i18next";

const TableHeading = () => {
  const { t } = useTranslation();

  return (
    <TableHeader>
      <TableCell className="bg-slight-blue text-left">{t("homepage_faqs_typeField")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("homePage_faqs_countryField")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("homepage_faqs_questionField")}</TableCell>
      <TableCell className="bg-slight-blue text-left">{t("homepage_faqs_answerField")}</TableCell>
      <TableCell className="bg-slight-blue text-center">{t("homePage_faqs_actionField")}</TableCell>
    </TableHeader>
  );
};

export default TableHeading;
