import apiInstance from "../config/apiInstance";
import endPointAPI from "../constants/endPointAPI";
import { ContentLanguagePayload } from "../interfaces/contentLanguage";
import { buildQueryString } from "../utils";

const languageContentService = {

//   Contents
getListContent: async (siteId: number) => {
  try {
    return await apiInstance.get(`${endPointAPI.LANGUAGE_CONTENT.GET_LIST_LANG}/${siteId}`)
  } catch (error) {
    return null
  }
}, 
getListMainSite: async () => {
  try {
    return await apiInstance.get(`${endPointAPI.LANGUAGE_CONTENT.GET_LIST_MAIN_SITE}`)
  } catch (error) {
    return null
  }
}, 
  getContentById: async (id: number) => {
    try {
      return await apiInstance.get(`${endPointAPI.LANGUAGE_CONTENT.GET_BY_ID}/${id}`)
    } catch (error) {
      return null
    }
}, 
storeContentLanguage: async (payload: ContentLanguagePayload) => {
  try {
    return await apiInstance.post(endPointAPI.LANGUAGE_CONTENT.STORE, payload)
  } catch (error) {
    return null
  }
},
updateContentLanguage: async (id: number, payload: ContentLanguagePayload) => {
  try {
    return await apiInstance.put(`${endPointAPI.LANGUAGE_CONTENT.UPDATE}/${id}`, payload)
  } catch (error) {
    return null
  }
},
deleteContentLanguage: async (id: number) => {
  try {
    return await apiInstance.delete(`${endPointAPI.LANGUAGE_CONTENT.DELETE}/${id}`)
  } catch (error) {
    return null
  }
},
};

export default languageContentService;
