import {
  Box,
  Grid,
  MenuItem,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  extraActions?: ReactNode;
  total?: number;
  tableHeading: ReactNode;
  tableContent: ReactNode;
  page?: number;
  setPage: (data: number) => void;
  perPage?: number;
  setPerPage: (data: number) => void;
  quickSearchComponent?: ReactNode;
  isReverse?: boolean;
}
const ContentLayout = ({
  extraActions,
  total,
  tableHeading,
  tableContent,
  page,
  setPage,
  perPage,
  setPerPage,
  quickSearchComponent,
  isReverse = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box className="w-full">
      {extraActions}
      <Box className="body-content">

        <Grid
          container
          spacing={1}
          flexDirection={!isReverse ? "row-reverse" : "row"}
        >

          <Grid item xs={4}>
            {page && perPage && Number(total) > 0 && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: isReverse ? "flex-start" : "flex-end",
                }}
              >
                {t("table_pagination_show")} &ensp;
                <TextField
                  select
                  value={perPage}
                  onChange={(e) => {
                    setPerPage(Number(e?.target?.value));
                    setPage(1);
                  }}
                  className="h-40 mt-8"
                >
                  {[5, 10, 15, 20, 30, 50, 100, 150]?.map(
                    (e: number, i: number) => (
                      <MenuItem key={i} value={e}>
                        {e}
                      </MenuItem>
                    )
                  )}
                </TextField>
                &ensp;{t("table_pagination_of")}&nbsp;<b>{Number(total)}</b>&nbsp;
              </Box>
            )}
          </Grid>  <Grid item xs={8}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: isReverse ? "flex-end" : "flex-start",
              }}
            >
              {quickSearchComponent}
            </Box>
          </Grid>
        </Grid>{" "}
        <Table className="table">
          <TableHead>{tableHeading}</TableHead>
          <TableBody>{tableContent}</TableBody>
        </Table>
        {page && perPage && Number(total) > 0 && (
          <Stack
            className="pt-10"
            direction={"row"}
            alignItems={"center"}
            sx={{
              mt: 1,
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Pagination
              count={Math.ceil(Number(total) / perPage)}
              onChange={(event, value) => setPage(value)}
              page={page}
            />
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default ContentLayout;
